import styled from "@emotion/styled";

export const YoutubeWrap = styled.section`
    width: 100%; display: flex; flex-direction: column; align-items: center; gap: 60px; padding-bottom: 160px;
    
    .title-wrap{ max-width: 1200px; width: 100%; display: flex; flex-direction: row; gap: 30px; justify-content: space-between; align-items: center; }
    .title-wrap .title-txt{  }
    .title-wrap .title-txt svg{  }
    .title-wrap .title-txt svg path{  }
    .title-wrap .title-box{ width: 100%; height: 4px; background-color: #000; }
    .accordion-wrap{ max-width: 1200px; width: 100%; }
    .accordion-wrap .accordion-list{ display: flex; flex-direction: column; }
    .accordion-wrap .accordion-list .accordion-item{ width: 100%; height: 120px; display: flex; flex-direction: row; justify-content: space-between; align-items: center; gap: 24px; padding: 0 20px; border-bottom: 2px solid #ccc; overflow: hidden; transition: all 0.5s ease-in-out; max-height: 120px; }
    .accordion-wrap .accordion-list .accordion-item .youtube-thum{ width: 160px; height: 90px; background-color: #222; border-radius: 10px; overflow: hidden; }
    .accordion-wrap .accordion-list .accordion-item .youtube-thum .youtube-thum-item{ height: 100%; }
    .accordion-wrap .accordion-list .accordion-item .youtube-desc{ width: 80%; display: flex; flex-direction: row; justify-content: space-between; align-items: center; font-family: 'Pretendard'; font-weight: 600; }
    .accordion-wrap .accordion-list .accordion-item .youtube-desc .youtube-title{ color: #777; font-size: 20px; letter-spacing: -1px; }
    .accordion-wrap .accordion-list .accordion-item .youtube-desc .youtube-title .youtube-title-item{  }
    .accordion-wrap .accordion-list .accordion-item .youtube-desc .upload-date{ color: #aaa; font-size: 14px; letter-spacing: -0.7px; }
    .accordion-wrap .accordion-list .accordion-item .youtube-desc .upload-date .upload-date-item{  }
    .accordion-wrap .accordion-list .accordion-item .youtube-icon{  }
    .accordion-wrap .accordion-list .accordion-item .youtube-icon svg{  }
    .accordion-wrap .accordion-list .accordion-item .youtube-icon svg path{  }
    .accordion-wrap .accordion-list .accordion-item .youtube-play-btn{ display: none; }
    
    .accordion-wrap .accordion-list .accordion-item.grown{ position: relative; width: 100%; height: auto; flex-direction: column; padding: 20px; max-height: 500px; transition: all 0.5s ease-in-out; }
    .accordion-wrap .accordion-list .accordion-item.grown .youtube-thum{ position: relative; width: 100%; height: 0; padding-top: 56.25%; }
    .accordion-wrap .accordion-list .accordion-item.grown .youtube-thum .youtube-thum-item{ position: absolute; width: auto; height: 100%; top: 0; left: 0; }
    .accordion-wrap .accordion-list .accordion-item.grown .youtube-desc{ width: 100%; flex-direction: column; align-items: flex-start; gap: 8px; }
    .accordion-wrap .accordion-list .accordion-item.grown .youtube-desc .youtube-title{ color: #444; }
    .accordion-wrap .accordion-list .accordion-item.grown .youtube-desc .youtube-title .youtube-title-item{  }
    .accordion-wrap .accordion-list .accordion-item.grown .youtube-desc .upload-date{  }
    .accordion-wrap .accordion-list .accordion-item.grown .youtube-desc .upload-date .upload-date-item{  }
    .accordion-wrap .accordion-list .accordion-item.grown .youtube-icon{ display: none; }
    .accordion-wrap .accordion-list .accordion-item.grown .youtube-icon svg{  }
    .accordion-wrap .accordion-list .accordion-item.grown .youtube-icon svg path{  }
    .accordion-wrap .accordion-list .accordion-item.grown .youtube-play-btn{ position: absolute; display: block; top: calc(50% - 50px); left: calc(50% - 50px); }

    @media screen and (max-width: 1400px) {
        padding: 0 40px 100px;
        .accordion-wrap .accordion-list .accordion-item .youtube-desc .youtube-title{ font-size: 18px; }
    }
    
    @media screen and (max-width: 992px) {
        
        width: 100%; display: flex; flex-direction: column; align-items: center; gap: 40px; padding: 0 0 100px;
        
        .title-wrap{ max-width: unset; width: 90%; }
        .accordion-wrap{ max-width: unset; width: 90%; }
        .accordion-wrap .accordion-list .accordion-item{ width: 100%; height: 90px; display: grid; grid-template-columns: 1fr 4fr 1fr; gap: 10px; padding: 0 10px;  }
        .accordion-wrap .accordion-list .accordion-item .youtube-thum{ width: 100px; height: 56.5px; }
        .accordion-wrap .accordion-list .accordion-item .youtube-desc{ width: fit-content; flex-direction: column; align-items: flex-start; gap: 8px; }
        .accordion-wrap .accordion-list .accordion-item .youtube-desc .youtube-title{ font-size: 16px; }
        .accordion-wrap .accordion-list .accordion-item .youtube-desc .youtube-title .youtube-title-item{ width: 168px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; }
        .accordion-wrap .accordion-list .accordion-item .youtube-desc .upload-date{ font-size: 12px; }
        .accordion-wrap .accordion-list .accordion-item .youtube-desc .upload-date .upload-date-item{  }
        .accordion-wrap .accordion-list .accordion-item .youtube-icon{  }
        .accordion-wrap .accordion-list .accordion-item .youtube-icon svg{  }
        .accordion-wrap .accordion-list .accordion-item .youtube-icon svg path{  }
        .accordion-wrap .accordion-list .accordion-item .youtube-play-btn{ display: none; }
        
        .accordion-wrap .accordion-list .accordion-item.grown{ display: flex; }
        .accordion-wrap .accordion-list .accordion-item.grown .youtube-desc .youtube-title{ width: 80%; }
        .accordion-wrap .accordion-list .accordion-item.grown .youtube-desc .youtube-title .youtube-title-item{ width: 100%; text-align: left; }
        .accordion-wrap .accordion-list .accordion-item.grown .youtube-play-btn{ top: calc(50% - 32px); left: calc(50% - 30px); }
    }
    `;