import Interview from "../components/interview";
import InterviewTitle from "../components/interviewTitle";







const InterviewPage = () => {

    

    return(

        <>
            <InterviewTitle />
            <Interview />
        </>
    )
}

export default InterviewPage;
