import Ceo from "../components/ceo";
import CoreValue from "../components/coreValue";
import Vision from "../components/vision";
import VisionTitle from "../components/visionTitle";
import Youtube from "../components/youtube";



const VisoinPage = () => {

    

    return(

        <>
            <VisionTitle />
            <Vision /> 
            <CoreValue />
            <Ceo />
            <Youtube />
        </>
    )
}

export default VisoinPage;
