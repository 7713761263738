import styled from "@emotion/styled";
// import visionBgImg from "../../assets/images/vision-bg.png";

export const ServiceWrap = styled.div`

    width: 100%; display: flex; flex-direction: column; align-items: center; gap: 80px;
    .title-wrap{ display: flex; flex-direction: column; gap: 24px; align-items: center; }
    .title-wrap .title-txt{  }
    .title-wrap .title-txt svg{  }
    .title-wrap .title-txt svg path{  }
    .title-wrap .title-detail{ color: #777; text-align: center; font-family: 'Pretendard'; font-size: 16px; font-weight: 500; line-height: 150%; }
    .title-wrap .title-detail .title-deatil-item{  }
    
    .contents-wrap{ display: flex; flex-direction: column !important; align-items: center; gap: 50px; }
    .contents-wrap .brands-swiper-wrap{ position: relative; }
    
    @media screen and (max-width: 1400px) {
        max-width: 920px;
        .contents-wrap{ width: 100%; }
    }
    
    @media screen and (max-width: 992px) {
        gap: 40px;
        .title-wrap .title-detail{ font-size: 14px; }
        
    }
`;