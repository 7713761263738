import { SummaryWrap } from "../assets/styles/recruitSummary.styled";
import { RecruitCompWrap } from "../styles/common/recruitComp.styled";


const RecruitComp = () => {


    return(

        <RecruitCompWrap>
            <SummaryWrap>
                <p className="summary-subtitle">OUR COMPANY</p>
                <p className="summary-title">넥스트러너스는</p>
            </SummaryWrap>
            <div className="company-detail">
                <p className="detail-desc">“미래를 위한 <strong>에듀테크 전문 스타트업</strong>입니다”</p>
                <div className="detail-add">
                    <p className="">
                        자동화, 언택트 시대가 다가오면서 경제활동의 방식이 점차 개인화되어 변화하고 있습니다. 시대 변화에 맞춰 다음 세대에게는 기존 입시, 취업 위주의 교육에서 벗어난 새로운 교육이 필요합니다. 넥스트러너스는 아이부터 성인까지 <strong>전체 생애 주기에 걸쳐 스스로 경제적 활동</strong>을 하고 <strong>능동적 삶</strong>을 살아가기 위해 <strong>필요한 핵심 역량들을 교육</strong>하고 있습니다.
                    </p>
                    <p className="">
                        넥스트러너스는 <strong>2018년 4월 법인 설립 이후 가파른 매출 상승</strong>을 만들며 빠르게 성장하고 있습니다. 2년 반 동안 <strong>4개의 교육 브랜드를 런칭</strong>하고 7,000명이 넘는 수강생들에게 양질의 교육을 제공하였으며, <strong>월 3억원의 매출을 달성</strong>하였습니다.
                    </p>
                </div>
            </div>
        </RecruitCompWrap>
    )
}

export default RecruitComp;
