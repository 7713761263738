// ScrollToTop.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    // 스크롤을 최상단으로 이동시키지 않게 예외 설정
    const noScrollRoutes = /^\/interviews\/[^/]+$/; // '/interviews/:id' 형태의 경로

      if (!noScrollRoutes.test(pathname)) {
          window.scrollTo(0, 0);
      }
  }, [pathname]);  // pathname 변경시 스크롤을 최상단으로

  return null; // UI 렌더링은 필요 없음
}

export default ScrollToTop;
