import React, { useState, useRef, useEffect } from 'react';
import { VideoWrap } from "../styles/common/video.styled";
import videoThumbnail01 from "../assets/images/video_thumbnail_01.png";

// 초기 비디오 데이터 배열
const initialVideos = [
    {
        id: 1,
        url: "https://v.kr.kollus.com/GuYumEMI?enable_initialize_focus=false",
        thumbnail: videoThumbnail01, 
        title: "평범보다 이하의 사람들이 창업으로 성공한 이야기",
        uploadDate: "2024-06-27",
    },
];

const Video = () => {
    const [videos] = useState(initialVideos);
    const isSingleVideo = videos.length === 1;
    const [activeClasses, setActiveClasses] = useState(videos.map((_, index) => (index === 0 ? 'grown' : '')));
    const refs = useRef(videos.map(() => React.createRef()));

    // 아코디언 토글 함수
    const toggleAccordion = (index) => {
        if (isSingleVideo) return; // 비디오가 하나일 때 토글 방지
        const newClasses = activeClasses.slice();
        newClasses[index] = newClasses[index] === 'grown' ? '' : 'grown';
        newClasses.forEach((_, idx) => {
            if (idx !== index) newClasses[idx] = '';
        });
        setActiveClasses(newClasses);
    };

    useEffect(() => {
        refs.current.forEach((ref, index) => {
            if (ref.current) {
                ref.current.style.maxHeight = activeClasses[index] === 'grown' ? '1000px' : '120px';
            }
        });
    }, [activeClasses]);


    return (
        <VideoWrap isSingleVideo={isSingleVideo}>
            <div className="title-wrap">
                <div className="title-txt">
                    <svg className='pc_only' width="151" height="35" viewBox="0 0 151 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M37.0247 1.84L24.9447 34.2H12.4647L0.904687 1.84H12.3847L18.6647 21.72H18.7447L25.0247 1.84H37.0247ZM49.9772 0.599998V7.12H38.6172V0.599998H49.9772ZM38.6172 34.2V9.12H49.9772V34.2H38.6172ZM84.2488 0.599998V34.2H73.6888L73.2488 32.56H73.1688C71.4088 33.84 69.5688 34.8 64.8488 34.8C56.4488 34.8 52.7688 29.4 52.7688 21.76C52.7688 14.4 55.0488 8.32 65.0088 8.32C68.2888 8.32 71.1288 9.56 72.7688 11.16H72.8888V0.599998H84.2488ZM73.2488 22.32V21.4C73.2488 17.56 71.8488 15.88 68.6888 15.88C66.0088 15.88 64.0888 17.56 64.0888 21.4V22.32C64.0888 26.2 66.1688 27.84 68.6888 27.84C71.8488 27.84 73.2488 26.08 73.2488 22.32ZM107.106 26H117.226C115.586 33.08 110.266 34.8 102.666 34.8C93.5856 34.8 87.0656 32.28 87.0656 21.6C87.0656 11.84 93.7056 8.32 102.666 8.32C111.626 8.32 117.546 12.24 117.706 21.6C117.706 22.44 117.626 23.44 117.546 24.2H97.4656C98.0256 27.04 99.7056 28.24 102.946 28.24C104.946 28.24 106.866 27.56 107.106 26ZM102.666 15.24C99.5856 15.24 98.0256 16.08 97.4656 18.52H107.466C106.986 16.28 105.666 15.24 102.666 15.24ZM135.283 8.32C144.243 8.32 150.883 11.84 150.883 21.6C150.883 32.28 144.323 34.8 135.283 34.8C126.203 34.8 119.683 32.28 119.683 21.6C119.683 11.84 126.323 8.32 135.283 8.32ZM135.283 27.8C139.043 27.8 140.683 25.88 140.683 21.76V21.16C140.683 17.08 139.283 15.24 135.283 15.24C131.283 15.24 129.883 17.08 129.883 21.16V21.76C129.883 25.88 131.523 27.8 135.283 27.8Z" fill="#fff"/>
                    </svg>
                    <svg className='pc_none' width="131" height="28" viewBox="0 0 131 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M28.9823 1.272L19.3183 27.16H9.33425L0.08625 1.272H9.27025L14.2943 17.176H14.3583L19.3823 1.272H28.9823ZM39.3443 0.279999V5.496H30.2563V0.279999H39.3443ZM30.2563 27.16V7.096H39.3443V27.16H30.2563ZM66.7615 0.279999V27.16H58.3135L57.9615 25.848H57.8975C56.4895 26.872 55.0175 27.64 51.2415 27.64C44.5215 27.64 41.5775 23.32 41.5775 17.208C41.5775 11.32 43.4015 6.456 51.3695 6.456C53.9935 6.456 56.2655 7.448 57.5775 8.728H57.6735V0.279999H66.7615ZM57.9615 17.656V16.92C57.9615 13.848 56.8415 12.504 54.3135 12.504C52.1695 12.504 50.6335 13.848 50.6335 16.92V17.656C50.6335 20.76 52.2975 22.072 54.3135 22.072C56.8415 22.072 57.9615 20.664 57.9615 17.656ZM85.047 20.6H93.143C91.831 26.264 87.575 27.64 81.495 27.64C74.231 27.64 69.015 25.624 69.015 17.08C69.015 9.272 74.327 6.456 81.495 6.456C88.663 6.456 93.399 9.592 93.527 17.08C93.527 17.752 93.463 18.552 93.399 19.16H77.335C77.783 21.432 79.127 22.392 81.719 22.392C83.319 22.392 84.855 21.848 85.047 20.6ZM81.495 11.992C79.031 11.992 77.783 12.664 77.335 14.616H85.335C84.951 12.824 83.895 11.992 81.495 11.992ZM107.589 6.456C114.757 6.456 120.069 9.272 120.069 17.08C120.069 25.624 114.821 27.64 107.589 27.64C100.325 27.64 95.1088 25.624 95.1088 17.08C95.1088 9.272 100.421 6.456 107.589 6.456ZM107.589 22.04C110.597 22.04 111.909 20.504 111.909 17.208V16.728C111.909 13.464 110.789 11.992 107.589 11.992C104.389 11.992 103.269 13.464 103.269 16.728V17.208C103.269 20.504 104.581 22.04 107.589 22.04ZM130.479 27.16H121.839V20.664H130.479V27.16Z" fill="white"/>
                    </svg>
                </div>
                <div className="title-box"></div>
            </div>
            <div className="accordion-wrap">
                <ul className="accordion-list">
                    {videos.map((video, index) => (
                        <li key={video.id}
                            ref={refs.current[index]}
                            className={`accordion-item ${activeClasses[index]}`}
                            onClick={() => toggleAccordion(index)}>
                            <div className="video-thum">
                                {/* <img src={video.thumbnail} className="video-thum-item" alt={video.title} /> */}
                                {activeClasses[index] === 'grown' && (
                                    <iframe
                                        className='video-thum-item'
                                        src={video.url}
                                        allow="autoplay; encrypted-media;"
                                        width="100%"
                                        height="100%"
                                        title={video.title}
                                    ></iframe>
                                )}
                            </div>
                            <div className="video-desc">
                                <div className="video-title">
                                    <p className="video-title-item">{video.title}</p>
                                </div>
                                <div className="upload-date">
                                    <p className="upload-date-item">{video.uploadDate}</p>
                                </div>
                            </div>
                            <div className="video-icon">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.6667 11.9538C12.6667 11.3174 12.6667 10.9992 12.7997 10.8216C12.9156 10.6668 13.093 10.5699 13.2858 10.5561C13.5072 10.5403 13.7748 10.7124 14.3101 11.0565L20.6043 15.1027C21.0688 15.4014 21.301 15.5507 21.3813 15.7405C21.4514 15.9064 21.4514 16.0936 21.3813 16.2595C21.301 16.4493 21.0688 16.5986 20.6043 16.8973L14.3101 20.9435C13.7748 21.2876 13.5072 21.4597 13.2858 21.4439C13.093 21.4301 12.9156 21.3332 12.7997 21.1784C12.6667 21.0008 12.6667 20.6826 12.6667 20.0462V11.9538Z" stroke="#AAAAAA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M4 10.4C4 8.15979 4 7.03969 4.43597 6.18404C4.81947 5.43139 5.43139 4.81947 6.18404 4.43597C7.03969 4 8.15979 4 10.4 4H21.6C23.8402 4 24.9603 4 25.816 4.43597C26.5686 4.81947 27.1805 5.43139 27.564 6.18404C28 7.03969 28 8.15979 28 10.4V21.6C28 23.8402 28 24.9603 27.564 25.816C27.1805 26.5686 26.5686 27.1805 25.816 27.564C24.9603 28 23.8402 28 21.6 28H10.4C8.15979 28 7.03969 28 6.18404 27.564C5.43139 27.1805 4.81947 26.5686 4.43597 25.816C4 24.9603 4 23.8402 4 21.6V10.4Z" stroke="#AAAAAA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </VideoWrap>
    );
}

export default Video;
