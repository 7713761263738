import CultureKeyword from "../components/cultureKeyword";
import CultureTitle from "../components/cultureTitle";






const CulturePage = () => {

    

    return(

        <>
            <CultureTitle />
            <CultureKeyword />
        </>
    )
}

export default CulturePage;
