import { MoreBtnWrap } from "../assets/styles/moreBtn.styled";

const MoreBtn = () => {

    return (
        <MoreBtnWrap type="button" className="more-btn-item">
            more +
        </MoreBtnWrap>
    );
};



export default MoreBtn;
