import styled from "@emotion/styled";
// import visionBgImg from "../../assets/images/vision-bg.png";

export const MainRecruitWrap = styled.section`
    width: 100%; display: flex; flex-direction: column; align-items: center; gap: 20px; padding: 160px 20%; background-color: #06070B;
    
    .title-wrap{ display: flex; flex-direction: column; gap: 30px; align-items: center; }
    .title-wrap .title-txt{  }
    .title-wrap .title-detail{ color: #ddd; text-align: center; font-family: 'Pretendard'; font-size: 16px; font-weight: 300; line-height: 150%; letter-spacing: -0.32px; }
    .title-wrap .title-detail .title-detail-item{  }
    
    @media screen and (max-width: 1400px) {
        .tablet_only{ display: inline; }
    }
    @media screen and (max-width: 992px) {
        
        gap: 40px; padding: 100px 0;
        
        .title-wrap{ gap: 40px; }
        .title-wrap .title-detail{ color: #777; font-size: 14px; font-weight: 500; line-height: 150%; }

    }
`;