import styled from "@emotion/styled";

export const BrandListWrap = styled.ul`
    display: flex; flex-direction: column;
    
    .brand-item{ display: none; flex-direction: column; gap: 60px; transition: all 0.3s; opacity: 0;
        &.sellfarm-item{ flex-direction: row; align-items: center; gap: 100px; }
        &.sellfarm-item .brand-img-item{ width: 400px; height: 450px; }

        &.active{ display: flex; opacity: 1; }
    }
    
    .brand-img-wrap{ display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 40px; }
    .brand-img-wrap .brand-img-item{ width: 100%; height: auto; }
    .compName-wrap{ display: flex; flex-direction: column; align-items: flex-start; gap: 20px; }
    .desc-title{ color: #000; font-family: 'Pretendard'; font-size: 32px; font-weight: 900; }
    .desc-title .desc-title-item{  }
    .desc-wrap{ display: flex; flex-direction: row; gap: 40px; }
    .desc-wrap .desc-list{ display: flex; flex-direction: column; gap: 10px; justify-content: center; align-items: flex-start; color: #777; font-family: 'Pretendard'; font-size: 16px; font-weight: 700; }
    .desc-wrap .desc-list .desc-item{  }
    .compName-wrap .desc-wrap .desc-list{ padding-left: 20px; }
    
    .keycolor-wrap{ display: flex; flex-direction: column; align-items: flex-start; gap: 20px; }
    .keycolor-wrap .desc-wrap .color-wrap{ display: flex; flex-direction: column; align-items: flex-start; gap: 20px; }
    .keycolor-wrap .desc-wrap .color-wrap .color-box{ width: 400px; height: 20px; }
    .keycolor-wrap .desc-wrap .color-wrap .color-name{ color: #777; font-family: 'Pretendard'; font-size: 16px; font-weight: 700; padding-left: 6px; }
    .keycolor-wrap .desc-wrap .color-wrap .color-name .color-name-item{  }
    .keycolor-wrap .desc-wrap .color-wrap .color-code{ padding-left: 6px; }
    
    
    @media screen and (max-width: 992px) {
        
        max-width: 320px;
        
        .brand-item{ display: none; flex-direction: column; gap: 40px; transition: all 0.3s; opacity: 0;
            &.sellfarm-item{ flex-direction: column; align-items: flex-start; gap: 40px; }
            &.sellfarm-item .brand-img-item{ width: 320px; height: auto; }
            
            &.active{ display: flex; opacity: 1; }
        }
        
        .brand-img-wrap{ display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 40px; }
        .brand-img-wrap .brand-img-item{ width: 100%; height: auto; }
        .desc-title{ font-size: 18px; }
        .desc-wrap{flex-direction: column; gap: 20px; }
        .desc-wrap .desc-list{ gap: 6px; font-size: 12px; }
        .desc-wrap .desc-list .desc-item{  }
        .compName-wrap .desc-wrap .desc-list{ padding-left: 20px; }
    
        .keycolor-wrap{ display: flex; flex-direction: column; align-items: flex-start; gap: 20px; }
        .keycolor-wrap .desc-wrap .color-wrap{ display: flex; flex-direction: column; align-items: flex-start; gap: 20px; }
        .keycolor-wrap .desc-wrap .color-wrap .color-box{ width: 320px; height: 10px; }
        .keycolor-wrap .desc-wrap .color-wrap .color-name{ font-size: 12px; padding-left: 0; }
        .keycolor-wrap .desc-wrap .color-wrap .color-code{ padding-left: 0; }
    }
`;