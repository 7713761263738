import styled from "@emotion/styled";
// import visionBgImg from "../../assets/images/vision-bg.png";

export const RecruitApplyWrap = styled.div`
    display: flex; flex-direction: column; align-items: flex-start; gap: 40px;
    .application-list{ width: 1120px; display: flex; justify-content: center; align-items: center; gap: 20px; }
    .application-list .application-item{ display: flex; justify-content: center; align-items: center; padding: 20px 40px; border-radius: 10px; color: #000; text-align: center; font-family: 'Pretendard'; font-size: 18px; font-weight: 700; }
    .application-list .application-item-arr{  }
    .application-list .application-item.color_num1{ background-color: #EAECEF; }
    .application-list .application-item.color_num2{ background-color: #E5E9ED; }
    .application-list .application-item.color_num3{ background-color: #D4DAE2; }
    .application-list .application-item.color_num4{ background-color: #CAD0D8; }
    .application-list .application-item.color_num5{ background-color: #FB0000; color: #fff; }

    @media screen and (max-width: 1400px) and (min-width: 992px){
        width: 900px;
        .application-list{ width: 900px; flex-wrap: wrap; justify-content: center; align-items: center; gap: 12px; }
        .application-list .application-item{  padding: 16px 32px; border-radius: 8px; font-size: 16px; }
    }
    
    @media screen and (max-width: 992px) {
        
        width: 320px; flex-direction: column; align-items: center; gap: 40px;

        .application-list{ width: 320px; flex-wrap: wrap; justify-content: center; align-items: center; gap: 12px; }
        .application-list .application-item{ padding: 12px 16px; border-radius: 8px; font-size: 12px; }

    }
    
`;