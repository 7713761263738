import { ServiceKeywordWrap } from "../styles/common/serviceKeyword.styled";
// import MainVisual from "./mainVisual";
// import visionBgImg from "../assets/images/vision-bg.png";
import Service from "./service";
import Keyword from "./keyword";


const ServiceKeyword = () => {


    return(

        <ServiceKeywordWrap>
            <Service />
            <Keyword />
        </ServiceKeywordWrap>
    )
}

export default ServiceKeyword;
