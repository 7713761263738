import { CiWrap } from "../styles/common/ci.styled";
import nxrCi from "../assets/images/nxr-ci.png";
import nxrCi02 from "../assets/images/nxr-ci-02.png";



const Ci = () => {

    

    return(

        <CiWrap>
            <div className="title-wrap">
                <div className="title-txt">
                    <svg className="pc_only" width="64" height="34" viewBox="0 0 64 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M34.3614 13.72H22.9214C22.8414 11.92 22.0414 8.48 17.7614 8.48C12.9614 8.48 11.6014 11.8 11.6014 16.88V17.08C11.6014 22.12 12.9614 25.48 17.7614 25.48C21.5214 25.48 22.9614 23.08 23.0414 20H34.6014C34.7614 26.92 28.8014 33.76 18.0814 33.76C5.52141 33.76 0.241406 27.84 0.241406 17C0.241406 6.08 5.92141 0.199998 18.0814 0.199998C27.8414 0.199998 33.9214 5.04 34.3614 13.72ZM37.457 33.2V0.839999H48.977V33.2H37.457ZM63.1648 33.2H52.3648V25.08H63.1648V33.2Z" fill="black"/>
                    </svg>
                    <svg className="pc_none" width="51" height="28" viewBox="0 0 51 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M27.936 11.576H18.784C18.72 10.136 18.08 7.384 14.656 7.384C10.816 7.384 9.728 10.04 9.728 14.104V14.264C9.728 18.296 10.816 20.984 14.656 20.984C17.664 20.984 18.816 19.064 18.88 16.6H28.128C28.256 22.136 23.488 27.608 14.912 27.608C4.864 27.608 0.64 22.872 0.64 14.2C0.64 5.464 5.184 0.759998 14.912 0.759998C22.72 0.759998 27.584 4.632 27.936 11.576ZM30.4125 27.16V1.272H39.6285V27.16H30.4125ZM50.9788 27.16H42.3388V20.664H50.9788V27.16Z" fill="black"/>
                    </svg>
                </div>
                <div className="title-box"></div>
            </div>
            <div className="content-wrap">
                <div className="brand-img-wrap">
                    <img src={nxrCi} className="brand-img-item" alt="넥스트러너스 로고 01" />
                    <img src={nxrCi02} className="brand-img-item" alt="넥스트러너스 로고 02" />
                </div>
                <div className="compName-wrap">
                    <div className="desc-title">
                        <p className="desc-title-item">Company Name</p>
                    </div>
                    <div className="desc-wrap">
                        <ul className="desc-list">
                            <li className="desc-item">NEXT (다음세대, 미래를) -&gt; 미래</li>
                            <li className="desc-item">Leaner’s (배우는 사람들)-&gt; 교육</li>
                            <li className="desc-item">Runner’s (달리는 사람들) -&gt; 자기주도적인 삶</li>
                            <li className="desc-item">Learn + Us (배우다 + 함께) -&gt; 공동체 -&gt; 모두 함께 간다</li>
                        </ul>
                    </div>
                </div>
                <div className="keycolor-wrap">
                    <div className="desc-title">
                        <p className="desc-title-item">Key Color</p>
                    </div>
                    <div className="desc-wrap">
                        <div className="color-wrap">
                            <div className="color-box bk"></div>
                            <div className="color-name">
                                <p className="color-name-item">NXR Black</p>
                            </div>
                            <div className="color-code">
                                <ul className="desc-list">
                                    <li className="desc-item">CMYK  C 0 · M 0 · Y 0 · K 100</li>
                                    <li className="desc-item">RGB  R 0 · G 0 · B 0</li>
                                    <li className="desc-item">HEX  #000000</li>
                                </ul>
                            </div>
                        </div>
                        <div className="color-wrap">
                            <div className="color-box rd"></div>
                            <div className="color-name">
                                <p className="color-name-item">NXR Red</p>
                            </div>
                            <div className="color-code">
                                <ul className="desc-list">
                                    <li className="desc-item">CMYK  C 0 · M 100 · Y 100 · K 2</li>
                                    <li className="desc-item">RGB  R 251 · G 0 · B 0</li>
                                    <li className="desc-item">HEX  #FB0000</li>
                                </ul>
                            </div>
                        </div>
                        <div className="color-wrap">
                            <div className="color-box wt"></div>
                            <div className="color-name">
                                <p className="color-name-item">NXR White</p>
                            </div>
                            <div className="color-code">
                                <ul className="desc-list">
                                    <li className="desc-item">CMYK  C 0 · M 0 · Y 0 · K 0</li>
                                    <li className="desc-item">RGB  R 255 · G 255 · B 255</li>
                                    <li className="desc-item">HEX  #FFFFFF</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </CiWrap>
    )
}

export default Ci;
