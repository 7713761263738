import styled from "@emotion/styled";
// import visionBgImg from "../../assets/images/vision-bg.png";

export const RecruitWrap = styled.section`
    width: 100%; display: flex; flex-direction: column; align-items: center; gap: 80px; padding: 0 20% 160px;
    .title-wrap{ display: flex; flex-direction: column; gap: 10px; align-items: center; }
    .title-wrap::before{ content: ''; width: 4px; height: 80px; background-color: #06070B; }
    .title-wrap .title-txt{  }
    .title-wrap .title-txt svg{  }
    .title-wrap .title-txt svg path{  }

    .content-wrap{ display: flex; flex-direction: column; align-items: center; gap: 80px; }

    @media screen and (max-width: 1400px) and (min-width: 992px){

        .content-wrap{ width: 900px; }

    }
    
    @media screen and (max-width: 992px) {
        
        // width: 100%; display: flex; flex-direction: column; align-items: center;
        gap: 40px; padding: 0 0 100px 0;
        .title-wrap{ gap: 6px; }
        // .title-wrap::before{ content: ''; width: 4px; height: 80px; background-color: #06070B; }
        // .title-wrap .title-txt{  }
        // .title-wrap .title-txt svg{  }
        // .title-wrap .title-txt svg path{  }
    
        .content-wrap{ gap: 80px; }

    }
    
`;