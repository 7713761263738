import React, { useState, useEffect } from 'react';
import { HamMenuMobWrap } from "../styles/common/hamMenuMob.styled";
import { Link } from 'react-router-dom';

const HamMenuMob = ({ isOpen, onClose }) => {
    const [openSection, setOpenSection] = useState(null);
    const [iconRotate, setIconRotate] = useState(false);
    const [animationClass, setAnimationClass] = useState('');

    useEffect(() => {
        // 애니메이션 클래스를 설정하는 로직을 useEffect의 내부에 배치
        if (isOpen) {
            setAnimationClass('slide-in');
        } else {
            setAnimationClass('slide-out');
        }
    }, [isOpen]);

    if (!isOpen) return null;

    const toggleSection = (index, event) => {
        event.stopPropagation(); 
        setIconRotate(!iconRotate);
        if (openSection === index) {
            setOpenSection(null);
        } else {
            setOpenSection(index);
        }
    };

    const handleLinkClick = (event) => {
        event.stopPropagation();
        onClose();
    };

    return (
        <HamMenuMobWrap className={animationClass}>
            <ul className="gnb-list">
                {['About us', 'Service', 'NXR Team', 'Recruit'].map((item, index) => (
                    <li key={index} className={`gnb-item ${openSection === index ? 'active' : ''}`} onClick={(e) => toggleSection(index, e)}>
                        <div className='gnb-item-wrap'>
                            <p className=''>{item}</p>
                            <div className='gnb-icon' style={{
                                transform: openSection === index ? 'rotate(0deg)' : 'rotate(90deg)',
                                transition: 'transform 0.3s ease'
                            }}>
                                {openSection === index ? (
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 12H17" stroke="white" strokeOpacity="0.4" strokeWidth="2"/>
                                    </svg>
                                ) : (
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M11 13L11 17H13V13H17V11H13V7H11L11 11H7V13H11Z" fill="white" fillOpacity="0.4"/>
                                    </svg>
                                )}
                            </div>
                        </div>
                        {openSection === index && (
                            <div className="lnb-container">
                                <div className="lnb-area">
                                    <div className="lnb-wrap">
                                        {index === 0 && (
                                            <ul className="lnb-list">
                                                <li className="lnb-item">
                                                    <Link className='lnb-item-wrap' to="/vision" onClick={handleLinkClick}>
                                                        <p className='lnb-item-txt'>Vision</p>
                                                        <div className='lnb-item-icon'>
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M10 17L15 12L10 7" stroke="white" strokeOpacity="0.4" strokeLinecap="round"/>
                                                            </svg>
                                                        </div>
                                                    </Link>
                                                </li>
                                                <li className="lnb-item">
                                                    <Link className='lnb-item-wrap' to="/history" onClick={handleLinkClick}>
                                                        <p className='lnb-item-txt'>History</p>
                                                        <div className='lnb-item-icon'>
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M10 17L15 12L10 7" stroke="white" strokeOpacity="0.4" strokeLinecap="round"/>
                                                            </svg>
                                                        </div>
                                                    </Link>
                                                </li>
                                                <li className="lnb-item">
                                                    <Link className='lnb-item-wrap' to="/ci-bi" onClick={handleLinkClick}>
                                                        <p className='lnb-item-txt'>CI / BI</p>
                                                        <div className='lnb-item-icon'>
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M10 17L15 12L10 7" stroke="white" strokeOpacity="0.4" strokeLinecap="round"/>
                                                            </svg>
                                                        </div>
                                                    </Link>
                                                </li>
                                            </ul>
                                        )}
                                        {index === 1 && (
                                            <ul className="lnb-list">
                                                <li className="lnb-item">
                                                    <Link className='lnb-item-wrap' to="/brand" onClick={handleLinkClick}>
                                                        <p className='lnb-item-txt'>Brand</p>
                                                        <div className='lnb-item-icon'>
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M10 17L15 12L10 7" stroke="white" strokeOpacity="0.4" strokeLinecap="round"/>
                                                            </svg>
                                                        </div>
                                                    </Link>
                                                </li>
                                            </ul>
                                        )}
                                        {index === 2 && (
                                            <ul className="lnb-list">
                                                <li className="lnb-item">
                                                    <Link className='lnb-item-wrap' to="/culture" onClick={handleLinkClick}>
                                                        <p className='lnb-item-txt'>Culture</p>
                                                        <div className='lnb-item-icon'>
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M10 17L15 12L10 7" stroke="white" strokeOpacity="0.4" strokeLinecap="round"/>
                                                            </svg>
                                                        </div>
                                                    </Link>
                                                </li>
                                                <li className="lnb-item">
                                                    <Link className='lnb-item-wrap' to="/benefits" onClick={handleLinkClick}>
                                                        <p className='lnb-item-txt'>Benefits</p>
                                                        <div className='lnb-item-icon'>
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M10 17L15 12L10 7" stroke="white" strokeOpacity="0.4" strokeLinecap="round"/>
                                                            </svg>
                                                        </div>
                                                    </Link>
                                                </li>
                                                <li className="lnb-item">
                                                    <Link className='lnb-item-wrap' to="/interviews" onClick={handleLinkClick}>
                                                        <p className='lnb-item-txt'>Interviews</p>
                                                        <div className='lnb-item-icon'>
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M10 17L15 12L10 7" stroke="white" strokeOpacity="0.4" strokeLinecap="round"/>
                                                            </svg>
                                                        </div>
                                                    </Link>
                                                </li>
                                            </ul>
                                        )}
                                        {index === 3 && (
                                            <ul className="lnb-list">
                                                <li className="lnb-item">
                                                    <Link className='lnb-item-wrap' to="/recruit" onClick={handleLinkClick}>
                                                        <p className='lnb-item-txt'>Recruit</p>
                                                        <div className='lnb-item-icon'>
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M10 17L15 12L10 7" stroke="white" strokeOpacity="0.4" strokeLinecap="round"/>
                                                            </svg>
                                                        </div>
                                                    </Link>
                                                </li>
                                            </ul>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </li>
                ))}
            </ul>
        </HamMenuMobWrap>
    );
}

export default HamMenuMob;
