import styled from "@emotion/styled";

export const BenefitsItemWrap = styled.ul`
    
    display: flex; width: 100%; max-width: 1120px; justify-content: flex-start; align-items: flex-start; align-content: flex-start; gap: 40px; flex-wrap: wrap;

    .benefit-item{ width: calc(50% - 20px); max-width: 540px; min-width: 460px; display: flex; flex-direction: column; justify-content: center; align-items: center; border-radius: 10px; background-color: #eee; box-shadow: 0px 4px 8px 1px rgba(63, 0, 0, 0.16); overflow: hidden; }
    .benefit-item.full{ width: 100%; }
    .benefit-item .benefit-detail{ width: 100%; display: flex; flex-direction: row; padding: 20px 40px; justify-content: space-between; align-items: center; background-color: #fff; box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.2); cursor: pointer; }
    .benefit-item .benefit-detail .benefit-info{ display: flex; flex-direction: row; gap: 20px; align-items: center; }
    .benefit-item .benefit-detail .benefit-info .benefit-icon{ width: 80px; height: 80px; display: flex; justify-content: center; align-items: center; }
    .benefit-item .benefit-detail .benefit-info .benefit-icon .benefit-icon-item{ width: 80px; height: auto; }
    .benefit-item .benefit-detail .benefit-info .benefit-name{ color: #000; font-family: 'Pretendard'; font-size: 20px; font-weight: 700; }
    .benefit-item .benefit-detail .benefit-info .benefit-name .benefit-name-txt{  }
    .benefit-item .benefit-detail .benefit-arrow{  }
    .benefit-item .benefit-detail .benefit-arrow svg{  }
    .benefit-item .benefit-detail .benefit-arrow svg path{ stroke: #000; }
 
    .benefit-item .benefit-grown{ width: 100%; }
    .benefit-item .benefit-grown .benefit-grown-list{ display: flex; padding: 40px; flex-direction: column; justify-content: center; align-items: flex-start; gap: 10px; color: #666; font-family: 'Pretendard'; font-size: 16px; font-weight: 500; line-height: 140%; }
    .benefit-item .benefit-grown .benefit-grown-list .benefit-grown-item{ display: flex; flex-direction: row; gap: 5px; }
    .benefit-item .benefit-grown .benefit-grown-list .benefit-grown-item::before{ content: '· '; }
    
    @media screen and (max-width: 992px) {
        
        // display: flex; width: 100%; max-width: 1120px; justify-content: flex-start; align-items: flex-start; align-content: flex-start; gap: 40px; flex-wrap: wrap;
        text-align: left;

        .benefit-item{ min-width: 300px; }
        // .benefit-item.full{ width: 100%; }
        .benefit-item .benefit-detail{ padding: 20px; }
        .benefit-item .benefit-detail .benefit-info{ gap: 12px; }
        .benefit-item .benefit-detail .benefit-info .benefit-icon{ width: 60px; height: 60px; }
        .benefit-item .benefit-detail .benefit-info .benefit-icon .benefit-icon-item{ width: 60px; }
        .benefit-item .benefit-detail .benefit-info .benefit-name{ font-size: 16px; line-height: 140%; }
        // .benefit-item .benefit-detail .benefit-info .benefit-name .benefit-name-txt{  }
        // .benefit-item .benefit-detail .benefit-arrow{  }
        // .benefit-item .benefit-detail .benefit-arrow svg{  }
        // .benefit-item .benefit-detail .benefit-arrow svg path{ stroke: #000; }
     
        // .benefit-item .benefit-grown{ width: 100%; }
        .benefit-item .benefit-grown .benefit-grown-list{ padding: 30px 20px; font-size: 14px; }
        // .benefit-item .benefit-grown .benefit-grown-list .benefit-grown-item{  }
        // .benefit-item .benefit-grown .benefit-grown-list .benefit-grown-item::before{ content: '· '; }
    }
`;