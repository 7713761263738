import { SummaryWrap } from "../assets/styles/recruitSummary.styled";
import { RecruitTeamWrap } from "../styles/common/recruitTeam.styled";


const RecruitTeam = () => {


    return(

        <RecruitTeamWrap>
            <SummaryWrap>
                <p className="summary-subtitle">OUR RUNNERS</p>
                <p className="summary-title">어떤 사람을 채용하나요?</p>
            </SummaryWrap>
            <ul className="criteria-list">
                <li className="criteria-item">
                    <p className="criteria-title">성장하려는 욕구와 열정이 충만한 사람</p>
                </li>
                <li className="criteria-item">
                    <p className="criteria-title">잘 융화되고 협력할 수 있는 사람</p>
                </li>
                <li className="criteria-item">
                    <p className="criteria-title">실력과 성과로 자신의 가치를<br className="pc_none" /> 입증할 수 있는 사람</p>
                </li>
                <li className="criteria-item">
                    <p className="criteria-title">책임감과 프로 의식이 있는 사람들</p>
                    <p className="criteria-title-bottom">프로 의식 → 완성도에 대한 집요함, 책임감<br className="pc_none" /> → 마감을 지키는  책임감</p>
                </li>
                <li className="criteria-item">
                    <p className="criteria-title">회사의 비전에 공감할 수 있는 사람</p>
                </li>
            </ul>
        </RecruitTeamWrap>
    )
}

export default RecruitTeam;
