import { CeoWrap } from "../styles/common/ceo.styled";
import ceoProfile from "../assets/images/profile.png";


const Ceo = () => {

    

    return(

        <CeoWrap>
            <div className="title-wrap">
                <div className="title-txt">
                    <svg className="pc_only" width="119" height="34" viewBox="0 0 119 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M34.2052 13.72H22.7652C22.6852 11.92 21.8852 8.48 17.6052 8.48C12.8052 8.48 11.4452 11.8 11.4452 16.88V17.08C11.4452 22.12 12.8052 25.48 17.6052 25.48C21.3652 25.48 22.8052 23.08 22.8852 20H34.4452C34.6052 26.92 28.6452 33.76 17.9252 33.76C5.36516 33.76 0.0851563 27.84 0.0851563 17C0.0851563 6.08 5.76516 0.199998 17.9252 0.199998C27.6852 0.199998 33.7652 5.04 34.2052 13.72ZM37.3008 33.2V0.839999H68.3008V9.12H48.8208V13.16H67.1008V20.88H48.8208V24.88H68.8608V33.2H37.3008ZM89.0877 0.199998C101.248 0.199998 106.528 6.48 106.528 17C106.528 27.48 101.688 33.76 89.0877 33.76C76.5277 33.76 71.6477 27.48 71.6477 17C71.6477 6.48 76.8877 0.199998 89.0877 0.199998ZM89.0877 25.48C93.8877 25.48 95.2077 22.12 95.2077 17.08V16.88C95.2077 11.8 93.9277 8.48 89.0877 8.48C84.2477 8.48 83.0077 11.8 83.0077 16.88V17.08C83.0077 22.12 84.2077 25.48 89.0877 25.48ZM118.321 33.2H107.521V25.08H118.321V33.2Z" fill="black"/>
                    </svg>
                    <svg className="pc_none" width="96" height="28" viewBox="0 0 96 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M27.7641 11.576H18.6121C18.5481 10.136 17.9081 7.384 14.4841 7.384C10.6441 7.384 9.55613 10.04 9.55613 14.104V14.264C9.55613 18.296 10.6441 20.984 14.4841 20.984C17.4921 20.984 18.6441 19.064 18.7081 16.6H27.9561C28.0841 22.136 23.3161 27.608 14.7401 27.608C4.69213 27.608 0.468125 22.872 0.468125 14.2C0.468125 5.464 5.01213 0.759998 14.7401 0.759998C22.5481 0.759998 27.4121 4.632 27.7641 11.576ZM30.2406 27.16V1.272H55.0406V7.896H39.4566V11.128H54.0806V17.304H39.4566V20.504H55.4886V27.16H30.2406ZM71.6701 0.759998C81.3981 0.759998 85.6221 5.784 85.6221 14.2C85.6221 22.584 81.7501 27.608 71.6701 27.608C61.6221 27.608 57.7181 22.584 57.7181 14.2C57.7181 5.784 61.9101 0.759998 71.6701 0.759998ZM71.6701 20.984C75.5101 20.984 76.5661 18.296 76.5661 14.264V14.104C76.5661 10.04 75.5421 7.384 71.6701 7.384C67.7981 7.384 66.8061 10.04 66.8061 14.104V14.264C66.8061 18.296 67.7661 20.984 71.6701 20.984ZM95.0569 27.16H86.4169V20.664H95.0569V27.16Z" fill="black"/>
                    </svg>
                </div>
            </div>
            <div className="profile-wrap">
                <div className="profile-img">
                    <img src={ceoProfile} className="profile-img-item" alt="profile-img" />
                </div>
                <div className="profile-info">
                    <div className="profile-history">
                        <div className="profile-name">이한별 Han Byul Lee</div>
                        <div className="history-wrap">
                            <ul className="history-list">
                                <li className="history-item">- 넥스트러너스 대표이사</li>
                                <li className="history-item">- jr코딩영재원 원장</li>
                                <li className="history-item">- 한강에피크닉 대표</li>
                                <li className="history-item">- 창업진흥원 비즈니스 / 기술 멘토</li>
                                <li className="history-item">- KAIST 전산학과 석사졸업</li>
                                <li className="history-item">- (전)마이23헬스케어 CTO</li>
                                <li className="history-item">- (전)엑소더스 개발팀장 Co-Founder</li>
                            </ul>
                        </div>
                    </div>
                    <div className="profile-desc">
                        <div className="desc-title">
                            “시대가 빠르게 변화하는 만큼 교육도 빠르게<br className="pc_none" /> 변해야만 합니다”
                        </div>
                        <div className="desc-par">
                            <p className="desc-par-item">
                                기존 입시와 취업 위주의 제도권 교육 시장 안에서 자라온 많은 청년들이 급변하는 시대에 적응하지 못하고 미래에 대한 불안감과 회의감을 느끼고 있습니다. 불안한 현실을 살아가고 있는 청년들과 급변하는 미래를 살아갈 아이들에게는 보다 더 능동적으로 삶을 살아갈 수 있도록 ‘새로운 교육'이 필요합니다. 넥스트러너스가 ‘새로운 교육'의 주역이 되어 교육 시장의 혁신적인 변화를 만들 것 입니다.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </CeoWrap>
    )
}

export default Ceo;
