import React from 'react';
import { HistoryWrap } from "../styles/common/history.styled";

const historyData = [
    {
        year: 2024,
        events: [
            {
                month: "5월",
                description: "월 10억 최대 매출 달성"
            }
        ]
    },
    {
        year: 2023,
        events: [
            {
                month: "9월",
                description: "스마트 훈련 교육 승인"
            },
            {
                month: "2월",
                description: "창업도약패키지 사업 선정"
            }
            
        ]
    },
    {
        year: 2022,
        events: [
            {
                month: "8월",
                description: "2022년 K-디지털 트레이닝 훈련기관 선정 (한국기술교육대학교 직업능력심사평가원)"
            },
            {
                month: "8월",
                description: "2022년 경기도일자리우수기업 선정 (경기도일자리재단)"
            },
            {
                month: "5월",
                description: "경기도 김포시 인증 청년기업 인증 (김포시청)"
            },
            {
                month: "3월",
                description: "성인 디지털 직무 교육 서비스 '오즈코딩스쿨' 런칭"
            }
        ]
    },
    {
        year: 2021,
        events: [
            {
                month: "12월",
                description: "지난해 대비 연매출 3배 상승"
            },
            {
                month: "11월",
                description: "근무혁신 우수기업 A등급 선정 (고용노동부)"
            },
            {
                month: "9월",
                description: "김포 코딩학원 직영 2호점 개원"
            },
            {
                month: "9월",
                description: "기업부설연구소 설립 및 인정"
            },
            {
                month: "8월",
                description: "창업부트캠프 300 프로그램 런칭"
            }
        ]
    },
    {
        year: 2020,
        events: [
            {
                month: "12월",
                description: "월 3억원 매출 달성"
            },
            {
                month: "12월",
                description: "김포에서 창의 체험 공간 '마법학교 아티오' 런칭"
            },
            {
                month: "12월",
                description: "'Jr코딩연구소'에서 '넥스트러너스'로 사명 변경"
            },
            {
                month: "11월",
                description: "월 2억원 매출 달성"
            },
            {
                month: "4월",
                description: "메이커스페이스 주관 기관 선정"
            },
            {
                month: "2월",
                description: "창업 교육 서비스 ‘라이프해킹스쿨' 런칭"
            },
            {
                month: "1월",
                description: "본사 서울 이전"
            }
        ]
    },
    {
        year: 2019,
        events: [
            {
                month: "10월",
                description: "초중고 코딩 교육 서비스 '코당마법학교' 런칭"
            },
            {
                month: "2월",
                description: "SW 고성장 사업 선정"
            }
        ]
    },
    {
        year: 2018,
        events: [
            {
                month: "11월",
                description: "자체 개발 지식재산권 특허9건 출원"
            },
            {
                month: "2월",
                description: "설립 2개월 만에 시드 투자 유치"
            },
            {
                month: "2월",
                description: "대전 코딩학원 직영 1호점 개원"
            },
            {
                month: "2월",
                description: "대전에서 ‘Jr코딩연구소’ 법인 설립"
            }
        ]
    },
];

const History = () => {
    return (
        <HistoryWrap>
            <div className="title-wrap">
                <div className="title-txt">
                    <svg className="pc_only" width="205" height="41" viewBox="0 0 205 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.40625 34.2V1.84H11.9263V13.72H22.5263V1.84H34.0063V34.2H22.5263V22.08H11.9263V34.2H0.40625ZM49.3834 0.599998V7.12H38.0234V0.599998H49.3834ZM38.0234 34.2V9.12H49.3834V34.2H38.0234ZM80.175 16.92H69.935C69.895 14.96 68.095 14.48 66.415 14.48C64.815 14.48 63.215 14.8 63.215 16.2C63.215 16.8 63.855 17.16 64.415 17.36C66.215 18.04 70.295 18.28 74.055 19.28C77.815 20.32 81.455 21.72 81.455 25.88C81.455 33.28 73.695 34.8 66.695 34.8C59.815 34.8 52.215 33.08 52.175 25.6H62.975C63.055 27.84 64.815 28.44 67.095 28.44C69.015 28.44 70.775 28 70.775 26.72C70.775 25.56 69.855 25.12 64.695 24.32C56.255 22.96 52.375 20.68 52.375 16.36C52.375 9.52 60.295 8.32 66.135 8.32C72.335 8.32 79.495 9.88 80.175 16.92ZM97.7134 0.599998V9.12H103.753V15.56H97.7134V24.68C97.7134 27 98.3134 28.08 101.193 28.08C102.073 28.08 102.913 28 103.753 27.8V34.04C101.553 34.56 98.9934 34.8 96.5134 34.8C91.7134 34.8 86.3934 33.52 86.3934 26.56V15.56H82.4734V9.12H86.3934V6.32L97.7134 0.599998ZM120.353 8.32C129.313 8.32 135.953 11.84 135.953 21.6C135.953 32.28 129.393 34.8 120.353 34.8C111.273 34.8 104.753 32.28 104.753 21.6C104.753 11.84 111.393 8.32 120.353 8.32ZM120.353 27.8C124.113 27.8 125.753 25.88 125.753 21.76V21.16C125.753 17.08 124.353 15.24 120.353 15.24C116.353 15.24 114.953 17.08 114.953 21.16V21.76C114.953 25.88 116.593 27.8 120.353 27.8ZM138.766 34.2V9.12H149.206L149.606 11.92H149.726C151.246 10.08 153.606 8.48 158.046 8.48C159.206 8.48 160.286 8.64 161.406 8.88V17.32C160.286 16.96 159.246 16.68 156.966 16.68C152.406 16.68 150.086 18.84 150.086 24.32V34.2H138.766ZM178.889 41H166.929L171.729 30.8C171.249 29.68 166.489 21.64 161.209 9.12H172.449L177.009 21.48H177.089L181.489 9.12H192.409L178.889 41ZM204.2 34.2H193.4V26.08H204.2V34.2Z" fill="black"/>
                    </svg>
                    <svg className="pc_none" width="164" height="33" viewBox="0 0 164 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.725 27.16V1.272H9.941V10.776H18.421V1.272H27.605V27.16H18.421V17.464H9.941V27.16H0.725ZM39.9068 0.279999V5.496H30.8188V0.279999H39.9068ZM30.8188 27.16V7.096H39.9068V27.16H30.8188ZM64.54 13.336H56.348C56.316 11.768 54.876 11.384 53.532 11.384C52.252 11.384 50.972 11.64 50.972 12.76C50.972 13.24 51.484 13.528 51.932 13.688C53.372 14.232 56.636 14.424 59.644 15.224C62.652 16.056 65.564 17.176 65.564 20.504C65.564 26.424 59.356 27.64 53.756 27.64C48.252 27.64 42.172 26.264 42.14 20.28H50.78C50.844 22.072 52.252 22.552 54.076 22.552C55.612 22.552 57.02 22.2 57.02 21.176C57.02 20.248 56.284 19.896 52.156 19.256C45.404 18.168 42.3 16.344 42.3 12.888C42.3 7.416 48.636 6.456 53.308 6.456C58.268 6.456 63.996 7.704 64.54 13.336ZM78.5708 0.279999V7.096H83.4028V12.248H78.5708V19.544C78.5708 21.4 79.0508 22.264 81.3548 22.264C82.0588 22.264 82.7308 22.2 83.4028 22.04V27.032C81.6428 27.448 79.5948 27.64 77.6108 27.64C73.7708 27.64 69.5148 26.616 69.5148 21.048V12.248H66.3788V7.096H69.5148V4.856L78.5708 0.279999ZM96.6825 6.456C103.851 6.456 109.163 9.272 109.163 17.08C109.163 25.624 103.915 27.64 96.6825 27.64C89.4185 27.64 84.2025 25.624 84.2025 17.08C84.2025 9.272 89.5145 6.456 96.6825 6.456ZM96.6825 22.04C99.6905 22.04 101.003 20.504 101.003 17.208V16.728C101.003 13.464 99.8825 11.992 96.6825 11.992C93.4825 11.992 92.3625 13.464 92.3625 16.728V17.208C92.3625 20.504 93.6745 22.04 96.6825 22.04ZM111.413 27.16V7.096H119.765L120.085 9.336H120.181C121.397 7.864 123.285 6.584 126.837 6.584C127.765 6.584 128.628 6.712 129.525 6.904V13.656C128.628 13.368 127.797 13.144 125.972 13.144C122.325 13.144 120.469 14.872 120.469 19.256V27.16H111.413ZM143.512 32.6H133.944L137.784 24.44C137.4 23.544 133.592 17.112 129.367 7.096H138.36L142.008 16.984H142.072L145.592 7.096H154.328L143.512 32.6ZM163.76 27.16H155.12V20.664H163.76V27.16Z" fill="black"/>
                    </svg>
                </div>
            </div>
            <div className="contents-wrap">
                <ul className="history-list">
                    {historyData.map((yearData) => (
                        <li key={yearData.year} className="history-item">
                            <div className="history-year">{yearData.year}</div>
                            <ul className="history-desc-list">
                                {yearData.events.map((event, index) => (
                                    <li key={index} className="history-desc-item">
                                        <div className="history-desc-month">{event.month}</div>
                                        <div className="history-desc-txt">{event.description}</div>
                                    </li>
                                ))}
                            </ul>
                        </li>
                    ))}
                </ul>
            </div>
        </HistoryWrap>
    );
}

export default History;
