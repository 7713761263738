import styled from "@emotion/styled";

export const HeaderWrap = styled.header`

    // Header start
    &{ width: 100%; height: 90px; z-index: 2; position: fixed; display: flex !important; justify-content: center; align-items: center; background-color: #fff; }
    & .header-wrap{ width: 75%; display: flex; flex-direction: row; justify-content: space-between; align-items: center; }
    & .header-wrap .nxr-logo{  }
    & .header-wrap .nxr-logo .item{  }
    & .header-wrap .nxr-logo .item svg{  }
    & .header-wrap .nxr-logo .item svg .logo-color{  }

    & .header-wrap nav{ color: #000; text-align: center; font-family: 'Pretendard'; font-size: 18px; font-weight: 700; line-height: normal; }
    & .header-wrap nav .gnb-list{ display: flex; flex-direction: row; justify-content: center; align-items: center;  cursor: pointer; }
    & .header-wrap nav .gnb-list .gnb-item{ position: relative; display: flex; flex-direction: column; padding: 8px 20px; transition: all 0.3s; }
    & .header-wrap nav .gnb-list .gnb-item:hover{ color: #fb0000; }
    & .header-wrap .lnb-container{ position: absolute; width: 100vw; max-height: 0; visibility: hidden; opacity: 0.5; overflow: hidden; background-color: rgba(255,255,255, 0.8); box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.10); backdrop-filter: blur(5px); transition: all 0.3s ease-in-out; top: 100%; left: 0; z-index: -1; }
    & .header-wrap nav .gnb-list:hover + .lnb-container{ display: block !important; }
    & .header-wrap .lnb-container .lnb-area{ width: 75%; display: flex; justify-content: flex-end;  margin: 0 auto; }
    & .header-wrap .lnb-container .lnb-wrap{ display: flex; flex-direction: row; padding: 10px 0 36px; font-family: 'Pretendard'; font-size: 14px; font-weight: 500; }
    & .header-wrap .lnb-container .lnb-wrap .lnb-list{ display: flex; flex-direction: column; align-items: center; }
    & .header-wrap .lnb-container .lnb-wrap .lnb-list .lnb-item{ padding: 10px 24px; cursor: pointer; }
    & .header-wrap .lnb-container .lnb-wrap .lnb-list a{ text-decoration: none; color: #000; transition: all 0.3s; }
    & .header-wrap .lnb-container .lnb-wrap .lnb-list a:hover{ color: #fb0000; }
    
    @media screen and (max-width: 1400px) {
        & .header-wrap{ width: 85%; }
        & .header-wrap .lnb-container .lnb-area{ width: 85%; }
    }
    
    @media screen and (max-width: 992px) {
        &{ width: 100%; height: 50px; z-index: 2; position: fixed; display: flex !important; justify-content: center; align-items: center; background-color: #06070B; }

        
        & .header-wrap .nxr-logo{ padding-left: 6px; }
        & .header-wrap .ham-btn{ background-color: transparent; border: none; outline: none; }
        & .header-wrap .ham-btn svg{ transition: all 0.3s ease; opacity: 1; }
        & .header-wrap .ham-btn:hover svg{  }
    
        & .header-wrap nav{ display: none; }
    }
`;