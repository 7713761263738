import styled from "@emotion/styled";
// import visionBgImg from "../../assets/images/vision-bg.png";

export const RecruitTeamWrap = styled.div`
    width: 1120px ; display: flex; flex-direction: row; justify-content: space-between; align-items: flex-start;

    .criteria-list{ display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 20px; padding-top: 40px; }
    .criteria-list .criteria-item{ width: 540px; display: flex; flex-direction: column; justify-content: center; align-items: flex-start; gap: 10px; padding: 28px 30px; border-radius: 10px; background-color: #fff; box-shadow: 0 4px 8px 1px rgba(63, 0, 0, 0.16); color: #000; font-family: 'Pretendard'; transition: all 0.3s; cursor: pointer; }
    .criteria-list .criteria-item:hover{ transform: scale(1.01); }
    .criteria-list .criteria-item .criteria-title{ font-size: 18px; font-weight: 700; }
    .criteria-list .criteria-item .criteria-title-bottom{ color: #777; font-size: 14px; font-weight: 600; }

    @media screen and (max-width: 1400px) and (min-width: 992px){
        width: 900px;
    }
    
    @media screen and (max-width: 992px) {
        
        width: 320px; flex-direction: column; align-items: center; gap: 40px; text-align: left;
    
        .criteria-list{ padding-top: 0; }
        .criteria-list .criteria-item{ width: 320px; gap: 10px; padding: 20px; line-height: 140%; }
        .criteria-list .criteria-item:hover{ transform: scale(1.01); }
        .criteria-list .criteria-item .criteria-title{ font-size: 16px; font-weight: 700; }
        .criteria-list .criteria-item .criteria-title-bottom{ color: #777; font-size: 12px; font-weight: 600; }

    }
    
`;