import styled from "@emotion/styled";
// import visionBgImg from "../../assets/images/vision-bg.png";

export const ServiceKeywordWrap = styled.section`
    width: 100%; height: 1190px; display: flex; flex-direction: column; gap: 200px; align-items: center; padding: 160px 12.5%;  background-color: #06070B;


    @media screen and (max-width: 1400px) {
        height: fit-content; padding: 160px 0; margin: 0 auto;
    }
`;