import styled from "@emotion/styled";

export const MediaWrap = styled.section`
    width: 100%; display: flex; flex-direction: column; align-items: center; gap: 80px; padding: 160px 10%;
    
    .title-wrap{ display: flex; flex-direction: column; gap: 24px; align-items: center; }
    .title-wrap::after{ content: ''; width: 80px; height: 4px; background-color: #06070B; }
    .title-wrap .title-txt{  }
    .title-wrap .title-txt svg{  }
    .title-wrap .title-txt svg path{  }


    .board-wrap{ width: 100%; max-width: 1400px; display: flex; flex-direction: column; align-items: center; gap: 50px; }
    .board-wrap .board-gallery{ width: 100%; }
    .board-wrap .board-gallery .gallery-list{ width: 100%; max-width: 1400px; display: flex; justify-content: center; align-items: center; gap: 40px 25px; flex-wrap: wrap; }
    .board-wrap .board-gallery .gallery-list .gallery-item{ width: calc((100% - 50px) / 3); display: flex; flex-direction: column; gap: 24px; }
    .board-wrap .board-gallery .gallery-list .gallery-item .gallery-img-wrap{ position: relative; width: 100%; overflow: hidden; border-radius: 20px; }
    .board-wrap .board-gallery .gallery-list .gallery-item .gallery-img-wrap .gallery-img-item{ width: 100%; max-width: 450px; height: 280px; background-size: cover; background-position: center; background-color: #222; }
    .board-wrap .board-gallery .gallery-list .gallery-item .gallery-img-wrap .more-btn-icon{ padding: 0; margin: 0; position: absolute; bottom: 0; right: 0; }
    .board-wrap .board-gallery .gallery-list .gallery-item .gallery-txt-wrap{ display: flex; flex-direction: column; gap: 24px; }
    .board-wrap .board-gallery .gallery-list .gallery-item .gallery-txt-wrap .gallery-title-wrap{ display: flex; flex-direction: column; align-items: flex-start; gap: 8px; font-family: 'Pretendard';  font-weight: 500; }
    .board-wrap .board-gallery .gallery-list .gallery-item .gallery-txt-wrap .gallery-title-wrap .date-item{ color: #aaa; font-size: 16px; letter-spacing: -0.8px; }
    .board-wrap .board-gallery .gallery-list .gallery-item .gallery-txt-wrap .gallery-title-wrap .title-item{ width: 100%; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; text-align: left; color: #444; font-size: 18px; letter-spacing: -0.9px; }
    .board-wrap .board-gallery .gallery-list .gallery-item .gallery-txt-wrap .gallery-keyword-wrap{  }
    .board-wrap .board-gallery .gallery-list .gallery-item .gallery-txt-wrap .gallery-keyword-wrap .keywords-list{ display: flex; flex-direction: row; gap: 16px; align-items: center; flex-wrap: wrap; }
    .board-wrap .board-gallery .gallery-list .gallery-item .gallery-txt-wrap .gallery-keyword-wrap .keywords-list .keywords-item{ display: flex;padding: 6px 12px; justify-content: center; align-items: center; border-radius: 20px; background-color: #ffe5e5; color: #cb0000; font-family: 'Pretendard'; font-size: 14px; font-weight: 700; }
    
    @media screen and (max-width: 1400px) {
        width: 100%; display: flex; flex-direction: column; align-items: center; gap: 40px; padding: 60px 0 100px;
        
        .title-wrap{ display: flex; flex-direction: column; gap: 12px; align-items: center; }
    
        .board-wrap{ width: 100%; }
        .board-wrap .board-gallery .gallery-list{ width: 100%; gap: 40px; }
        .board-wrap .board-gallery .gallery-list .gallery-item{ width: 320px; gap: 20px; }
        .board-wrap .board-gallery .gallery-list .gallery-item .gallery-img-wrap{ position: relative; width: 100%; padding-top: 75%; }
        .board-wrap .board-gallery .gallery-list .gallery-item .gallery-img-wrap .gallery-img-item{ position: absolute; width: 100%; height: 100%; top: 0; left: 0; }
        .board-wrap .board-gallery .gallery-list .gallery-item .gallery-txt-wrap{ gap: 20px; padding: 0 6px; }
        .board-wrap .board-gallery .gallery-list .gallery-item .gallery-txt-wrap .gallery-title-wrap .date-item{ font-size: 16px; }
        .board-wrap .board-gallery .gallery-list .gallery-item .gallery-txt-wrap .gallery-title-wrap .title-item{ font-size: 18px; }
        .board-wrap .board-gallery .gallery-list .gallery-item .gallery-txt-wrap .gallery-keyword-wrap .keywords-list{ gap: 8px; }
        .board-wrap .board-gallery .gallery-list .gallery-item .gallery-txt-wrap .gallery-keyword-wrap .keywords-list .keywords-item{ font-size: 12px; }
    }

    @media screen and (max-width: 992px) {
        
        width: 100%; display: flex; flex-direction: column; align-items: center; gap: 40px; padding: 60px 0 100px;
        
        .title-wrap{ display: flex; flex-direction: column; gap: 12px; align-items: center; }
    
        .board-wrap{ width: 100%; max-width: 320px; }
        .board-wrap .board-gallery .gallery-list{ width: 100%; max-width: 320px; gap: 40px; }
        .board-wrap .board-gallery .gallery-list .gallery-item{ width: 320px; gap: 20px; }
        .board-wrap .board-gallery .gallery-list .gallery-item .gallery-img-wrap{ position: relative; width: 100%; padding-top: 75%; }
        .board-wrap .board-gallery .gallery-list .gallery-item .gallery-img-wrap .gallery-img-item{ position: absolute; width: 100%; height: 100%; top: 0; left: 0; }
        .board-wrap .board-gallery .gallery-list .gallery-item .gallery-txt-wrap{ gap: 20px; padding: 0 6px; }
        .board-wrap .board-gallery .gallery-list .gallery-item .gallery-txt-wrap .gallery-title-wrap .date-item{ font-size: 16px; }
        .board-wrap .board-gallery .gallery-list .gallery-item .gallery-txt-wrap .gallery-title-wrap .title-item{ font-size: 18px; }
        .board-wrap .board-gallery .gallery-list .gallery-item .gallery-txt-wrap .gallery-keyword-wrap .keywords-list{ gap: 8px; }
        .board-wrap .board-gallery .gallery-list .gallery-item .gallery-txt-wrap .gallery-keyword-wrap .keywords-list .keywords-item{ font-size: 12px; }
    }
`;