import { MainVisualWrap } from "../styles/common/mainVisual.styled";
import mainVisual from "../assets/images/main-visual.png";
import mainVisualMob from "../assets/images/main-visual-mob.png";


const MainVisual = () => {

    

    return(

        <MainVisualWrap>
            <picture>
                <source media="(max-width: 992px)" srcSet={mainVisualMob} />
                <img src={mainVisual} className="main-visual-item" alt="넥스트러너스 education for next generatoin" />
            </picture>
        </MainVisualWrap>
    )
}

export default MainVisual;