import React from "react";
import { useParams } from "react-router-dom";
import InterviewDetail from "../components/interviewDetail";
import InterviewTitle from "../components/interviewTitle";
import { getGalleryData } from "../components/interviewBoard";



const InterviewDetailPage = () => {
    const { id } = useParams();
    const interviewData = getGalleryData().find(item => item.id === parseInt(id));

    

    return(

        <>
            <InterviewTitle />
            {/* <InterviewDetail data={interviewData} /> */}
            {interviewData ? <InterviewDetail data={interviewData} /> : <p>Interview not found.</p>}
        </>
    )
}

export default InterviewDetailPage;
