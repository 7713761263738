import { MoreBtnWrap } from "../assets/styles/moreBtn.styled";

const ApplyBtn = () => {

    return (
        <MoreBtnWrap type="button" className="more-btn-item">
            apply more +
        </MoreBtnWrap>
    );
};



export default ApplyBtn;
