import { MainWrap } from "../styles/common/main.styled";
import { Route, Routes } from 'react-router-dom';
import HomePage from "./homePage";
import VisoinPage from "./visoinPage";
import HistoryPage from "./historyPage";
import CiBiPage from "./ciBiPage";
import CulturePage from "./culturePage";
import BenefitsPage from "./benefitsPage";
import InterviewPage from "./interviewPage";
import RecruitPage from "./recruitPage";
import ServicePage from "./servicePage";
import ScrollToTop from "../components/scrollToTop";
import InterviewDetailPage from "./interviewDetailPage";


function Main(props) {

    

    return(

        <MainWrap>
            <ScrollToTop />
            <Routes>
                <Route path='/' element={<HomePage />} />
                <Route path='/vision' element={<VisoinPage />} />
                <Route path='/history' element={<HistoryPage />} />
                <Route path='/ci-bi' element={<CiBiPage />} />
                <Route path='/brand' element={<ServicePage />} />
                <Route path='/culture' element={<CulturePage />} />
                <Route path='/benefits' element={<BenefitsPage />} />
                <Route path='/interviews' element={<InterviewPage />} />
                <Route path='/interviews/:id' element={<InterviewDetailPage />} />
                <Route path='/recruit' element={<RecruitPage />} />
            </Routes>
        </MainWrap>
    )
}

export default Main;
