import { SummaryWrap } from "../assets/styles/recruitSummary.styled";
import { RecruitBenefitWrap } from "../styles/common/recruitBenefit.styled";
import BenefitsItem from "./benefitsItem";


const RecruitBenefit = () => {


    return(

        <RecruitBenefitWrap>
            <SummaryWrap>
                <p className="summary-subtitle">OUR BENEFITS</p>
                <p className="summary-title">복리후생</p>
            </SummaryWrap>
            <div className="benefit-wrap">
                <BenefitsItem className="benefit-list" />
            </div>
        </RecruitBenefitWrap>
    )
}

export default RecruitBenefit;
