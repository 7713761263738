import { KeywordWrap } from "../styles/common/keywordWrap.styled";
import keywordIcon01 from "../assets/images/keyword-icon-01.png";
import keywordIcon02 from "../assets/images/keyword-icon-02.png";
import keywordIcon03 from "../assets/images/keyword-icon-03.png";
import keywordIcon04 from "../assets/images/keyword-icon-04.png";
import keywordIcon05 from "../assets/images/keyword-icon-05.png";


const KeywordCultureArea = () => {

    

    return(

        <KeywordWrap>
            <div className="sub-title">
                <div className="sub-title-item">KEYWORD</div>
                <div className="sub-title-txt">핵심 키워드</div>
            </div>
            <div className="keyword-content">
                <ul className="keyword-list">
                    <li className="keyword-item">
                        <div className="keyword-info">
                            <div className="keyword-name">성장</div>
                            <p className="keyword-description">
                                성장 욕구와<br />
                                지속적인 열정
                            </p>
                        </div>
                        <div className="keyword-icon">
                            <img src={keywordIcon01} className="keyword-icon-item" alt="" />
                        </div>
                    </li>
                    <li className="keyword-item">
                        <div className="keyword-info">
                            <div className="keyword-name">융화</div>
                            <p className="keyword-description">
                                상호 존중과<br />
                                협력
                            </p>
                        </div>
                        <div className="keyword-icon">
                            <img src={keywordIcon02} className="keyword-icon-item" alt="" />
                        </div>
                    </li>
                    <li className="keyword-item">
                        <div className="keyword-info">
                            <div className="keyword-name">실력</div>
                            <p className="keyword-description">
                                실력으로<br />
                                자신의 가치 입증
                            </p>
                        </div>
                        <div className="keyword-icon">
                            <img src={keywordIcon03} className="keyword-icon-item" alt="" />
                        </div>
                    </li>
                    <li className="keyword-item">
                        <div className="keyword-info">
                            <div className="keyword-name">책임감</div>
                            <p className="keyword-description">
                                책임감과<br />
                                프로의식 필수!
                            </p>
                        </div>
                        <div className="keyword-icon">
                            <img src={keywordIcon04} className="keyword-icon-item" alt="" />
                        </div>
                    </li>
                    <li className="keyword-item">
                        <div className="keyword-info">
                            <div className="keyword-name">비전</div>
                            <p className="keyword-description">
                                회사의 꿈과<br />
                                비전에 공감
                            </p>
                        </div>
                        <div className="keyword-icon">
                            <img src={keywordIcon05} className="keyword-icon-item" alt="" />
                        </div>
                    </li>
                </ul>
            </div>
        </KeywordWrap>
    )
}

export default KeywordCultureArea;
