import styled from "@emotion/styled";

export const MainVisualWrap = styled.section`
    width: 100vw; height: calc(100vh - 90px); display: flex; justify-content: center; align-items: center; background-color: #06070B;
    & .main-visual-item{ width: 70%; max-width: 1200px; height: auto; }
    picture{ width: inherit; height: unset; }

    @media screen and (max-width: 992px) {
        height: 600px;
        // width: 100vw; height: calc(100vh - 90px); display: flex; justify-content: center; align-items: center; background-color: #06070B;
        & .main-visual-item{ width: 80%; max-width: 500px; height: auto; }
    }

`;