import Benefits from "../components/benefits";
import BenefitsTitle from "../components/benefitsTitle";



const BenefitsPage = () => {

    

    return(

        <>
            <BenefitsTitle />
            <Benefits />
        </>
    )
}

export default BenefitsPage;
