import Bi from "../components/bi";
import Ci from "../components/ci";
import CiBiTitle from "../components/ciBiTitle";




const CiBiPage = () => {

    

    return(

        <>
            <CiBiTitle />
            <Ci />
            <Bi />
        </>
    )
}

export default CiBiPage;
