import styled from "@emotion/styled";

export const HistoryWrap = styled.section`
    width: 100%; display: flex; flex-direction: column; align-items: center; gap: 80px; padding: 160px 0;

    .title-wrap{ display: flex; flex-direction: column; gap: 24px; align-items: center; }
    .title-wrap::after{ content: ''; width: 80px; height: 4px; background-color: #06070B; }
    .title-wrap .title-txt{  }
    .title-wrap .title-txt svg{  }
    .title-wrap .title-txt svg path{  }

    .contents-wrap{ font-family: 'Pretendard'; }
    .contents-wrap .history-list{ display: flex; flex-direction: column; gap: 60px; }
    .contents-wrap .history-list .history-item{ display: grid; grid-template-columns: 1fr 7fr; gap: 120px; }
    .contents-wrap .history-list .history-item .history-year{ color: #000; font-size: 32px; font-weight: 900; }
    .contents-wrap .history-list .history-item .history-desc-list{ display: flex; flex-direction: column; gap: 20px; padding-top: 15px; }
    .contents-wrap .history-list .history-item .history-desc-list .history-desc-item{ display: grid;grid-template-columns: 1fr 16fr; gap: 60px; font-size: 16px; font-weight: 700; }
    // .contents-wrap .history-list .history-item .history-desc-list .history-desc-item{ display: flex; flex-direction: row; gap: 60px; font-size: 16px; font-weight: 700; }
    .contents-wrap .history-list .history-item .history-desc-list .history-desc-item .history-desc-month{ color: #000; }
    .contents-wrap .history-list .history-item .history-desc-list .history-desc-item .history-desc-txt{ color: #777; text-align: left; }
    
    @media screen and (max-width: 992px) {
        
        gap: 40px; padding: 100px 0 60px;
    
        .title-wrap{ gap: 12px; }
        .contents-wrap .history-list .history-item{ display: flex; flex-direction: column; align-items: flex-start; gap: 20px; }
        .contents-wrap .history-list .history-item .history-year{ font-size: 24px; }
        .contents-wrap .history-list .history-item .history-desc-list{ gap: 12px; padding-top: 0; }
        .contents-wrap .history-list .history-item .history-desc-list .history-desc-item{ width: 300px; grid-template-columns: 1fr 9fr; gap: 20px; font-size: 14px; line-height: normal; }
    }
`;