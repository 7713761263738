import styled from "@emotion/styled";

export const CultureKeywordWrap = styled.section`
    width: 100%; display: flex; flex-direction: column; align-items: center; gap: 90px; padding-bottom: 160px;
    .title-wrap{ display: flex; flex-direction: column; gap: 10px; align-items: center; }
    .title-wrap::before{ content: ''; width: 4px; height: 80px; background-color: #06070B; }
    .title-wrap .title-txt{  }
    .title-wrap .title-txt svg{  }
    .title-wrap .title-txt svg path{  }

    .content-wrap{ display: flex; flex-direction: column; gap: 120px; }
    
    @media screen and (max-width: 992px) {
        
        gap: 40px; padding-bottom: 120px;
        .title-wrap{ display: flex; flex-direction: column; gap: 6px; align-items: center; }
    }
`;