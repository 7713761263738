import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { InterviewDetailWrap } from "../styles/common/interviewDetail.styled";
import interbiew_logo from "../assets/images/interbiew_logo.png";
import { galleryData } from './interviewBoard'; 

const InterviewDetail = ({ data }) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [selectedKeyword, setSelectedKeyword] = useState(null);
    const navigate = useNavigate();
    const introWrapAnswerRef = useRef(null);

    useEffect(() => {
        setCurrentSlide(0);
        if (introWrapAnswerRef.current) {
            introWrapAnswerRef.current.scrollTop = 0;
        }
    }, [data]);

    const handleNextSlide = () => {
        setCurrentSlide((prevSlide) =>
            prevSlide < data.description.length - 1 ? prevSlide + 1 : 0
        );

        if (introWrapAnswerRef.current) {
            introWrapAnswerRef.current.scrollTop = 0;
        }
    };

    const handlePrevSlide = () => {
        setCurrentSlide((prevSlide) =>
            prevSlide > 0 ? prevSlide - 1 : data.description.length - 1
        );

        if (introWrapAnswerRef.current) {
            introWrapAnswerRef.current.scrollTop = 0;
        }
    };

    const handleTitleClick = (title) => {
        const foundItem = galleryData.find(item => item.title.includes(title));
        if (foundItem) {
            navigate(`/interviews/${foundItem.id}`);
        }
    };

    if (!data) return <p>No data available.</p>;

    const currentDescription = data.description[currentSlide];

    if (!currentDescription) {
        return <p>No description available.</p>;
    }

    const currentBackgroundColor = galleryData.find(item => item.title.some(title => data.title.includes(title)))?.backgroundColor || '#540000';

    const renderAnswers = () => {
        return currentDescription.answers.map((answer, index) => (
            <div key={index} className="answer-block">
                {answer.keyword && <p className="keyword-item">{answer.keyword}</p>}
                <p className="text-ans-item">
                    {answer.answer.map((line, lineIndex) => (
                        <React.Fragment key={lineIndex}>
                            <span dangerouslySetInnerHTML={{ __html: line }} />
                            {lineIndex < answer.answer.length - 1 ? <br /> : null}
                        </React.Fragment>
                    ))}
                </p>
            </div>
        ));
    };

    return (
        <InterviewDetailWrap>
            <div className="title-wrap">
                <div className="title-txt">
                    <img src={interbiew_logo} className="brand-img-item" alt="interview" />
                </div>
                <div className="title-box"></div>
            </div>

            <div className="contents-wrap">
                <div className="keywords-wrap">
                    {galleryData.map((item, groupIndex) => {
                        const isActive = data.title.some(title => item.title.includes(title));

                        return (
                            <button 
                                key={groupIndex} 
                                className={`keywords-wrap_item ${isActive ? 'active' : ''}`}
                                onClick={() => handleTitleClick(item.title[0])}
                            >
                                {item.title.map((title, index) => (
                                    <span key={index} className="keyword-box">
                                        <div 
                                            className={`keyword-button ${isActive ? 'active' : ''}`}
                                        >
                                            {title}
                                        </div>
                                        <span className="keyword-dot">{index < item.title.length - 1 && ' · '}</span>
                                    </span>
                                ))}
                            </button>
                        );
                    })}
                </div>

                <div 
                    className="contents-intro-wrap" 
                    style={{ backgroundColor: currentBackgroundColor }}
                >
                    <div 
                        className="intro-wrap_question"
                        style={{ backgroundColor: currentBackgroundColor }}
                    >
                        <div className="radius-box">
                            <span>{currentSlide + 1}</span>
                        </div>

                        <p 
                            className="text-ques-item"
                            dangerouslySetInnerHTML={{ __html: currentDescription.question }} 
                        />

                        <div className="slide-pagination">
                            <button className="btn-prev slide-arrow" onClick={handlePrevSlide}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                                    <path d="M8.5 1L1.5 8L8.5 15" stroke="#B6B6B6" strokeWidth="1.5"/>
                                </svg>
                            </button>
                            <div className="page-num">
                                <span>0{currentSlide + 1} / 0{data.description.length}</span>
                            </div>
                            <button className="btn-next slide-arrow" onClick={handleNextSlide}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                                    <path d="M0.5 1L8.5 8L0.5 15" stroke="white" strokeWidth="1.5"/>
                                </svg>                                    
                            </button>
                        </div>
                    </div>
                    <div className="intro-wrap_answer" ref={introWrapAnswerRef}>
                        {renderAnswers()}
                    </div>
                </div>
            </div>
        </InterviewDetailWrap>
    );
};

export default InterviewDetail;
