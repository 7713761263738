import styled from "@emotion/styled";

export const KeywordWrap = styled.div`

    display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 60px;

    .keyword-content{  }
    .keyword-content .keyword-list{ width: 100%; max-width: 1140px; display: flex;flex-direction: row; flex-wrap: wrap; justify-content: center; align-items: center; gap: 30px; }
    .keyword-content .keyword-list .keyword-item{ display: flex; width: 360px; height: 180px; padding: 0 40px; justify-content: space-between; align-items: center; border-radius: 8px; border: 2px solid #fb0000; background-color: #fff; box-shadow: 0px 0px 8px 0px rgba(251, 0, 0, 0.5); }
    .keyword-content .keyword-list .keyword-item .keyword-info{ display: flex; flex-direction: column; gap: 16px; justify-content: center; align-items: flex-start; font-family: 'Pretendard'; }
    .keyword-content .keyword-list .keyword-item .keyword-info .keyword-name{ color: #000; font-size: 24px; font-weight: 700; }
    .keyword-content .keyword-list .keyword-item .keyword-info .keyword-description{ color: #aaa; font-size: 16px; font-weight: 600; line-height: 140%; }
    .keyword-content .keyword-list .keyword-item .keyword-icon{ width: 80px; height: 80px; display: flex; justify-content: center; align-items: center; }
    .keyword-content .keyword-list .keyword-item .keyword-icon .keyword-icon-item{ width: 80px; height: auto; }

    .sub-title{ display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 10px; color: #000; text-align: center; font-family: 'Pretendard'; font-weight: 800; }
    .sub-title .sub-title-item{ font-size: 20px; }
    .sub-title .sub-title-txt{ font-size: 32px; }
    
    @media screen and (max-width: 992px) {
        
        // display: flex; flex-direction: column; justify-content: center; align-items: center;
        gap: 40px;
    
        .keyword-content{  }
        .keyword-content .keyword-list{ max-width: 1140px; gap: 20px; }
        .keyword-content .keyword-list .keyword-item{ width: 280px; height: 140px; text-align: left; }
        .keyword-content .keyword-list .keyword-item .keyword-info{ gap: 10px; }
        .keyword-content .keyword-list .keyword-item .keyword-info .keyword-name{ font-size: 20px; }
        .keyword-content .keyword-list .keyword-item .keyword-info .keyword-description{ font-size: 14px; }
        .keyword-content .keyword-list .keyword-item .keyword-icon{ width: 60px; height: 60px; }
        .keyword-content .keyword-list .keyword-item .keyword-icon .keyword-icon-item{ width: 60px; } 
    
        .sub-title{ gap: 6px; }
        .sub-title .sub-title-item{ font-size: 16px; }
        .sub-title .sub-title-txt{ font-size: 24px; }

    }
`;