import styled from "@emotion/styled";

export const CoreValueWrap = styled.section`

    width: 100%; display: flex; flex-direction: column; align-items: center; gap: 200px; padding-bottom: 160px; background-color: #06070B; color: #fff;

    .title-wrap{ display: flex; flex-direction: column; gap: 10px; align-items: center; }
    .title-wrap::before{ content: ''; width: 4px; height: 80px; background-color: #fff; }
    .title-wrap .title-txt{  }
    .title-wrap .title-txt svg{  }
    .title-wrap .title-txt svg path{  }
    .title-wrap .title-detail{ color: #777; text-align: center; font-family: 'Pretendard'; font-size: 16px; font-weight: 700; line-height: 150%; }
    .title-wrap .title-detail .title-detail-item{  }
    .contents-wrap{ position: relative; display: flex; flex-direction: row; gap: 80px; justify-content: space-between; align-items: center; }
    .contents-wrap .valueBox-list{ width: 520px; height: 380px; display: flex; flex-direction: column; gap: 30px; padding: 50px; border-radius: 10px; border: 1px solid #fb0000; background-color: #222; box-shadow: 0px 0px 16px 2px rgba(198, 0, 0, 0.6); z-index: 1; }
    .contents-wrap .valueBox-list .valueBox-item{ display: flex; flex-direction: column; gap: 20px; }
    .contents-wrap .valueBox-list .valueBox-item .tag{ width: fit-content; display: inline-flex; padding: 6px 12px; justify-content: center; align-items: center; align-self: flex-end; border-radius: 6px; background-color: #333; color: #aaa; text-align: right; font-family: 'Pretendard'; font-size: 14px; font-weight: 700; }
    .contents-wrap .valueBox-list .valueBox-item .value-text{ display: flex; flex-direction: column; align-items: flex-start; gap: 12px; font-family: 'Pretandard'; }
    .contents-wrap .valueBox-list .valueBox-item .value-text .value-title{ color: #fff; font-size: 24px; font-weight: 700; }
    .contents-wrap .valueBox-list .valueBox-item .value-text .value-subTitle{ color: #aaa; font-size: 14px; font-weight: 500; }
    .contents-wrap .valueBox-list .value-line{ width: 100%; height: 1px; background-color: #fff; }
    .contents-wrap .valueBox-list .value-desc-wrap{  }
    .contents-wrap .valueBox-list .value-desc-wrap .desc-list{ display: flex; flex-direction: column; align-items: flex-start; gap: 10px; color: #777; font-family: 'Pretendard'; font-size: 16px; font-weight: 500; }
    .contents-wrap .valueBox-list .value-desc-wrap .desc-list .desc-item{  }
    .contents-wrap .bg-circle{ position: absolute; width: 540px; height: 540px; z-index: 0; left: 50%; right: 50%; transform: translate(-50%, 0); }

    @media screen and (max-width: 1400px) {

        gap: 100px;

        .contents-wrap{ gap: 60px; }
        .contents-wrap .valueBox-list{ width: 420px; height: 340px; }
        .contents-wrap .valueBox-list .valueBox-item .tag{ font-size: 12px; }
        .contents-wrap .valueBox-list .valueBox-item .value-text .value-title{ font-size: 20px; }
        .contents-wrap .valueBox-list .valueBox-item .value-text .value-subTitle{ font-size: 12px; }
        .contents-wrap .valueBox-list .value-desc-wrap .desc-list{ font-size: 14px; }

    }
    
    @media screen and (max-width: 992px) {

        gap: 40px; padding-bottom: 160px; 
        
        .title-wrap .title-detail{ font-size: 14px; }
        .contents-wrap{ flex-direction: column; gap: 60px; }
        .contents-wrap .valueBox-list{ width: 320px; height: unset; gap: 20px; padding: 40px 20px 58px; text-align: left; }
        .contents-wrap .valueBox-list .valueBox-item{ display: flex; flex-direction: column; gap: 30px; }
        .contents-wrap .valueBox-list .valueBox-item .tag{ font-size: 12px; }
        .contents-wrap .valueBox-list .valueBox-item .value-text{ gap: 12px; }
        .contents-wrap .valueBox-list .valueBox-item .value-text .value-title{ font-size: 20px; }
        .contents-wrap .valueBox-list .valueBox-item .value-text .value-subTitle{ font-size: 12px; }
        .contents-wrap .valueBox-list .value-desc-wrap .desc-list{ font-size: 12px; }
        .contents-wrap .valueBox-list .value-desc-wrap .desc-list .desc-item{  }
        .contents-wrap .bg-circle{ width: unset; height: unset; top: calc(50% - 120px); left: 50%; right: unset; }
        
    }
`;