import { FooterWrap } from "../styles/common/footer.styled";
import { Link } from 'react-router-dom';
import NxrLogo from "../assets/images/nxr-logo.png";


const Footer = () => {

    

    return(

        <FooterWrap>
            <div className="footer-wrap">
                <div className="footer-txt">
                    <div className="detail-txt-wrap">
                        <div className="logo">
                            <Link to="/" className="item" alt="넥스트러너스 로고" >
                                <img src={NxrLogo} className="" alt="" width="auto" height="14px" />
                            </Link>
                        </div>
                        <div className="detail-txt">
                            <ul className="detail-text-list">
                                <li className="detailTxt-item">
                                    <div className="title">연락처</div>
                                    <div className="txt">0507-1446-1171</div>
                                </li>
                                <li className="detailTxt-item">
                                    <div className="title">이메일</div>
                                    <div className="txt">contact@nextrunners.co.kr</div>
                                </li>
                            </ul>
                            <ul className="detail-text-list">
                                <li className="detailTxt-item">
                                    <div className="title">주소</div>
                                    <div className="txt">서울특별시 서초구 강남대로99길 45, 엠빌딩 6층</div>
                                </li>
                            </ul>
                            <ul className="detail-text-list">
                                <li className="detailTxt-item">
                                    <div className="title">사업자 등록번호</div>
                                    <div className="txt">540-86-00384</div>
                                </li>
                                <li className="detailTxt-item">
                                    <div className="title">대표</div>
                                    <div className="txt">이한별</div>
                                </li>
                                <li className="detailTxt-item">
                                    <div className="title">상호명</div>
                                    <div className="txt">넥스트러너스</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="gnb-txt">
                        <div className="gnb-wrap">
                            <div className="gnb-title">About us</div>
                            <ul className="lnb-list">
                                <li className="lnb-item"><Link to="/vision">Vision</Link></li>
                                <li className="lnb-item"><Link to="/history">History</Link></li>
                                <li className="lnb-item"><Link to="/ci-bi">CI / BI</Link></li>
                            </ul>
                        </div>
                        <div className="gnb-wrap">
                            <div className="gnb-title">Service</div>
                            <ul className="lnb-list">
                                <li className="lnb-item"><Link to="/service/brand">Brand</Link></li>
                                {/* <li className="lnb-item">NXR LAB</li> */}
                            </ul>
                        </div>
                        <div className="gnb-wrap">
                            <div className="gnb-title">NXR Team</div>
                            <ul className="lnb-list">
                                <li className="lnb-item"><Link to="/culture">Culture</Link></li>
                                <li className="lnb-item"><Link to="/benefits">Benefits</Link></li>
                                <li className="lnb-item"><Link to="/interviews">Interviews</Link></li>
                            </ul>
                        </div>
                        <div className="gnb-wrap">
                            <div className="gnb-title">Recruit</div>
                            <ul className="lnb-list">
                                <li className="lnb-item"><Link to="/recruit">Recruit</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="footer-copyright">
                    <div className="familySite-wrap detail-area">
                        <p className="familySite-item">Family Site</p>
                    </div>
                    <div className="copyright-wrap detail-area">
                        <p className="copyright-item">Copyright ⓒ 2024 Nextrunners<br className="pc_none" /> All rights reserved.</p>
                    </div>
                </div>
            </div>
        </FooterWrap>
    )
}

export default Footer;
