// import { InterviewBoardWrap } from "../styles/common/interviewBoard.styled";
import React from "react";
import { InterviewBoardWrap } from "../styles/common/interviewBoard.styled";
import { Link } from "react-router-dom";


export const galleryData = [
    {
        id: 1,
        title: ['CEO'],
        backgroundColor: '#540000',
        keywords: ['이한별'],
        description: [
            {
                question: "한 문장으로<br /> 자기소개 부탁드려요.",
                answers: [ 
                    { 
                        keyword: "CEO · 이한별",
                        answer : [ "넥스트러너스에서 리더로 함께하고 있는 이한별입니다." ]
                    }
                ]
            },
            {
                question: "넥스트러너스는 성장을 중요시 하는 회사 같은데,<br class='pc_show tab_none' /> 특별한 이유가 있을까요?",
                answers: [ 
                    {
                        keyword: "CEO · 이한별",
                        answer: [ "<b>넥스트러너스라는 사명이 담고 있는 의미를 알면 왜 러너(구성원) 모두의 성장을 중요시 하는지 이해하기 쉽습니다, 넥스트는 ‘다음 세대', ‘미래‘라는 뜻을 담고 있으며, 러너스는 ‘달리는 사람(Runners)‘, ‘학습하는 사람들(Learners)‘, ‘함께 배우다(Learn + Us)’ 라는 3가지 중의적인 의미를 담고 있습니다.</b> 종합하면 넥스트러너스는 ‘다음 세대를 위한 보다 실용적인 교육을 제공하고 함께 성장한다’는 의미를 담고 있습니다. <b>다음 세대를 위한 실용적인 교육을 제공하고 더 많은 사람들의 성장을 돕는 조력자가 되기 위해서는 당연히 러너들도 끊임없이 성장</b>해야 합니다. 교육을 통해 회사, 러너, 고객 모두가 함께 성장할 수 있는 세상을 만드는 것이 우리의 비전입니다." ]
                    }
                ]
            },
            {
                question: "그럼 대표님은 어떠한 본인의 성장과<br class='pc_show tab_none' /> NXR의 성장 경험을 가지고 계신가요?",
                answers: [ 
                    {
                        keyword: "CEO · 이한별",
                        answer: [ "넥스트러너스는 설립 이후 4년동안 2만명이 넘는 코딩 영재들과 창업가들을 양성해왔습니다. 그리고 그들의 성장을 돕는 것과 동시에 회사 또한 매년 300% 이상 매출 성장을 만들어내고 있습니다. <b>러너들과 함께 회사를 지속 성장 시키면서 저 또한 더 나은 창업가 그리고 더 나은 교육자로 가늠할 수 없을 만큼 많은 성장을 했다는 것을 체감하고 있습니다.</b> 과거 창업을 통해 경제적 자유를 달성 하겠다는 단편적인 목표를 넘어서 제가 추구하는 행복 가치 실현을 위해 더 많은 사람들에게 실용적인 교육을 제공하고 싶다는 목표를 가지게 되었습니다." ]
                    }
                ]
            },
            {
                question: "현재 넥스트러너스 팀이 가지고 있는 장점은<br class='pc_show tab_none' /> 무엇이라고 생각하나요?",
                answers: [ 
                    {
                        keyword: "CEO · 이한별",
                        answer: [ "넥스트러너스는 어떤 팀보다도 린(Lean)하게 실험을 수행할 줄 아는 팀입니다. <b>우리는 지금도 수 많은 가설들을 수립하고 그것들은 가장 효율적인 방식으로 빠르게 검증하는 실험들을 무한히 반복하고 있습니다.</b> 넥스트러너스 팀에 합류하게 되면 끊임 없는 실험을 하며 회사 안에서도 창업하는 것에 버금가는 경험들을 수 없이 하게 될 것입니다. 그리고 <b>그런 수 많은 실험의 경험치를 통해 인생 중에 가장 밀도 높은 성장을 할 수 있습니다.</b>" ]
                    }
                ]
            },
            {
                question: "앞으로 어떤 분이 러너(runner)로<br class='pc_show tab_none' /> 함께 하기를 기대하나요?",
                answers: [ 
                    {
                        keyword: "CEO · 이한별",
                        answer : [ "<b>성장, 융화, 실력, 책임감, 비전이라는 5가지 키워드로 요약할 수 있습니다.</b> 끊임없이 성장에 대한 욕구가 있고, 팀에 융화될 수 있으며, 실력으로 자신의 가치를 입증할 수 있는 러너와 함께 하고 싶습니다. 또한 맡은 업무를 기한 내에 마무리하는 책임감을 가지고 있으며 넥스트러너스의 비전에 공감하고 같은 미래를 상상하면서 함께할 러너를 찾습니다." ] 
                    }
                ]
            },
            {
                question: "마지막 질문은 조금 포괄적 일 수도 있을 거 같은데요.<br class='pc_show tab_none' /> 앞으로 목표가 어떻게 되시나요?",
                answers: [
                    {
                        keyword: "CEO · 이한별",
                        answer: [ "넥스트러너스는 이미 많은 데스밸리를 넘기고 제이커브를 그리며 지속 성장할 수 밖에 없는 궤도에 올라섰습니다. <b>넥스트러너스를 통해서 기존 입시와 취업 교육 위주의 낡은 교육을 대체하고 보다 실용적인 교육을 제공하여 다음 세대 모두가 필수적으로 거쳐가야만 하는 혁신적인 교육 회사를 만드는 것이 가장 큰 목표입니다.</b> 이 목표를 향한 과정을 저희 러너들과 함께 하여 많은 성취들을 같이 나누고 싶습니다!" ]
                    }
                ]
            },
        ]
    },
    {
        id: 2,
        title: ['CEO staff', 'COO'],
        backgroundColor: '#5D3800',
        keywords: ['이희은 · 김민기'],
        description: [
            {
                question: "한 문장으로<br /> 자기소개 부탁드려요.",
                answers: [
                    {
                        keyword: "CEO staff · 이희은",
                        answer: [ "안녕하세요! 넥스트러너스 CEO Staff 이희은입니다. <b>넥스트러너스의 꾸준한 성장을 만드는 의사결정이 이루어지도록 대표님을 돕는 조력자 역할</b>을 하고 있습니다!" ]
                    },
                    {
                        keyword: "COO · 김민기",
                        answer: [ "안녕하세요! 넥스트러너스에서 COO를 맡고 있는 김민기입니다. <b>극한의 제너럴리스트를 추구하고 있습니다.</b>" ]
                    }
                ]
            },
            {
                question: "현재 하고계신 일에 대해<br class='pc_show tab_none' /> 조금 더 자세히 설명 부탁드려요.",
                answers: [
                    {
                        keyword: "CEO staff · 이희은",
                        answer: [ "회사의 모든 팀이 최고의 성과를 낼 수 있도록 조율·관리하고, 대표님과 별도의 프로젝트를 진행하기도 합니다. <b>CEO Staff란 직무가 다소 생소할 수도 있는데, CEO의 대리 역할을 하면서 회사 내부적인 운영을 감독</b>하는 포지션이라고 생각해 주시면 이해에 도움이 되실 것 같아요." ]
                    },
                    {
                        keyword: "COO · 김민기",
                        answer: [ "넥스트러너스 운영총괄팀 내에서 각 <b>팀에 문제를 같이 해결하기도 하고, 업무가 진행될 수 있도록 조율해서 프로세스를 만들어내는 것을 하고 있습니다.</b> 이외에도 주요 K-디지털 트레이닝, K-디지털 기초역량훈련과 같은 정부 사업을 수주하고 신사업을 추진하는 업무도 담당하고 있어요. 그래서 실무도 같이 하면서 매니지먼트도 병행하고 있다고 봐주시면 됩니다." ]
                    }
                ]
            },
            {
                question: "요즘 가장 중요하게 생각하고 있는 과제가 있다면<br class='pc_show tab_none' /> 무엇인지 설명해 주세요.",
                answers: [
                    {
                        keyword: "CEO staff · 이희은",
                        answer: [ "<b>회사의 리스크 관리에 집중하고 있어요.</b> 회사 규모가 커지면서 정말 다양한 의사결정들이 행해지고 있는데요, 채용부터 지출, 상품, 마케팅 등 전사에서 행해지고 있는 의사 결정이나 방식에 위험요소는 없는지 점검하고 추후 발생 할 수 있는 문제점들을 사전에 진단하고 개선할 수 있는 체계를 만들어가기 위해 고민하고 있습니다." ]
                    },
                    {
                        keyword: "COO · 김민기",
                        answer: [ "요새 생각하는 과제는 인원이 급속도로 늘어가면서 <b>‘어떻게 효율적으로 일하고 조직문화를 만들어갈 수 있을까’가 고민</b>이 됩니다. 여러 회사에서 다양한 배경을 가지신 분들과 신입 분들이 섞이면서 서로가 가진 경험이 너무 강하다는 부분을 느끼고 있어요.<br />",
                            
                        "사실 서로 건전하게 토론하고 의견을 제시하는 부분에서 오히려 장점도 분명히 있습니다. 그런데 넥스트러너스에는 넥스트러너스 만에 조직문화가 있고 각 구성원도 최대한 조직문화에 녹아들 수 있도록 하는 게 좋다고 생각해요. <b>어떤 게 좀 더 러너(runner)스러운 것인지 생각하고 의사결정하게 되면 많은 시간을 아낄 수 있거든요.</b><br />",
                        
                        "그래서 최근에 주말에 카페에 가서 하루 만에 컬쳐 덱을 만들어서 배포하기도 했습니다. 조직 구조나 이런 부분들도 고민이고요. 최근 경영학 박사과정을 파트타임으로 밟고 있는데도 불구하고 조직문화는 정답이 없는 굉장히 어려운 숙제인 것 같습니다." ]
                    }
                ]
            },
            {
                question: "넥스트러너스에서 일하며 얻을 수 있는<br class='pc_show tab_none' /> 성장 경험은 무엇인가요?",
                answers: [
                    {
                        keyword: "CEO staff · 이희은",
                        answer: [ "<b>대표님의 시선에서 조직을 바라보고 업무를 관리해야 하다 보니 업무 역량과 시야가 넓어질 수밖에 없어요.</b> 게다가 모든 조직 구성원분들이 열정을 가지고 다양한 업무를 진행하셔서, 진행되고 있는 업무를 파악하는 것 자체만으로 엄청난 양의 업무 지식을 습득할 수 있게 됩니다. 그 과정에서 개선해야 할 점은 무엇인지, 어떤 도움을 드릴 수 있을지 고민하다 보면, 스스로 터득하게 되는 것들도 아주 많습니다." ]
                    },
                    {
                        keyword: "COO · 김민기",
                        answer: [ "<b>가장 큰 성장 경험은 조직이 성장하면서 이전에는 경험하지 못한 새로운 문제들이 생긴다는 점이 정말 재미있는 것 같아요.</b> 어느 회사든 ‘문제 해결력’을 중요하게 생각하잖아요. 그런데 회사가 성장하지 않고 내가 하는 일이 올해도 내년도 비슷하다면 내가 해결해 내는 문제도 거의 유사하지 않을까요? 그런 회사에서는 저는 성장한다는 느낌을 아예 못 받을 것 같아요. 그리고 그런 문제를 해결함으로 인해서 회사가 더 나아가는 것도 재미있습니다.<br />",

                        "<b>해결해야 되는 문제가 생기면 ‘아! 내가 한번 또 성장할 기회구나’ 싶어서 스트레스받지만 즐겁고, 그렇기 때문에 지속할 수 있게 되는 원동력이 되는 것 같습니다.</b>" ]
                    }
                ]
            },
            {
                question: "그럼, 어떤 분들과<br /> 함께 일하고 싶으신가요?",
                answers: [
                    {
                        keyword: "CEO staff · 이희은",
                        answer: [ "저는 기본에 충실한 것부터 가장 중요하다고 생각해요. <b>주어진 일에 책임감을 가지고, 정해진 규칙을 지키고, 자신의 결과물을 계속해서 욕심내며 개선해가는! 이런 것들이 어찌 보면 사소하지만 당연 갖추고 있어야 하는 제일 중요한 업무 태도</b>인 것 같아요. 이것들이 잘 지켜지는 분이라면 충분히 성장하셨을 것이고, 성장하고 계실 것이고, 성장할 가능성이 있다고 생각합니다." ]
                    },
                    {
                        keyword: "COO · 김민기",
                        answer: [ "<b>제가 일하고 싶은 분들은 그릿(Grit)이 있는 분들과 회복력이 좋은 분들을 선호</b>해요. 사실 회사 생활하기가 쉽지 않잖아요. 단순 알바처럼 똑같은 일을 반복하는 것도 아니고 넥스트러너스 같이 성장하는 회사에서는 책임과 역할이 계속 작게 바뀔 수 있거든요. 그렇다 보니 일을 하다 보면 긍정적으로 스트레스를 받기도 하고 해내기 위해서는 맨땅에서 삽질을 할 수도 있어야 해요. <b>정해진 목표를 향해서 집요하게 달려가는 힘, 그리고 그 과정에서 상처를 극복할 수 있는 회복력이 정말 중요하다고 생각해요.</b><br />",
                            
                        "그래서 ‘멋진 일’만 하고 싶어 하는 사람들은 사실 다른 곳에 지원하시는 것이 훨씬 나을 거예요. 홈런을 치기 위해서는 수많은 삼진과 팀을 위한 안타가 있기 때문에 1%~2% 비중 홈런이 나오는 것이라고 생각합니다. 그렇기에 과정과 실패를 무시하고 멋진 일만 하고 싶은 분들은 넥스트러너스는 분명히 좋은 회사가 아닙니다. 데이터 분석가도 인사이트 하나를 내기 위해 데이터 수집부터 전처리를 열심히 해야 하잖아요. 대부분의 직무가 저는 똑같다고 봅니다." ]
                    }
                ]
            },
            {
                question: "넥스트러너스는 어떤 회사인가요?<br class='pc_show tab_none' /> 복지, 문화, 동료 등 가장 좋아하는 것이 있다면<br class='pc_show tab_none' /> 소개해주세요.",
                answers: [
                    {
                        keyword: "CEO staff · 이희은",
                        answer: [ "복지, 문화, 동료 한 가지만 언급하기 어려울 정도로 좋은 점들이 많아서 고민이 되는데요!ㅎㅎ <b>‘다양한 복지’, ‘즐거운 문화’, ‘좋은 동료’ 이 모든 것들을 형식적으로만 표현해 둔 곳이 아니라 실제로 쉽게 체감할 수 있는 회사</b>라는 점이 가장 자랑할 수 있는 부분이 아닐까 싶어요!<br />",

                        "일은 힘들어도 사람이 힘들면 못 다니는 곳이 회사인데, 정말 좋은 동료들이 함께 일하고 있어서 우리가 만들어가는 문화가 항상 즐거운 것 같아요! <b>회사에서도 항상 좋은 보상을 해주기 위해 노력하고 있어서 자율출퇴근, 자기계발비 지원 등등 정말 아낌없는 지원을 해주고 있고요.</b>" ]
                    },
                    {
                        keyword: "COO · 김민기",
                        answer: [ "<b>넥스트러너스는 압축적인 성장이 가능하고 내가 하고 싶은 도전이 있다면 다양한 이해관계자를 설득하여 도전해 볼 수 있는 회사</b>라고 생각합니다. 이건 저 뿐만 아니라 누구에게나 적용이 되고요. 심지어 개발자이신 분이 특정 교육을 마케팅해보고 운영까지 모두 담당해보고 싶다고 하셔서 기회를 드린 적이 있을 정도로 포지션에 관계 없이 성장을 위한 방향은 전방향으로 열려있습니다.<br />",
                            
                        "그리고 또 <b>빠르게 변화하는 환경</b>이 저는 가장 좋은데요. 저는 도파민 중독이라고 스스로 생각할 정도로 사실 같은 일만 계속하라고 하면 절대 못하는 사람이 거든요. 극단적으로 같은 것만 쓰는 걸 싫어해서 물건으로는 노트북을 1년에 6번도 바꾼 적 있고요. 휴대폰도 3개월~6개월에 한번씩 바꿀 정도로 도파민 중독이 강한 사람입니다.<br />",
                        
                        "그런데 <b>넥스트러너스에서는 6개월 전에 했던 업무랑 6개월 후에 하는 업무가 계속 달라지다 보니 너무 재미있습니다. 회사가 계속해서 성장한다는 것은 성장에 따라서 나에게 다른 역할과 책임을 요구한다는 뜻이거든요.</b><br />",
                        
                        "그래서 계속 성장하는 회사에 있고, 고객들이 우리를 선택해 주는 것이 정말 감사할 따름입니다." ]
                    }
                ]
            },
            {
                question: "마지막으로, 앞으로 넥스트러너스에서<br class='pc_show tab_none' /> 이루고 싶은 것이 있다면 말씀해주세요!",
                answers: [
                    {
                        keyword: "CEO staff · 이희은",
                        answer: [ "<b>안정적인 매출을 낼 수 있는 조직 환경을 세팅해 회사를 성장시키는 것이 가장 큰 목표입니다.</b><br />", 
                        
                        "대표님의 번뜩이는 사업 비전에 감탄한 적이 한두번이 아닌데요, 구성원 모두가 이 비전 아래 최고의 성과를 만들어갈 수 있도록, 올바른 체계와 업무 방식, 조직 문화를 만들어서 회사의 성장을 만들어내는 것이 목표입니다! 입사시기부터 지금까지의 회사의 성장속도를 감안해보면, 유니콘이 되는 것도 머지 않았다고 생각이 드네요ㅎㅎ" ]
                    },
                    {
                        keyword: "COO · 김민기",
                        answer: [ "<b>넥스트러너스가 유니콘이 되게 하는 게 목표입니다.</b> 저는 창업을 하다가 한번 실패한 경험이 있어서 깨달은 것은 회사 안에서는 회사를 레버리지해서 매출을 내는 게 상대적으로 쉽다는 거예요. 나 혼자 1,000억 매출 회사를 만드는 것보다 넥스트러너스를 1,000억 매출 회사로 만드는 게 쉽다는 뜻입니다. 반대로 회사에서는 날아다니는 사람도 막상 혼자 창업해 보면 1억 매출도 내기 정말 어렵죠.<br />",

                        "<b>저는 학생부터 지금까지 창업가, 사업가로서 세상에 영향을 끼치는 기업, 서비스를 만드는 게 목표였고 그게 꼭 나 혼자서 할 필요는 없다는 것을 깨달았습니다.</b> 넥스트 러너스 내에서는 필요한 자원과 좋은 동료들이 있기 때문에 저는 10년 내로 넥스트 러너스가 유니콘이 될 수 있다고 생각해요." ]
                    }
                ]
            },

        ]
    },
    {
        id: 3,
        title: ['교육팀'],
        backgroundColor: '#006653',
        keywords: ['양성훈 · 곽운도 · 우도현 · 김태형 · 오민교'],
        description: [
            {
                question: "한 문장으로<br /> 자기소개 부탁드려요.",
                answers: [ 
                    {
                        keyword: "교육 1팀 · 양성훈",
                        answer: [ "안녕하세요. 교육 1팀의 8기통 엔진을 담당하고 있는 양성훈입니다." ]
                    },
                    {
                        keyword: "교육 2팀 · 곽운도",
                        answer: [ "안녕하세요. 초격차캠프 프론트엔드 과정을 담당하고 있는 곽운도입니다." ]
                    },
                    {
                        keyword: "교육 3팀 · 우도현",
                        answer: [ "안녕하세요! 넥스트러너스 교육 3팀이자 사업개발캠프를 담당하는 우도현입니다." ]
                    },
                    {
                        keyword: "교육 4팀 · 김태형",
                        answer: [ "심심할 틈 없는 기획자 김태형입니다." ]
                    },
                    {
                        keyword: "교육 5팀 · 오민교",
                        answer: [ "안녕하세요 늘이 아빠 오민교 입니다. 변화를 두려워하지 않고, 도전하는 삶을 사는 오민교 입니다." ]
                    },
                ]
            },
            {
                question: "현재 하고계신 일에 대해<br class='pc_show tab_none' /> 조금 더 자세히 설명 부탁드려요.",
                answers: [ 
                    {
                        keyword: "교육 1팀 · 양성훈",
                        answer: [ "저는 교육 1팀 리드를 맡고 있습니다.<br/>",

                            "우선, 교육 1팀에 대해 소개 드리겠습니다. <b>교육 1팀은 파이썬 기반의 백엔드 개발자 양성을 목표로 교육 기획부터 취업지원까지 전 과정을 기획하고 운영하고 있습니다.</b> 리드로써 담당하고 있는 부분은 최고의 학습을 제공하기 위해 <b>과정별로 필요한 콘텐츠 및 과제 제작에 참여하고 있으며, 수강생분들이 참여하고 있는 과정 전반에 걸쳐 최상의 만족도를 느낄 수 있도록 기획 및 운영을 총괄</b>하고 있습니다." ]
                    },
                    {
                        keyword: "교육 2팀 · 곽운도",
                        answer: [ "<b>프론트엔드 코스를 기획하고 설계, 운영하는 교육2팀에서 리드 역할</b>을 담당하고 있습니다. 요즘 개발자로 커리어를 전환하기 위해 코딩을 배우시는 분들이 정말 많은데요. 수강생분들을 프론트엔드 개발자로 양성하기 위한 학습 로드맵을 구상하고, 그 로드맵에 따라 커리큘럼을 구성하는 역할을 하고 있어요. 또, 콘텐츠나 과제를 직간접적으로 제작하기도 하고, 6개월간 진행되는 코스의 운영 전반도 함께 살피고 있습니다." ]
                    },
                    {
                        keyword: "교육 3팀 · 우도현",
                        answer: [ "저는 넥스트러너스 오즈코딩스쿨의 IT 스타트업 사업개발 캠프를 담당하고 있습니다. 사업개발 캠프는 뛰어난 IT 기반 창업가를 육성하고 있고, 대부분의 수강생들이 창업을 목표로 하고 있습니다. 그뿐만 아니라 사업기획/개발, PM/PO 등 전문성을 요구하는 직무의 취업을 준비하기도 합니다. <b>저는 사업개발 캠프의 전체적인 교육 커리큘럼을 기획하고 관리함과 동시에 러닝코치로서 수강생들이 가진 창업 아이디어를 구체화하고 실제 출시하여 수익화까지 할 수 있도록 방향성을 제시</b>해주고 있습니다. 저도 2년 정도의 플랫폼을 창업했던 경험이 있어 이를 바탕으로 각 수강생에 맞춘 취/창업 멘토링을 해 주고 있습니다." ]
                    },
                    {
                        keyword: "교육 4팀 · 김태형",
                        answer: [ "창업, 부업 등을 통해 경제적 자유 혹은 더 많은 수익을 얻기 위해 고민하는 분들을 위한 서비스를 만들고 있습니다. 주 업무는 <b>강사 섭외 및 관리, 서비스 기획입니다.</b>" ]
                    },
                    {
                        keyword: "교육 5팀 · 오민교",
                        answer: [ "현재는 새롭게 만들어질 팀의 파트들을 하나하나 채워나가고 있습니다. 예를 들어 수강생들이 관심을 가질만한 상세페이지를 기획 구상하거나, 함께 일할 동료를 찾고, 커리큘럼을 제작하는 등, <b>새로운 팀을 위해 모든 업무를 하고 있습니다.</b>" ]
                    },
                ]
            },
            {
                question: "정말 다양한 일들을 하고 계시네요!<br class='pc_show tab_none' /> 넥스트러너스에서 소속해 계신 팀에 대한 소개를<br class='pc_show tab_none' /> 부탁드려요.",
                answers: [ 
                    {
                        keyword: "교육 1팀 · 양성훈",
                        answer: [ "<b>교육 1팀은 초격차 백엔드 과정과 린스타트업 MVP 풀스택 과정을 담당하고 있습니다.</b> 운영 매너지로 계신 이수현 운영 매니저님과 제가 초격차 백엔드 과정을 담당하고 있습니다. 그리고 린스타트업 MVP 풀스택 과정은 정유준 매니저님께서 담당하고 있습니다. 여기에 매 기수 우수 수료생분들이 조교로 합류해 운영의 완성도를 높여주고 있습니다. (샤라웃 조교님들 박민정, 김산이, 김희은, 김진우, 방민식, 정유준, 이승현, 임대용)" ]
                    },
                    {
                        keyword: "교육 2팀 · 곽운도",
                        answer: [ "<b>교육 2팀은 오즈코딩스쿨의 프론트엔드 과정을 함께 만들어가는 팀입니다.</b> 지금은 저와 운영매니저 민지 님, 그리고 각 기수의 조교님들과 함께 일하고 있어요. 개강 전부터 학습 환경을 세팅하고, 콘텐츠와 과제를 준비하고 있어요. 오즈코딩스쿨의 강점 중 하나가 밀착 케어인데요, 매일 수강생분들을 찾아가서 헬스 체크를 진행하고 필요한 도움을 제공하기도 합니다. <b>개강부터 수료까지, 그리고 수료 이후까지 수강생 여러분을 돕기 위한 모든 것을 하는 팀이라고 생각해 주시면 될 것 같습니다.</b>" ]
                    },
                    {
                        keyword: "교육 3팀 · 우도현",
                        answer: [ "<b>교육 3팀은 오즈코딩스쿨의 사업개발 캠프를 담당</b>하고 있고, 현재는 저와 운영 매니저 둘뿐이지만 추후 조교님도 합류할 예정입니다. 사업개발 캠프를 같이 기획하고 운영하고 있고, 매일 많은 생각과 방향성을 논의하면서 더 좋은 교육 커리큘럼이 될 수 있도록 노력하고 있습니다. 특히 저와 운영 매니저님은 서로가 가진 강점들이 명확해서 더 많은 시너지가 나는 것 같은데요, 저는 창업 관련 역량이 있고 운영 매니저님은 교육 행정/운영 역량이 뛰어나 서로의 부족한 부분을 채워주고 있어 팀워크가 좋은 것 같습니다." ]
                    },
                    {
                        keyword: "교육 4팀 · 김태형",
                        answer: [ '<b>교육 4팀은 넥스트러너스 내 “라이프해킹스쿨”을 담당하고 있습니다.</b> 실전 창업을 하는 “창업부트캠프”외 온라인 셀러, 직장인 부업, SNS 수익화 외 각종 VOD를 서비스하는 플랫폼입니다. 수강생의 입장에서 더 나은 교육을 받고 실질적인 성과를 얻게 하기 위해 고민하는 팀입니다.' ]
                    },
                    {
                        keyword: "교육 5팀 · 오민교",
                        answer: [ "<b>교육 5팀은 웹 풀스택 온라인 과정을 운영하게 될 팀 입니다.</b> 슬로건은 ‘누구나 관리받고 개발자가 될 수 있다.’ 로서 ‘관리형 부트캠프’를 지향하고 있습니다. 풀스택 온라인 과정에 오셔서 관리받다 보면 누구나 개발자가 될 수 있습니다. 또한 취업 하이퍼 패스를 위해 실무형 포트폴리오 제작에 공을 들이고 있습니다. <b>수료 이후에도 꾸준히 포트폴리오를 만들 수 있는 커뮤니티를 통해 지속해서 성장할 수 있는 환경을 만들어 드립니다.</b>" ]
                    },
                ]
            },
            {
                question: "매달 개강하고 한 기수가 몇 개월씩 이어지니<br class='pc_show tab_none' /> 관리하는 수강생 수가 많을 것 같아요.<br class='pc_show tab_none' /> 관리는 어떻게 하나요?",
                answers: [ 
                    {
                        keyword: "교육 1팀 · 양성훈",
                        answer: [ "한 달에 한 기수씩 들어오는 상황에서 매 기수 최선을 다하는 것이 쉽지 않습니다. <b>그래서 주차별, 과목별 만족도와 헬스 체크를 통해 수강생들의 이야기에 귀를 기울이고 있으며, 이를 바탕으로 빠르게 개선함으로 수강생들의 만족도를 최상으로 유지시키기 위해서 노력</b>하고 있습니다. 그 외에도 수강생을 위한 수준별 학습 지원을 통해 모두가 성장할 수 있는 환경을 만들고, 현역 개발자들과 함께하는 3:1 멘토링을 통해 실무에 가까운 기술들을 학습할 수 있도록 돕고 있습니다. 아울러, 이러한 노력들이 매 기수 만족도 4.0 이상이라는 좋은 결과로 나타나고 있습니다." ]
                    },
                    {
                        keyword: "교육 2팀 · 곽운도",
                        answer: [ "수강생분들을 잘 관리하기 위한 방법으로 <b>조교 시스템, 수준별 학습반, 데일리 헬스 체크</b>를 말씀드릴 수 있을 것 같아요.<br />",
                            
                        "먼저 조교님들이 항상 여러분의 <b>학습 공간에 상주하면서 질의응답</b>을 하고 있어요. 여러분이 대화하고자 하면 딱 10초 안에 조교님들을 만날 수 있습니다.<br />",
                        
                        "두 번째는 <b>수준별 학습반</b>입니다. 오즈코딩스쿨은 새싹반, 열정반, 심화반이라는 3단계의 수준별 학습 반을 운영하고 있어요. 각각의 학습반에는 수준에 맞는 콘셉트로 준비되어 있답니다. 학습 진행이 어려운 분들에게는 이해할 때까지 알려드리는 새싹반을, 그리고 학습이 다소 쉽게 느껴지는 분들에게는 현업 개발자와 사이드 프로젝트를 진행할 수 있는 환경을 만들어 드리고 있어요.<br />",
                        
                        "마지막으로 데일리 헬스 체크도 언급하고 싶습니다. 수강생분들 중에 힘든 일이 있어도 혼자 끙끙 앓다가 꿈을 포기하는 분들이 있더라고요. 그래서 저희가 <b>매일 3명씩 찾아가서 헬스 체크를 진행</b>하고 있습니다. 학습에 어려움은 없는지, 학습에 집중하기 어려운 일은 없는지 저희가 먼저 다가가서 체크하고 있어요." ]
                    },
                    {
                        keyword: "교육 3팀 · 우도현",
                        answer: [ "가장 중요한 것은 <b>체계적인 교육 커리큘럼과 가이드라인</b>입니다. 수강생들의 역량도 천차만별이기에 수준별로 구성되어 있는 강의와 과제들을 통해 모든 수강생이 포기하지 않고 원하는 목표를 이루어서 수료할 수 있습니다.<br />",
                            
                        "그리고 <b>수강생들끼리 자유로운 소통을 할 수 있도록 다양한 활동과 행사를 제공하고 있습니다.</b> 교육을 제공하는 운영진과도 꾸준히 소통하지만, 사업개발 캠프 수강생들은 창업을 희망하기에 서로의 아이디어와 생각들을 공유하는 시간을 더 소중하게 여깁니다. 그 속에서 내 아이디어를 피드백 받기도 하고, 스스로 팀을 이루어서 함께 하기도 합니다.<br />",
                        
                        "그리고 온라인으로만 하면 아쉬울 수 있으니 <b>오프라인에서도 만날 수 있는 네트워킹 데이 행사를 개최하여 더 끈끈한 관계를 형성</b>하기도 합니다." ]
                    },
                    {
                        keyword: "교육 4팀 · 김태형",
                        answer: [ "교육 4팀의 경우 최장 2개월입니다. <b>뒤처지거나 이탈하시는 인원을 최소화하기 위해서 담당 매니저가 지속적으로 동기부여 및 과제 체크, 각종 안내를 합니다.</b> 지불한 비용만큼의 만족도를 얻을 수 있게 하기 위해 끊임없는 커뮤니케이션을 시도하고 빠르게 피드백을 적용합니다." ]
                    },
                    {
                        keyword: "교육 5팀 · 오민교",
                        answer: [ "관리의 퀄리티를 높게 가져가기 위해 기수당 40명 이상은 받지 않고 있습니다. 또한 <b>한 분 한 분 집중적으로 관리하기 위해 담당자를 기본적으로 한 명씩 배정하고 있으며, 수강생 현황 파악을 위한 헬스 체크도 매일 진행하고 있습니다.</b> 또한 수강생 본인의 상태를 알게 하기 위해 <b>수강생 평가 지표를 마련하여 부족한점을 파악하고 보완</b>할 수 있도록 분반 제도를 운영하고 있습니다." ]
                    },
                ]
            },
            {
                question: "누군가를 책임지고 교육한다는 건, 쉽지 않은 일일 것 같아요.<br class='pc_show tab_none' /> 그 와중에도 일하면서 보람을 느끼는 부분이 있다면요?",
                answers: [ 
                    {
                        keyword: "교육 1팀 · 양성훈",
                        answer: [ "<b>6개월이라는 힘든 과정을 수료한 수강생분들 남겨준 후기 하나하나에 감동과 보람을 느낍니다.</b> 많은 후기 중에서도 몸이 좋지 않아 중간에 몇 번이나 포기하려고 했던 수강생이 수료를 하며 남겼던 감사의 인사와 늦은 나이에 초격차 캠프에 참여해 걱정이 많았는데 과정 수료를 통해 코딩에 재미를 느꼈고 앞으로 개발자가 되기 위해 더 노력하겠다는 글이 기억에 남습니다. 그리고 수료 후 개발자로 취직해 밥이나 커피를 사주겠다는 아주 기특한 수료생의 연락을 받으면 저도 모르게 미소가 지어집니다." ]
                    },
                    {
                        keyword: "교육 2팀 · 곽운도",
                        answer: [ "코스 초반에 학습에 <b>어려움을 겪던 수강생이 나중에 폭풍 성장한 모습을 보면 가장 뿌듯한 것 같습니다.</b> 프로젝트 때 특히 반짝반짝 빛나는 분들이 있는 것 같아요. 오랜 고민 끝에 만든 새로운 콘텐츠나 서비스에 대해 수강생분들이 좋게 평가해 주실 때도 정말 뿌듯합니다. 대부분의 수강생분들이 저희가 노력하고 있다는 것을 알아주시는 것 같아요. 그래서 더 잘하고 싶어집니다." ]
                    },
                    {
                        keyword: "교육 3팀 · 우도현",
                        answer: [ "제가 담당했던 수강생들이 교육 중이나 수료 이후에 수익화를 성공하여 뚜렷한 성과를 냈을 때도 좋지만, <b>교육 초반에 갈피를 못 잡던 수강생들이 한 달도 안 되어서 방향성을 설정하고 본인의 아이디어를 실제로 시장에 검증하고 있는 걸 보면 너무나 뿌듯합니다.</b> 그리고 수료 후에도 저에게 상담을 요청하거나 성과를 공유 해주는 수료생들을 보면 괜히 더 힘이 나기도 합니다." ]
                    },
                    {
                        keyword: "교육 4팀 · 김태형",
                        answer: [ "<b>아무것도 모르는 수강생이 높은 월 매출을 달성했다고 말씀해 주실 때, 지방에 거주하는 수강생이 사무실까지 찾아와서 배우려고 할 때</b> 보람과 동시에 책임감을 느낍니다." ]
                    },
                    {
                        keyword: "교육 5팀 · 오민교",
                        answer: [ "<b>누군가의 커리어를 함께 설계해 나아간다는 건 항상 모험을 하고 있는 기분이 들어서 좋습니다.</b> 동료를 찾아 바다를 탐험하는 해적들처럼, 개발자가 되기 위해 모인 동료들의 목표를 이루게 도와주는 역할이 참으로 뿌듯하고 기쁩니다. 힘들어서 포기하고 싶은 수강생에게 응원의 말로 도움을 줄 수 있을 때, 응원에 힘입어 견뎌내고 수료하여 취업에 성공하는 모습을 볼 때면 보람을 느끼고 뿌듯한 마음이 이루 말할 수 없이 큽니다." ]
                    },
                ]
            },
            {
                question: "넥스트러너스에서 일하며 얻을 수 있는<br class='pc_show tab_none' /> 성장 경험은 무엇인가요?",
                answers: [ 
                    {
                        keyword: "교육 1팀 · 양성훈",
                        answer: [ "<b>더 나은 방향으로 성장할 수 있도록 모든 것이 허용되는 문화는 제가 성장할 수 있는 가장 큰 자양분이었습니다.</b> 주 단위 회고를 통해 어떤 문제들이 있었는지 파악하고 차주까지 개선안을 마련해 더 나은 결과를 만들고 수치로 증명하는 일들은 객관적인 시선에서 업무를 볼 수 있는 능력을 키워주었습니다. 또한, <b>더 나은 결과를 만드는 과정에서 부딪치는 한계를 깨기 위해 가설을 세우고 검증하는 방식은 문제를 논리적으로 해결할 수 있는 능력을 키워주었습니다.</b> 성장을 위해 모든 것이 허용해 주는 문화 덕분에 객관적이고 논리적으로 업무를 할 수 있는 능력을 키울 수 있었습니다." ]
                    },
                    {
                        keyword: "교육 2팀 · 곽운도",
                        answer: [ "<b>더 좋은 교육을 위해 시도해 볼 수 있는 기회가 항상 열려 있습니다.</b> 저는 평소에도 ‘완벽한 교육은 없다’라고 생각하고 있어요. 아무래도 사람을 성장시키는 일이다 보니 변수가 너무나 많거든요. 어차피 완벽한 것을 만들어 낼 수 없다는 의미로 들릴 수 있지만 한편으로는, 현재에 만족하지 않고 끊임없이 고민해야 한다는 동기부여가 되는 것 같습니다." ]
                    },
                    {
                        keyword: "교육 3팀 · 우도현",
                        answer: [ "<b>먼저 더 나은 교육을 위해 제가 기획한 것들을 실제로 해볼 수 있다는 것입니다.</b> 리드뿐만이 아니라 운영 매니저, 심지어 실습 조교 포지션에서도 자유롭게 의견을 제시하고 실제로 해볼 수 있도록 기회를 주고 있다는 것이 큰 성장 경험입니다.<br />",
                            
                        "그리고 저는 사업개발 캠프를 기획하고 운영하면서 스스로도 창업에 대한 식견이 넓어지고 있다는 것이 느껴집니다. <b>모든 수강생들이 각기 다른 분야의 아이디어들을 실현하고 싶어 하고, 저는 그 아이디어들에 대해서 피드백 주고 방향성을 잡아줘야 하기 때문에 다양한 분야에 대해 스스로 공부하게 됩니다.</b>" ]
                    },
                    {
                        keyword: "교육 4팀 · 김태형",
                        answer: [ "B2B와 B2G로만 10년을 넘게 일하다가 B2C 업무를 하게 보니 모든 것이 새로웠고, 처음 일하는 기분이 들었습니다. 덕분에 처음 일을 했던 그때의 마음으로 일을 하면서 새로운 열정이 생겼습니다. <b>고객과 밀착하여 즉각적인 결과가 나오는 업무들로 인해서 빠르게 수행하고, 이를 더욱 발전시키는 민첩함과 과감함이 많이 생겼습니다.</b>" ]
                    },
                    {
                        keyword: "교육 5팀 · 오민교",
                        answer: [ "<b>최근 팀의 리드로 발령을 받고 하나부터 열까지 모든 일들이 챌린지인 상황이 가끔 버겁게 느껴지기도 하지만, 이러한 고통 속에서 성장의 꽃이 피어난다고 생각이 들어 즐겁게 일하고 있습니다.</b> 기존에 하던 업무들이 새롭게 접목되면서 저만의 커리어가 쌓여 나가며 성장하는 경험이 새롭습니다. 운영 매니저에서 교육기획자와 러닝코치로, 또 리더로서 성장해 나아갈 경험이 기대됩니다." ]
                    },
                ]
            },
            {
                question: "그럼, 어떤 분들과<br /> 함께 일하고 싶으신가요?",
                answers: [ 
                    {
                        keyword: "교육 1팀 · 양성훈",
                        answer: [ "<b>자신에 대해 잘 알고 있는 분들을 선호합니다.</b> 능력, 의지, 한계와 같이 다양한 면에 대해 잘 알고 있는 분이라면 자신이 성장하기 위해 어떤 것들을 개선해야  할지 또는 어떤 일이 주어졌을 때 내가 할 수 있는 가장 효율적인 방법은 무엇인지에 대해 빠르게 판단할 수 있다고 생각합니다. 그리고 능력 밖에 업무나 대처하기 어려운 상황이 발생한 경우에도 자신의 한계를 잘 알기에 주변에 도움 구해 현명하게 해결할 수 있다고도 생각합니다." ]
                    },
                    {
                        keyword: "교육 2팀 · 곽운도",
                        answer: [ "<b>다른 사람을 성장시키는 일에 관심이 있는 사람입니다.</b> 그런 분들과 같이 일할 때 저도 성장한다는 느낌을 받게 되는 것 같아요. 제가 먼저 그런 사람이 되기 위해 노력하고 있습니다." ]
                    },
                    {
                        keyword: "교육 3팀 · 우도현",
                        answer: [ "<b>현재에 안주하지 않고 성장하고자 하는 열정이 있는 분들하고 일하고 싶습니다.</b> 저 역시도 현재 사업개발 캠프를 원활히 이끌어가고 있지만, 아직 개선하고 싶은 부분들이 많습니다. 더 퀄리티 있는 교육을 위해, 리드로서 더 팀을 잘 이끌어가기 위해 꾸준히 고민하고 있습니다. 이런 고민들을 함께 나누고 같이 개선해나갈 수 있는 열정과 끈기를 가진 사람이면 좋을 것 같습니다." ]
                    },
                    {
                        keyword: "교육 4팀 · 김태형",
                        answer: [ "<b>본인의 업무 능력을 제대로 파악</b>하는 사람, <b>겸손함과 자신감이 공존</b>하는 사람" ]
                    },
                    {
                        keyword: "교육 5팀 · 오민교",
                        answer: [ "<b>함께하면 편안한 사람</b>들과 일하고 싶습니다. 조금 더 욕심을 부려 본다면 <b>배울 점이 있는 분</b>들과 함께 일하고 싶습니다." ]
                    },
                ]
            },
            {
                question: "넥스트러너스는 어떤 회사인가요?<br class='pc_show tab_none' /> 복지, 문화, 동료 등 가장 좋아하는 것이 있다면<br class='pc_show tab_none' /> 소개해주세요.",
                answers: [ 
                    {
                        keyword: "교육 1팀 · 양성훈",
                        answer: [ "하나를 꼽자면 <b>열정적이고 진취적인 동료</b>를 꼽을 수 있습니다. 같이 일하는 동료들이 본인 업무의 스폐셜리스트가 되기 위해 노력하는 모습을 보고 있으면 저 또한 자극을 받곤 합니다. 그리고 놀랍게도 공통적인 특징이 있는데 하나같이 모두 숨겨둔 능력이 한두 개씩은 있다는 겁니다. 수준급의 영상편집 능력, 특정 분야의 도메인 지식과 같이 아주 유용한 능력을 갖고 있어 제가 업무를 진행하면서 다양한 도움을 받고 있습니다." ]
                    },
                    {
                        keyword: "교육 2팀 · 곽운도",
                        answer: [ "<b>끊임없이 노력하게 만드는 대표님의 마인드</b>라고 생각합니다. (의심하는 분들 계실 수도 있는데 사회생활이나 반어법 절대 아닙니다.) 목표를 이루기 위한 적당한 긴장감은 삶의 활력소가 되는 것 같아요. <b>노력에 대해 절대 간과하지 않는 문화</b>가 넥스트러너스가 가지고 있는 좋은 문화라고 생각합니다." ]
                    },
                    {
                        keyword: "교육 3팀 · 우도현",
                        answer: [ "<b>넥스트러너스는 성장을 위해 달리는 동료들이 많습니다.</b> 각자의 직무에 있어서 자부심을 가지고 더 나은 교육을 위해 끊임없이 고민하고 발전해가는 동료들의 모습을 보면 저도 열정이 생기게 되는 것 같습니다. 그리고 <b>점심/야근 식비 제공이나 자기 계발비 지원</b> 등 업무에 집중할 수 있도록 받쳐주는 복지도 좋습니다." ]
                    },
                    {
                        keyword: "교육 4팀 · 김태형",
                        answer: [ "<b>점심 식대, 초과근무 수당의 복지</b>는 동급 규모의 회사에서는 흔하지 않는 수준입니다. 7시간의 근무 또한 스스로 더 효율적으로 일할 수 있게 하는 원동력이 됩니다." ]
                    },
                    {
                        keyword: "교육 5팀 · 오민교",
                        answer: [ "<b>성장의 불꽃이 활활 타오르는 회사</b>입니다. 스타트업 특유의 복지와 동료가 좋습니다. 7시간 기본 근무 제도로 일에 몰두할 수 있는 여건을 만들어 주시는 것부터, 야간 근로수당을 따로 챙겨 주심으로서 열심히 일하는 직원들에 대한 보상도 확실히 챙겨 주십니다. 또한 함께 하면 <b>즐겁고 모나지 않은 동료</b>들이 있어서 즐겁게 일할 수 있습니다." ]
                    },
                ]
            },
            {
                question: "마지막으로, 앞으로 넥스트러너스에서<br class='pc_show tab_none' /> 이루고 싶은 것이 있다면 말씀해주세요!",
                answers: [ 
                    {
                        keyword: "교육 1팀 · 양성훈",
                        answer: [ "파이썬 기반의 백엔드 과정이 많지 않은 것으로 알고 있습니다. 백엔드 과정을 담당하고 있는 만큼 목표는 개발자가 되고 싶은 사람들이 가장 참여하고 싶은 백엔드 과정으로 만드는 것입니다. 이런 목표를 바탕으로 다양한 계획을 세우고 있습니다. <b>1년 안에 최고의 과정으로 인정받을 수 있도록 팀원들과 함께 최선</b>을 다하겠습니다." ]
                    },
                    {
                        keyword: "교육 2팀 · 곽운도",
                        answer: [ "<b>취업률 100%의 우주최강 프론트엔드 코스를 만들고 싶습니다.</b> 기업들이 필요로 하는 주니어 개발자의 모델을 만들고, 그 모델에 따라 프로젝트를 만들고, 프로젝트를 위한 학습 로드맵을 만들고 싶습니다. 각각의 학습 과정에는 학습 목표가 명확해서 학습 목표를 이루기 위한 콘텐츠와 과제가 치밀하게 설계되어 있는 거죠. 이후에는 프론트엔드를 넘어 IT 교육 시장 전반과 취업 교육 시장, 더 나아가 세상에 있는 모든 교육을 바꿔보고 싶네요." ]
                    },
                    {
                        keyword: "교육 3팀 · 우도현",
                        answer: [ "넥스트러너스의 <b>IT 창업 부트캠프들이 ‘IT 창업 교육 분야’에 있어서 대표적인 교육 부트캠프로 자리잡을 수 있도록 하는 것이 저의 목표</b>입니다. 이론적인 창업 교육이 아니라 실무 기반 창업 교육으로서 창업을 꿈꾸는 누구라도 저희 교육을 통해 창업 아이디어를 만들어내고 구체화하여 수익을 창출할 수 있도록 교육을 체계화하고자 합니다." ]
                    },
                    {
                        keyword: "교육 4팀 · 김태형",
                        answer: [ "1. 매월 지속적으로 달성하는 KPI",
                            "2. 더 넓은 범위의 교육 프로그램 런칭",
                            "3. B2B영역 확장" ]
                    },
                    {
                        keyword: "교육 5팀 · 오민교",
                        answer: [ "아직 얼마 되지 않아 더 고민 해보겠습니다. 당장은 <b>새롭게 론칭하는 웹 풀스택과정의 성공적인 론칭과 시스템적 안정화를 목표</b>로 하겠습니다." ]
                    },
                ]
            },

        ]
    },
    {
        id: 4,
        title: ['경영지원팀', '마케팅팀', 'CRM팀', '개발팀'],
        backgroundColor: '#100073',
        keywords: ['김태완 · 박세경 · 이승현 · 백관열'],
        description: [
            {
                question: "한 문장으로<br /> 자기소개 부탁드려요.",
                answers: [ 
                    {
                        keyword: "경영지원팀 · 김태완",
                        answer: [ "안녕하세요! 넥스트러너스의 살림꾼, 경영지원팀 막내 김태완 입니다 :D" ]
                    }, 
                    {
                        keyword: "마케팅팀 · 박세경",
                        answer: [ "안녕하세요! 넥스트러너스 마케팅팀 리드 박세경입니다!" ]
                    }, 
                    {
                        keyword: "CRM팀 · 이승현",
                        answer: [ "안녕하세요. 넥스트러너스 CRM팀 리드 이승현입니다." ]
                    }, 
                    {
                        keyword: "개발팀 · 백관열",
                        answer: [ "안녕하세요! 개발팀 리드 백관열 입니다!" ]
                    }, 
                ]
            },
            {
                question: "현재 하고 계신 일에 대해<br class='pc_show tab_none' /> 조금 더 자세히 설명 부탁드려요.",
                answers: [ 
                    {
                        keyword: "경영지원팀 · 김태완",
                        answer: [ "<ol><li><b>매월 자금의 흐름을 파악</b>하는 월마감 작업</li><li>강의와 부트캠프 등 강의 <b>매출 및 지급을 위한 강사정산</b></li><li>NXR에서 열심히 달릴 수 있는 <b>인재 영입을 위한 채용과 계약, 급여 등<br class='pc_show tab_none' /> 인사업무</b></li><li>업무 효율을 올릴 수 있도록 <b>각종 비품 및 시설 등 환경적 요인을 관리하는<br class='pc_show tab_none' /> 총무 업무 ㆍ올바른 조직문화 설립을 위한 규율화 작업</b><br /></li>",
                            
                            "위 업무 외 여러 업무를 담당하고 있습니다 :D" ]
                    }, 
                    {
                        keyword: "마케팅팀 · 박세경",
                        answer: [ "넥스트러너스의 <b>교육 서비스 마케팅을 전반적으로 담당</b>하고 있습니다. <b>KPI 달성을 위해 디지털 광고 매체 운영 및 분석, 성과 관리 데이터 기반으로 주요 광고 지표 리포팅, 미디어 믹스 및 예산을 수립</b>하고 있습니다.",
                        "그리고 마케팅팀 팀원들과 KPI 달성을 위해 다양한 시도와 검증을 할 수 있게 <b>팀 매니징 및 마케팅 전략수립, 매출 관리</b>를 하고 있습니다." ]
                    }, 
                    {
                        keyword: "CRM팀 · 이승현",
                        answer: [ "넥스트러너스에서 진행되는 <b>교육 서비스의 모집과 퍼널 효율화를 업무</b>를 진행하고 있습니다. 이를 위해 <b>퍼널의 문제점을 개선하여 고객 여정을 최적화</b>하고, 고객들에게 넥스트러너스의 다양한 교육 콘텐츠를 보다 쉽게 소개하여 그들이 새로운 학습 경험을 할 수 있도록 돕고 있습니다." ]
                    }, 
                    {
                        keyword: "개발팀 · 백관열",
                        answer: [ "회사에서 필요한 <b>프로그램을 설계하고 코딩</b>하고 이후에 문제가 없는지 <b>유지 보수</b>하는 업무를 맡고 있습니다!" ]
                    }, 
                ]
            },
            {
                question: "정말 다양한 일들을 하고 계시네요!<br class='pc_show tab_none' /> 본인이 속한 팀에 대한 소개를 부탁드려요.",
                answers: [ 
                    {
                        keyword: "경영지원팀 · 김태완",
                        answer: [ "<b>경영지원팀은 넥스트러너스의 조직문화 설립과 탄탄한 기초를 다지기 위해 고군분투</b>하고 있습니다. 회사 내부에서 직원들의 업무 효율을 위해 살피고 지원하며, 회사가 목표를 향해 방해 없이 달려나갈 수 있도록 하는 조직입니다 :D" ]
                    }, 
                    {
                        keyword: "마케팅팀 · 박세경",
                        answer: [ "넥스트러너스 <b>마케팅팀은 KPI 달성을 위해 책임감 있고 능동적으로 업무를 진행하는 팀</b>입니다. 퍼포먼스 및 콘텐츠 마케팅, 브랜딩, 데이터분석, 언론홍보 등 넥스트러너스를 위한 전반적인 마케팅을 담당하고 있습니다. 주요 광고 채널 및 다양한 매체를 효율적으로 운영하며, 마케팅 지표를 중시하고 소비자 데이터 기반으로 인사이트를 도출합니다. CRM팀, 교육팀 등 유관 부서와 동일한 목표를 달성하기 위해 서로 협업하며 시너지를 창출하는 조직입니다 :)" ]
                    }, 
                    {
                        keyword: "CRM팀 · 이승현",
                        answer: [ "<b>CRM팀은 고객들이 자사의 컨텐츠에 효율적으로 참여할 수 있도록 다양한 전략과 활동을 고민하고 실행</b>하는 팀입니다. 고객 만족도와 참여율을 높여, 회사의 성장을 지원하기 위해 노력하고 있습니다." ]
                    }, 
                    {
                        keyword: "개발팀 · 백관열",
                        answer: [ "<b>개발팀은 넥스트러너스에서 개발이 필요한 모든 업무를 해결하고 또 이를 위해서 스스로 성장하는 팀</b>입니다! 저희가 만든 프로그램이 사용자들이 경험하고, 또 그에 대한 피드백을 들을 수 있는 이러한 환경을 만드는데에 최선을 다하고 있습니다!" ]
                    }, 
                ]
            },
            {
                question: "넥스트러너스에서 일하며<br class='pc_show tab_none' /> 얻을 수 있는 성장 경험은 무엇인가요?",
                answers: [ 
                    {
                        keyword: "경영지원팀 · 김태완",
                        answer: [ "<b>다양한 직무를 경험하며 발전 할 수 있는 점, 그리고 회사에 도움이 된다면 누구나 의견을 제시할 수 있고 수용할 수 있는 환경</b>이라는 점이 강점 입니다. 회사 전반에 거쳐 경영지원팀에서 지원하는 업무들이 체계화 되어가는 것을 보며, 맡은 업무만 수행하는게 아니라 회사 전체를 바라보고 미래를 생각하는 스스로를 보게 되었습니다. 소속감을 느끼며 빠르게 발전해 나갈 수 있는 환경적 요인이 성장에 큰 영향을 주고 있습니다." ]
                    }, 
                    {
                        keyword: "마케팅팀 · 박세경",
                        answer: [ "넥스트러너스의 <b>마케터로 일하며 얻을 수 있는 가장 큰 성장 포인트는 다양한 경험</b>을 할 수 있다는 점입니다. 수평적인 분위기 속에서 아이디어를 내는데 눈치를 볼 필요 없고, 자유롭게 의견을 제시하며 프로젝트를 직접 리딩하고 실행해 볼 수도 있습니다. 마케터로써 퍼널 설계부터 실행까지 다양한 경험을 해 볼 수 있는 점이 시야를 넓히고 성장하게 되는 큰 기여를 하고 있습니다." ]
                    }, 
                    {
                        keyword: "CRM팀 · 이승현",
                        answer: [ "전형적인 답변일 수 있으나, <b>성장하는 스타트업에서 다양한 업무를 해볼 수 있다는 것이 장점</b>인 것 같습니다. 새로운 시도를 수용하고 함께 고민해주는 회사와 팀원들 덕분에 스스로를 돌이켜보고 책임이라는 단어의 중요성을 다시 한번 생각해보고 있는 것 같습니다." ]
                    }, 
                    {
                        keyword: "개발팀 · 백관열",
                        answer: [ "넥스트러너스의 <b>개발팀 리드로 일하면서 리더십을 강화하고, 프로젝트 관리 능력을 향상시키며, 커뮤니케이션 스킬을 발전</b>시킬 수 있었습니다. 특히 문제 해결 능력과 전략적 사고를 통해 팀의 성과를 극대화하는 데 주력했으며, 이는 각 개인의 커리어 성장에도 큰 힘이 되었다고 생각합니다." ]
                    }, 
                ]
            },
            {
                question: "그럼, 어떤 분들과 함께<br class='pc_show tab_none' /> 일하고 싶으신가요?",
                answers: [ 
                    {
                        keyword: "경영지원팀 · 김태완",
                        answer: [
                            "<ol><li>타인에 대한 존중과 배려심이 있는 러너</li><li>본인 업무에 책임감을 가지고 적극적으로 임하는 러너</li><li>함께 함으로써 성장할 수 있는 러너</li></ol>",
                            "위 러너들과 함께 달려나가고 싶습니다 :D"
                        ]
                    },
                    {
                        keyword: "마케팅팀 · 박세경",
                        answer: [ '<b>새로운 시도와 도전을 즐기고, 자기 주도적이신 러너</b>분들이라면 두 팔 벌려 환영합니다! 서비스에 애정을 가지고 “어떻게 하면 더 잘 알릴지”, “더 효율적이며 나은 전략이 있을지” 항상 고민하는 마케터이시면 좋을 것 같습니다.' ]
                    }, 
                    {
                        keyword: "CRM팀 · 이승현",
                        answer: [ "<b>유연한 사고를 가진 러너분들과 일하는 것을 선호</b>합니다. 모든 회사가 그렇겠지만, 특히나 넥스트러너스는 지속적으로 성장하는 회사이기 때문에 업무를 진행하다 보면 겪어보지 못했던 어려움을 겪게 될 수도 있다고 생각합니다. 이러한 상황에서도 유연한 사고를 통해 함께 문제를 해결해나가고 싶습니다." ]
                    }, 
                    {
                        keyword: "개발팀 · 백관열",
                        answer: [
                            "<ol class='custom-list'><li>혼자 하는 것보다 여럿이 모여 같이 서로의 개발 문화를 공유하며 협업하는 게 중요하다고 생각하는 커뮤니케이션이 잘되는 러너</li><li>회사의 비전과 자신을 일치시켜 함께 나아갈 수 있는 러너</li></ol>"
                        ]
                    },
                ]
            },
            {
                question: "넥스트러너스는 어떤 회사인가요?<br class='pc_show tab_none' /> 복지, 문화, 동료 등 가장 좋아하는 것이 있다면<br class='pc_show tab_none' /> 소개해주세요.",
                answers: [ 
                    {
                        keyword: "경영지원팀 · 김태완",
                        answer: [ "넥스트러너스는 여느 기업과 비교하더라도, <b>몰입형 근무를 바탕으로 한 일 7시간 근무ㆍ각종 수당 지급 등 복지 수준이 굉장히 높은 기업</b>입니다. 경영지원팀으로서 여러 복지를 제공하는것은 곧 업무의 증대로 이어지는 경우가 많지만.. 이 정도 수준의 복지를 직원들에게 제공할 수 있어 무척이나 행복합니다 :D" ]
                    }, 
                    {
                        keyword: "마케팅팀 · 박세경",
                        answer: [ "넥스트러너스는 <b>도전과 성장의 길이 열려있는 기업</b>입니다. 업무를 더욱 효율적으로 진행할 수 있도록 근무 환경, 복지, 문화 등을 최대한 제공해주고 있습니다. 회사 및 개인의 성장을 위해 <b>매월 자기계발비 지원</b>하는 점도 정말 좋습니다!" ]
                    }, 
                    {
                        keyword: "CRM팀 · 이승현",
                        answer: [ "<b>제공된 복지를 차별 없이, 모두가 자유롭게 이용할 수 있는 점</b>이 정말 큰 메리트인 것 같습니다. 직장인이라면 분명 공감하실 것이라고 생각합니다. 채용공고와는 다른 복지, 은연중에 깔려 있는 회사의 분위기로 인해 눈치가 보이거나 복지를 제대로 활용하지 못하는 회사도 경험해 보았는데, 넥스트러너스는 높은 복지 수준을 직원들에게 오히려 권장한다는 점이 좋았습니다." ]
                    }, 
                    {
                        keyword: "개발팀 · 백관열",
                        answer: [ "회사의 가장 <b>큰 장점은 함께 일하는 동료</b>들 입니다! 서로가 서로에게 협력적이고 서로를 지원하며 성장하고 있습니다. 이런 동료들과 같이 일하며 긍정적이고 즐거운 근무환경을 함께 만들어 나갈수 있습니다!" ]
                    }, 
                ]
            },
            {
                question: "마지막으로, 앞으로 넥스트러너스에서<br class='pc_show tab_none' /> 이루고 싶은 것이 있다면 말씀해주세요!",
                answers: [ 
                    {
                        keyword: "경영지원팀 · 김태완",
                        answer: [ "성장을 위한 러너들이 모여 함께 달려왔고, 모두의 노력이 시너지 효과를 내어 회사 전체적인 성장을 이루어 왔습니다. 앞으로 우리의 성장이 지속 가능할 수 있도록 굳건하고 체계적인 경영지원팀을 만들어가고 싶습니다! :D" ]
                    }, 
                    {
                        keyword: "마케팅팀 · 박세경",
                        answer: [ "넥스트러너스의 좋은 교육 서비스를 많은 분들에게 더 알릴 수 있도록 다양한 전략을 수립하고 싶습니다. 팀원들 또한 같은 방향을 바라보며, 자유롭게 아이데이션하고 실행 및 성과를 내는 마케팅팀을 만들어가고 싶습니다!" ]
                    }, 
                    {
                        keyword: "CRM팀 · 이승현",
                        answer: [ "만족할 만한 고객 여정을 확립하여 고객이 넥스트러너스의 교육 서비스를 즐겁게 경험하실 수 있도록 하고 싶습니다. 개인적으로는 현재 맡은 업무를 보다 체계화하고 전문적인 지식을 쌓아 시간의 밀도를 높이고 싶습니다." ]
                    }, 
                    {
                        keyword: "개발팀 · 백관열",
                        answer: [ "고객들이 우리 서비스를 이용할때 만족도 높은 서비스를 만들고 이걸 내가 만들었다 자랑스럽게 말할수있는 그런 서비스를 넥스트러너스와 함께 만들고 싶습니다!" ]
                    }, 
                ]
            },
        ]
    }
];

export const getGalleryData = () => galleryData;


const GalleryItem = ({ id, date, title, keywords }) => {
    return (
        <li className="gallery-item-wrap">
            <Link to={`/interviews/${id}`} className="gallery-item">
                <div className="gallery-box-wrap">
                    <div className="gallery-content-item">
                        <div className="content-item_list">
                            <p className="item_list-tid">Interview</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <g clipPath="url(#clip0_52_702)">
                                    <path d="M14.767 9.30395C14.767 8.95305 14.4825 8.66858 14.1316 8.66858C13.7807 8.66858 13.4962 8.95305 13.4962 9.30395C13.4959 11.7872 11.4825 13.7999 8.99925 13.7996C6.51656 13.7992 4.50402 11.7866 4.50365 9.30395C4.50365 8.95305 4.21918 8.66858 3.86828 8.66858C3.51738 8.66858 3.23291 8.95305 3.23291 9.30395C3.23626 12.2417 5.44508 14.7084 8.36459 15.035V16.7293H6.05397C5.70307 16.7293 5.4186 17.0137 5.4186 17.3646C5.4186 17.7155 5.70307 18 6.05397 18H11.9459C12.2968 18 12.5813 17.7155 12.5813 17.3646C12.5813 17.0137 12.2968 16.7293 11.9459 16.7293H9.63532V15.035C12.5548 14.7084 14.7636 12.2417 14.767 9.30395Z" fill="white" fillOpacity="0.5"/>
                                    <path d="M9.00004 0C7.04318 0 5.45679 1.58635 5.45679 3.54326V9.28064C5.4591 11.2366 7.04413 12.8215 9.00004 12.8239C10.956 12.8216 12.5409 11.2366 12.5433 9.28064V3.54326C12.5433 1.58635 10.9569 0 9.00004 0Z" fill="white" fillOpacity="0.5"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_52_702">
                                        <rect width="18" height="18" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                            <div className="stroke-line"></div>
                        </div>
                        <ul className="content-item_tit">
                            {title.map((tit, index) => (
                                <li key={index} className="item_tit">{tit}</li>
                            ))}
                        </ul>
                    </div>
                    <div className="more-btn-icon">
                        <p className="icon_text">인터뷰 보러가기</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                            <circle cx="20" cy="20" r="19" stroke="white" strokeWidth="2"/>
                            <path d="M16.949 13.5593L23.7286 20.339L16.949 27.1186" stroke="white" strokeWidth="2.94705"/>
                        </svg>    
                    </div>
                </div>
            </Link>
            <p className="gallery-keyword-wrap">{keywords}</p>
        </li>
    );
};

const InterviewBoard = () => {
    return (
        <InterviewBoardWrap className="board-gallery">
            <ul className="gallery-list">
                {galleryData.map((item, index) => (
                    <GalleryItem key={index} {...item} />
                ))}
            </ul>
        </InterviewBoardWrap>
    );
};

export default InterviewBoard;