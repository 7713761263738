import styled from "@emotion/styled";
// import visionBgImg from "../../assets/images/vision-bg.png";

export const BrandSwiperWrap = styled.div`

    position: relative;

    .swiper-list{ width: 1400px; display: flex; flex-direction: row; gap: 40px; }
    .swiper-list .swiper-item{ position: relative; width: 320px; height: 180px; display: flex; justify-content: center; align-items: center; border: 6px; background-color: #13151D; border-radius: 6px; transition: all 0.3s; cursor: pointer; }
    .swiper-list .swiper-item img{ width: auto; height: 100px; }
    .swiper-list .swiper-item .default-img{ position: absolute; opacity: 1; transition: all 0.3s ease-in-out; }
    .swiper-list .swiper-item .hover-img{ position: absolute; opacity: 0; transition: all 0.3s ease-in-out; }
    .swiper-list .swiper-item:hover{ background-color: #E5EAF4; }
    .swiper-list .swiper-item:hover .default-img{ opacity: 0; }
    .swiper-list .swiper-item:hover .hover-img{ opacity: 1; }
    .swiper-list .swiper-item.active{ background-color: #E5EAF4; }
    .swiper-list .swiper-item.active .default-img{ position: absolute; opacity: 1; transition: all 0.3s ease-in-out; }
    .swiper-list .swiper-item.active .hover-img{ position: absolute; opacity: 0; transition: all 0.3s ease-in-out; }
    .swiper-list .swiper-item.active .default-img{ opacity: 0; }
    .swiper-list .swiper-item.active .hover-img{ opacity: 1; }

    button{ position: absolute; top: calc(50% - 25px); width 50px; height: 50px; display: flex; justify-content: center; align-items: center; border-radius: 8px; background-color: rgba(62, 63, 82, 0.50); outline: none; border: none; padding: 9px; margin: 0; z-index: 1; cursor: pointer; }
    .prev-btn{ left: -25px; }
    .next-btn{ right: -25px; }
    
    @media screen and (max-width: 1400px) {
        width: 100%; max-width: 920px;
        .swiper-wrapper{ width: 100%; }
        .swiper-list{ width: 920px; }
        .swiper-list .swiper-item{ width: 280px !important; height: 157.5px; }
    }

    @media screen and (max-width: 992px) {
        width: 320px; height: 135px; position: relative;

        .swiper-list{ width: 100%; }
        .swiper-list .swiper-item{ position: relative; width: 240px !important; height: 135px !important; margin: 10px; }
        .swiper-list .swiper-item img{ width: auto; height: 100px; }
        .swiper-list .swiper-item .default-img{ position: absolute; opacity: 1; transition: all 0.3s ease-in-out; }
        .swiper-list .swiper-item .hover-img{ position: absolute; opacity: 0; transition: all 0.3s ease-in-out; }
        .swiper-list .swiper-item:hover{ background-color: #E5EAF4; }
        .swiper-list .swiper-item:hover .default-img{ opacity: 0; }
        .swiper-list .swiper-item:hover .hover-img{ opacity: 1; }
        .swiper-list .swiper-item.active{ background-color: #E5EAF4; }
        .swiper-list .swiper-item.active .default-img{ position: absolute; opacity: 1; transition: all 0.3s ease-in-out; }
        .swiper-list .swiper-item.active .hover-img{ position: absolute; opacity: 0; transition: all 0.3s ease-in-out; }
        .swiper-list .swiper-item.active .default-img{ opacity: 0; }
        .swiper-list .swiper-item.active .hover-img{ opacity: 1; }

        button{ position: absolute; top: calc(50% - 10px); width 40px; height: 40px; }
        .prev-btn{ left: -20px; }
        .next-btn{ right: -20px; }
        button svg{ width 24px; height: 24px; }

    }

`;