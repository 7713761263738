import styled from "@emotion/styled";

export const BiWrap = styled.section`
    width: 100%; display: flex; flex-direction: column; align-items: center; gap: 60px; padding: 100px 10% 160px;

    .title-wrap{ width: 100%; max-width: 1400px; display: flex; flex-direction: row; gap: 30px; justify-content: space-between; align-items: center; }
    .title-wrap .title-txt{  }
    .title-wrap .title-txt svg{  }
    .title-wrap .title-txt svg path{  }
    .title-wrap .title-box{ width: 100%; height: 4px; background-color: #000; }

    .content-wrap{ width: 100%; max-width: 1400px; display: flex; flex-direction: column; gap: 60px; justify-content: center; align-items: center; }

    @media screen and (max-width: 1400px) {
        padding: 100px 0 120px;
        .title-wrap{ width: 920px; }
        .content-wrap{ width: 920px; }
        .content-wrap .keycolor-wrap .desc-wrap{ gap: 24px; }
        .content-wrap .keycolor-wrap .desc-wrap .color-wrap{ gap: 16px; }
        .content-wrap .keycolor-wrap .desc-wrap .color-wrap .color-box{ width: 250px; height: 20px; }
        .content-wrap .keycolor-wrap .desc-wrap .color-wrap .color-name{ font-size: 14px; }
        .content-wrap .desc-wrap .desc-list{ gap: 8px; font-size: 14px; }
    }

    
    @media screen and (max-width:992px) {
        
        width: 100%; padding: 60px 0 50px;
    
        .title-wrap{ width: 320px; gap: 30px; justify-content: space-between; align-items: center; }

        .content-wrap{ max-width: 320px; gap: 40px;  }
        .content-wrap .brand-img-wrap{ gap: 20px; }
        .content-wrap .desc-title{ font-size: 18px; }
        .content-wrap .desc-wrap .desc-list{ gap: 6px; font-size: 12px; }
        .content-wrap .desc-wrap .desc-list .desc-item{  }
        .content-wrap .compName-wrap .desc-wrap .desc-list{ padding-left: 0; }
        
        .content-wrap .keycolor-wrap .desc-wrap{ flex-direction: column; gap: 20px; }
        .content-wrap .keycolor-wrap .desc-wrap .color-wrap{ gap: 12px; }
        .content-wrap .keycolor-wrap .desc-wrap .color-wrap .color-box{ width: 320px; height: 10px; }
        .content-wrap .keycolor-wrap .desc-wrap .color-wrap .color-name{ font-size: 12px; padding-left: 0; }
        .content-wrap .keycolor-wrap .desc-wrap .color-wrap .color-code{ padding-left: 0; }

    }
`;