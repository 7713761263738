import styled from "@emotion/styled";

export const CiWrap = styled.section`
    width: 100%; display: flex; flex-direction: column; align-items: center; gap: 60px; padding: 160px 10% 100px;

    .title-wrap{ width: 100%; max-width: 1400px; display: flex; flex-direction: row; gap: 30px; justify-content: space-between; align-items: center; }
    .title-wrap .title-txt{  }
    .title-wrap .title-txt svg{  }
    .title-wrap .title-txt svg path{  }
    .title-wrap .title-box{ width: 100%; height: 4px; background-color: #000; }

    .content-wrap{ width: 100%; max-width: 1400px; display: flex; flex-direction: column; gap: 60px; justify-content: center; align-items: flex-start; }
    .content-wrap .brand-img-wrap{ display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 40px; }
    .content-wrap .brand-img-wrap .brand-img-item{ width: 100%; height: auto; }
    .content-wrap .compName-wrap{ display: flex; flex-direction: column; align-items: flex-start; gap: 20px; }
    .content-wrap .desc-title{ color: #000; font-family: 'Pretendard'; font-size: 32px; font-weight: 900; }
    .content-wrap .desc-title .desc-title-item{  }
    .content-wrap .desc-wrap{ display: flex; flex-direction: row; gap: 40px; }
    .content-wrap .desc-wrap .desc-list{ display: flex; flex-direction: column; gap: 10px; justify-content: center; align-items: flex-start; color: #777; font-family: 'Pretendard'; font-size: 16px; font-weight: 700; }
    .content-wrap .desc-wrap .desc-list .desc-item{  }
    .content-wrap .compName-wrap .desc-wrap .desc-list{ padding-left: 20px; }

    .content-wrap .keycolor-wrap{ display: flex; flex-direction: column; align-items: flex-start; gap: 20px; }
    .content-wrap .keycolor-wrap .desc-wrap .color-wrap{ display: flex; flex-direction: column; align-items: flex-start; gap: 20px; }
    .content-wrap .keycolor-wrap .desc-wrap .color-wrap .color-box{ width: 400px; height: 20px; }
    .content-wrap .keycolor-wrap .desc-wrap .color-wrap .color-box.bk{ background-color: #000; border: 1px solid #000; }
    .content-wrap .keycolor-wrap .desc-wrap .color-wrap .color-box.wt{ background-color: #fff; border: 1px solid #000; }
    .content-wrap .keycolor-wrap .desc-wrap .color-wrap .color-box.rd{ background-color: #fb0000; border: 1px solid #fb0000; }
    .content-wrap .keycolor-wrap .desc-wrap .color-wrap .color-name{ color: #777; font-family: 'Pretendard'; font-size: 16px; font-weight: 700;padding-left: 6px; }
    .content-wrap .keycolor-wrap .desc-wrap .color-wrap .color-name .color-name-item{  }
    .content-wrap .keycolor-wrap .desc-wrap .color-wrap .color-code{ padding-left: 6px; }
    
    @media screen and (max-width: 1400px) {
        padding: 120px 0 100px;
        .title-wrap{ width: 920px; }
        .content-wrap{ width: 920px; }
        .content-wrap .keycolor-wrap .desc-wrap{ gap: 24px; }
        .content-wrap .keycolor-wrap .desc-wrap .color-wrap{ gap: 16px; }
        .content-wrap .keycolor-wrap .desc-wrap .color-wrap .color-box{ width: 250px; height: 20px; }
        .content-wrap .keycolor-wrap .desc-wrap .color-wrap .color-name{ font-size: 14px; }
        .content-wrap .desc-wrap .desc-list{ gap: 8px; font-size: 14px; }
    }
    
    @media screen and (max-width: 992px) {
        
        width: 100%; display: flex; flex-direction: column; align-items: center; gap: 60px; padding: 100px 0 60px;
    
        .title-wrap{ width: 100%; max-width: 320px; display: flex; flex-direction: row; gap: 30px; justify-content: space-between; align-items: center; }
    
        .content-wrap{ max-width: 320px; gap: 40px;  }
        .content-wrap .brand-img-wrap{ gap: 20px; }
        .content-wrap .desc-title{ font-size: 18px; }
        .content-wrap .desc-wrap .desc-list{ gap: 6px; font-size: 12px; }
        .content-wrap .desc-wrap .desc-list .desc-item{  }
        .content-wrap .compName-wrap .desc-wrap .desc-list{ padding-left: 0; }
        
        .content-wrap .keycolor-wrap .desc-wrap{ flex-direction: column; gap: 20px; }
        .content-wrap .keycolor-wrap .desc-wrap .color-wrap{ gap: 12px; }
        .content-wrap .keycolor-wrap .desc-wrap .color-wrap .color-box{ width: 320px; height: 10px; }
        .content-wrap .keycolor-wrap .desc-wrap .color-wrap .color-name{ font-size: 12px; padding-left: 0; }
        .content-wrap .keycolor-wrap .desc-wrap .color-wrap .color-code{ padding-left: 0; }
    }
`;