import styled from "@emotion/styled";

export const PaginationWrap = styled.div`

    display: flex; flex-direction: row; gap: 10px;

    button{ width: 24px !important; height: 18px; display: flex; flex-direction: column; justify-content: flex-start; align-items: center; padding: 0 8px; margin: 0; font-family: 'Pretendard'; font-size: 14px; font-weight: 700; background-color: transparent; border: none; outline: none; color: #aaa; }
    button.active{ color: #000; }
    button.active::after{ display: block; width: 140%; height: 2px; background-color: #000; content: ''; color: #000; }
    
`;