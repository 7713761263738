import { CultureWrap } from "../styles/common/culture.styled";
import cultureIcon01 from "../assets/images/culture-icon-01.png";
import cultureIcon02 from "../assets/images/culture-icon-02.png";
import cultureIcon03 from "../assets/images/culture-icon-03.png";
import { useState } from "react";



const CultureItem = ({ icon, title, details, isLast }) => {
    const [isOpen, setIsOpen] = useState(false);

    

    return (
        <li className={`culture-item ${isLast ? 'full' : ''}`}>
            <div className="culture-detail" onClick={() => setIsOpen(!isOpen)}>
                <div className="culture-info">
                    <div className="culture-icon">
                        <img src={icon} className="culture-icon-item" alt="" />
                    </div>
                    <div className="culture-name">
                        <p className="culture-name-txt">{title}</p>
                    </div>
                </div>
                <div className="culture-arrow">
                    <svg className="pc_only" xmlns="http://www.w3.org/2000/svg" style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s ease' }} width="24" height="15" viewBox="0 0 24 15" fill="none">
                        <path d="M2 2L12 12L22 2" stroke="white" strokeWidth="4" strokeLinecap="round"/>
                    </svg>
                    <svg className="pc_none" style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s ease' }} width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1L9 9L17 1" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                    </svg>
                </div>
            </div>
            {isOpen && (
                <div className='culture-grown'>
                    <ul className="culture-grown-list">
                        {details.map((item, index) => (
                            <li key={index} className="culture-grown-item">{item}</li>
                        ))}
                    </ul>
                </div>
            )}
        </li>
    );
}

const Culture = () => {
    const cultureData = [
        {
            icon: cultureIcon01,
            title: "근무 규칙",
            details: [
                "주 5일, 하루 7시간으로 효율적이고 밀도 높게 근무",
                "9:00 ~ 10:30 자율적으로 출근 (시차출근제)",
                "모든 구성원에게 오늘 내가 해야 할 업무를 슬랙에 공유",
                "12:30 ~ 13:30 고정된 점심 시간 (업무 상의 이유 제외)",
                "점심 식대는 회사 지원! 배달 또는 근처 식당에서 자유롭게 식사 가능"
            ]
        },
        {
            icon: cultureIcon02,
            title: "마감 기간",
            details: [
                "‘기간을 스스로 설정하는 것'은 ‘프로 의식’을 가지고 일을 하는 시작점",
                "일에 대한 ‘책임감'을 측정할 수 있는 가장 정확한 지표",
                "WBS 태스크 등록 시 마감기간 등록 필수",
                "하고 있는 업무에 듀데이트가 없는지 지속적으로 체크",
                "마감을 지키지 못했을 경우, 사유와 변경 일정을 모두에게 슬랙으로 공유"
            ]
        },
        {
            icon: cultureIcon03,
            title: "신뢰의 스노우볼 법칙",
            details: [
                "함께 일하는 구성원에게 신뢰를 쌓아가기 위해 노력해야 함",
                "모든 구성원 간에 신뢰가 확실하고 끈끈하다면 조직의 효율과 성과는 자연스럽게 상승",
                "신뢰는 쌓으면 쌓을수록 스노우볼이 되어 향후 좋은 기회로 이어지게 됩니다."
            ],
            isFull: true
        }
    ];

    return (
        <CultureWrap>
            <div className="sub-title">
                <div className="sub-title-item">CULTURE</div>
                <div className="sub-title-txt">넥스트러너스의 사내문화</div>
            </div>
            <div className="culture-content">
                <ul className="culture-list">
                    {cultureData.map((item, index) => (
                        <CultureItem key={index} icon={item.icon} title={item.title} details={item.details} isLast={index === cultureData.length - 1} />
                    ))}
                </ul>
            </div>
        </CultureWrap>
    )
}

export default Culture;
