import styled from "@emotion/styled";
// import visionBgImg from "../../assets/images/vision-bg.png";

export const RecruitBenefitWrap = styled.div`
    width: 1120px ; display: flex; flex-direction: row; justify-content: space-between; align-items: flex-start;
    
    .benefit-wrap{ width: 540px; padding-top: 40px; }

    .benefit-item{ width: 100% !important; }

    @media screen and (max-width: 1400px) and (min-width: 992px){
        width: 900px;
    }
    
    @media screen and (max-width: 992px) {
        
        width: 320px; flex-direction: column; align-items: center; gap: 40px;
        
        .benefit-wrap{ width: 100%; padding-top: 0; }
        .benefit-wrap .css-17fknuw{  gap: 20px !important; }

    }
`;