import Brand from "../components/brand";
import ServiceTitle from "../components/serviceTitle";






const ServicePage = () => {

    

    return(

        <>
            <ServiceTitle />
            <Brand />
            {/* <CultureKeyword /> */}
        </>
    )
}

export default ServicePage;
