import './App.css';
import GlobalStyle from './styles/globalStyle';
import './styles/globals.css';
import Header from "./components/header"
import Main from './pages/main';
import Footer from "./components/footer"
import './styles/font.css';


function App() {
  return (
    <>
    <GlobalStyle />
    <div className="App">
      <Header />
      <Main />
      <Footer />
    </div>
    </>
  );
}

export default App;