import styled from "@emotion/styled";

export const CultureWrap = styled.div`
    display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 60px;

    .culture-content{ width: 100%; max-width: 1120px; }
    .culture-content .culture-list{ display: flex; width: 100%; align-items: flex-start; align-content: flex-start; gap: 40px; flex-wrap: wrap; }
    .culture-content .culture-list .culture-item{ width: calc(50% - 20px); display: flex; flex-direction: column; justify-content: center; align-items: center; border-radius: 10px; background-color: #666; box-shadow: 0px 4px 8px 1px rgba(63, 0, 0, 0.16); overflow: hidden; }
    .culture-content .culture-list .culture-item.full{ width: 100%; max-width: none; }
    .culture-content .culture-list .culture-item .culture-detail{ width: 100%; display: flex; flex-direction: row; padding: 20px 40px; justify-content: space-between; align-items: center; background-color: #06070B; box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.2); }
    .culture-content .culture-list .culture-item .culture-detail .culture-info{ display: flex; flex-direction: row; gap: 20px; align-items: center; }
    .culture-content .culture-list .culture-item .culture-detail .culture-info .culture-icon{ width: 80px; height: 80px; display: flex; justify-content: center; align-items: center; }
    .culture-content .culture-list .culture-item .culture-detail .culture-info .culture-icon .culture-icon-item{ width: 80px; height: auto; }
    .culture-content .culture-list .culture-item .culture-detail .culture-info .culture-name{ color: #fff; font-family: 'Pretendard'; font-size: 20px; font-weight: 700; }
    .culture-content .culture-list .culture-item .culture-detail .culture-info .culture-name .culture-name-txt{  }
    .culture-content .culture-list .culture-item .culture-detail .culture-arrow{  }
    .culture-content .culture-list .culture-item .culture-detail .culture-arrow svg{  }
    .culture-content .culture-list .culture-item .culture-detail .culture-arrow svg path{  }

    .culture-content .culture-list .culture-item .culture-grown{ width: 100%; }
    .culture-content .culture-list .culture-item .culture-grown .culture-grown-list{ display: flex; padding: 40px; flex-direction: column; justify-content: center; align-items: flex-start; gap: 10px; color: #ccc; font-family: 'Pretendard'; font-size: 16px; font-weight: 500; line-height: 140%; text-align: left; }
    .culture-content .culture-list .culture-item .culture-grown .culture-grown-list .culture-grown-item{ display: flex; flex-direction: row; gap: 5px; }
    .culture-content .culture-list .culture-item .culture-grown .culture-grown-list .culture-grown-item::before{ content: '· '; }

    .sub-title{ display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 10px; color: #000; text-align: center; font-family: 'Pretendard'; font-weight: 800; }
    .sub-title .sub-title-item{ font-size: 20px; }
    .sub-title .sub-title-txt{ font-size: 32px; }

    @media screen and (max-width: 1400px) {
        padding: 0 40px;
        .culture-content{ width: 100%; max-width: 1060px; }
    }
    
    @media screen and (max-width: 992px) {
        
        gap: 40px;
    
        .culture-content{ width: 100%; max-width: 1120px; }
        .culture-content .culture-list{ justify-content: center; }
        .culture-content .culture-list .culture-item{ max-width: 300px; width: 100%; }
        .culture-content .culture-list .culture-item.full{ width: 100%; max-width: 300px; }
        .culture-content .culture-list .culture-item .culture-detail{ padding: 20px 20px; }
        .culture-content .culture-list .culture-item .culture-detail .culture-info{ gap: 16px; }
        .culture-content .culture-list .culture-item .culture-detail .culture-info .culture-icon{ width: 40px; height: 40px; }
        .culture-content .culture-list .culture-item .culture-detail .culture-info .culture-icon .culture-icon-item{ width: 40px; }
        .culture-content .culture-list .culture-item .culture-detail .culture-info .culture-name{ font-size: 16px; }
    
        .culture-content .culture-list .culture-item .culture-grown{ width: 100%; }
        .culture-content .culture-list .culture-item .culture-grown .culture-grown-list{ padding: 30px 20px; font-size: 14px; }
    
        .sub-title{ gap: 6px; }
        .sub-title .sub-title-item{ font-size: 16px; }
        .sub-title .sub-title-txt{ font-size: 24px; }
    }
`;