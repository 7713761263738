import styled from "@emotion/styled";
import visionBgImg from "../../assets/images/vision-bg.png";

export const VisionWrap = styled.section`

    positoin: relative; width: 100%; height: 400px; display: flex; flex-direction: column; align-items: center; justify-content: center; gap: 40px; background-color: #fff; background: url(${visionBgImg}) no-repeat center; background-size: cover;

    .title-wrap{ width: 60%; }
    .title-wrap .title-txt{  }
    .title-wrap .title-txt svg{  }
    .title-wrap .title-txt svg path{  }
    .title-wrap .title-detail .title-detail-item{  }
    .contents-wrap{ width: 60%; display: flex; flex-direction: column; gap: 20px; align-items: center; }
    .contents-wrap .contents-title{ color: #393939; text-align: center; font-family: 'Pretendard'; font-size: 18px; font-weight: 700; line-height: 140%; }
    .contents-wrap .contents-title strong{ color: #fb0000; }
    .contents-wrap .contents-txt{ color: #393939; text-align: center; font-family: 'Pretendard'; font-size: 14px; font-weight: 500; line-height: 144%; }

    
    @media screen and (max-width: 992px) {
        
        position: relative; width: 100vw; height: 320px; gap: 40px; overflow: hidden;
        
        
        .title-wrap{ width: fit-content; gap: 30px; z-index: 1; }
        .contents-wrap{ width: fit-content; z-index: 1; }
        .contents-wrap .contents-title{ font-size: 16px;  }
        .contents-wrap .contents-txt{ color: #555;  font-size: 12px; }
        .background-img{ position: absolute; z-index: 0; display: none; }
        .background-img svg{ height: 400px; }
    }
`;