import styled from "@emotion/styled";
// import visionBgImg from "../../assets/images/vision-bg.png";

export const RecruitCompWrap = styled.div`
    width: 1120px ; display: flex; flex-direction: row; justify-content: space-between; align-items: flex-start;

    .company-detail{ display: flex; flex-direction: column; align-items: flex-start; gap: 20px; padding-top: 40px; color: #777; font-family: 'Pretendard'; font-weight: 700; text-align: left; }
    .company-detail strong{ color: #000; }
    .company-detail .detail-desc{ font-size: 20px; }
    .company-detail .detail-add{ width: 520px; display: flex; flex-direction: column; align-items: flex-start; gap: 10px; padding-left: 20px; font-size: 16px; line-height: 150%; }

    @media screen and (max-width: 1400px) and (min-width: 992px){
        width: 900px;
    }
    
    @media screen and (max-width: 992px) {

        width: 320px; flex-direction: column; align-items: center; gap: 40px;

        .company-detail{ align-items: center; gap: 20px; padding-top: 0; }
        .company-detail .detail-desc{ font-size: 18px; }
        .company-detail .detail-add{ width: 300px; padding-left: 0; font-size: 14px; line-height: 170%; }

    }
`;