import styled from "@emotion/styled";
// import visionBgImg from "../../assets/images/vision-bg.png";

export const KeywordWrap = styled.div`
    width: 100%; display: flex; flex-direction: column; align-items: center; gap: 80px;

    .title-txt{  }
    .title-txt svg{  }
    .title-txt svg path{  }
    .contents-wrap{ width: 100%; display: flex; flex-direction: row; justify-content: space-between; align-items: center; }
    .contents-wrap .stats-wrap{ display: flex; flex-direction: column; gap: 16px; font-family: 'Pretendard'; font-weight: 700; }
    .contents-wrap .stats-wrap .stats-text-area{ display: flex; align-items: flex-end; gap: 8px; margin:0 auto; }
    .contents-wrap .stats-wrap .stats-text-area .stats-txt{ color: #fff; display: flex; align-items: flex-end; gap: 12px; }
    .contents-wrap .stats-wrap .stats-text-area .stats-txt .stats-txt-num{ font-size: 40px; transition: all 2.75s ease-out; font-family: 'Courier New', Courier, monospace; font-weight: 700; }
    .contents-wrap .stats-wrap .stats-text-area .stats-txt .stats-txt-unit{ font-size: 24px; padding-bottom: 4px; }
    .contents-wrap .stats-wrap .stats-text-area .stats-arrow{ padding-bottom: 2px; }
    .contents-wrap .stats-wrap .stats-desc{ color: #fb0000; font-size: 18px; }
    .contents-wrap .stats-bar{  }
    .contents-wrap .stats-bar.hidden{ display: none; }
    .contents-wrap .stats-bar svg{  }
    .contents-wrap .stats-bar svg path{  }
    
    @media screen and (max-width: 1400px){
        max-width: 920px;
    }
    @media screen and (max-width: 992px){
        max-width: 320px; gap: 40px;
        
        .contents-wrap{ width: 90%; display: grid; grid-template-columns: 1fr 1fr; gap: 40px 64px; }
        .contents-wrap .stats-wrap .stats-text-area{ justify-content: center; }
        .contents-wrap .stats-wrap .stats-text-area .stats-txt .stats-txt-num{ font-size: 32px;  }
        .contents-wrap .stats-wrap .stats-text-area .stats-txt .stats-txt-unit{ font-size: 20px; }
        .contents-wrap .stats-wrap .stats-desc{ font-size: 12px; }
        .contents-wrap .stats-bar{ display: none; }
    }
`;