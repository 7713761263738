import { BiWrap } from "../styles/common/bi.styled";
import BrandSwiper from "./brandSwiper";
import BrandList from "./brandList";
import React, { useState } from 'react';




const Bi = () => {
    const [selectedTab, setSelectedTab] = useState(0);
  
    const handleSelectTab = (index) => {
        setSelectedTab(index);
    };

    

    return(

        <BiWrap>
            <div className="title-wrap">
                <div className="title-txt">
                    <svg className="pc_only" width="64" height="34" viewBox="0 0 64 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.3425 8.92V13.04H17.4625C20.0225 13.04 21.4625 12.88 21.4625 10.8C21.4625 9 19.9025 8.92 17.4625 8.92H11.3425ZM0.0625 33.2V0.839999H20.4625C30.5825 0.839999 32.5425 5.08 32.5425 8.92C32.5425 12.16 30.6625 14.2 27.8225 15.24C31.2225 16 34.4225 18.6 34.4225 23.4C34.4225 29.84 29.7425 33.2 21.1825 33.2H0.0625ZM11.3425 19.88V25.08H18.2225C21.1025 25.08 22.8225 24.88 22.8225 22.4C22.8225 20.44 21.3825 19.88 18.6625 19.88H11.3425ZM37.8359 33.2V0.839999H49.3559V33.2H37.8359ZM63.5438 33.2H52.7438V25.08H63.5438V33.2Z" fill="black"/>
                    </svg>
                    <svg className="pc_none" width="52" height="27" viewBox="0 0 52 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.624 6.736V10.032H14.52C16.568 10.032 17.72 9.904 17.72 8.24C17.72 6.8 16.472 6.736 14.52 6.736H9.624ZM0.6 26.16V0.271999H16.92C25.016 0.271999 26.584 3.664 26.584 6.736C26.584 9.328 25.08 10.96 22.808 11.792C25.528 12.4 28.088 14.48 28.088 18.32C28.088 23.472 24.344 26.16 17.496 26.16H0.6ZM9.624 15.504V19.664H15.128C17.432 19.664 18.808 19.504 18.808 17.52C18.808 15.952 17.656 15.504 15.48 15.504H9.624ZM30.8188 26.16V0.271999H40.0348V26.16H30.8188ZM51.385 26.16H42.745V19.664H51.385V26.16Z" fill="black"/>
                    </svg>
                </div>
                <div className="title-box"></div>
            </div>
            <div className="content-wrap">
                <BrandSwiper className="brands-swiper-wrap" onSelectTab={handleSelectTab} />
                <BrandList className="brand-list" selectedTab={selectedTab} />
            </div>
        </BiWrap>
    )
}

export default Bi;
