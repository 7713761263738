import styled from "@emotion/styled";

export const CeoWrap = styled.section`

    width: 100%; display: flex; flex-direction: column; align-items: center; gap: 90px; padding-bottom: 160px;

    .title-wrap{ display: flex; flex-direction: column; gap: 10px; align-items: center; }
    .title-wrap::before{ content: ''; width: 4px; height: 80px; background-color: #06070B; }
    .title-wrap .title-txt{  }
    .title-wrap .title-txt svg{  }
    .title-wrap .title-txt svg path{  }
    .profile-wrap{ display: flex; flex-direction: row; justify-content: center; align-items: flex-end; gap: 120px; }
    .profile-wrap .profile-img{ width: 520px; height: 600px; }
    .profile-wrap .profile-img .profile-img-item{ width: 520px; height: auto; }
    .profile-wrap .profile-info{ max-width: 640px; display: flex; flex-direction: column; align-items: flex-start; gap: 80px; padding-bottom: 20px; }
    .profile-wrap .profile-info .profile-history{ display: flex; flex-direction: column; gap: 40px; font-family: 'Pretendard'; }
    .profile-wrap .profile-info .profile-history .profile-name{ color: #000; font-size: 32px; font-weight: 800; }
    .profile-wrap .profile-info .profile-history .history-wrap{  }
    .profile-wrap .profile-info .profile-history .history-wrap .history-list{ display: flex; flex-direction: column; gap: 10px; color: #777; font-size: 16px; font-weight: 500; text-align: left; }
    .profile-wrap .profile-info .profile-history .history-wrap .history-list .history-item{  }

    .profile-wrap .profile-info .profile-desc{ display: flex; flex-direction: column; gap: 15px; font-family: 'Pretendard'; text-align: left; }
    .profile-wrap .profile-info .profile-desc .desc-title{ color: #000; font-size: 20px; font-weight: 700; }
    .profile-wrap .profile-info .profile-desc .desc-par{ color: #777; font-size: 16px; font-weight: 500; line-height: 150%; padding-left: 20px; }
    .profile-wrap .profile-info .profile-desc .desc-par .desc-par-item{  }

    @media screen and (max-width: 1400px) {
        gap: 60px;
        .profile-wrap{ gap: 80px; }
        .profile-wrap .profile-img{ width: 390px; height: 450px; }
        .profile-wrap .profile-img .profile-img-item{ width: 390px; }
        .profile-wrap .profile-info{ max-width: 400px; gap: 60px; padding-bottom: 0; }
        .profile-wrap .profile-info .profile-history{ gap: 32px; }
        .profile-wrap .profile-info .profile-history .profile-name{ font-size: 24px; }
        .profile-wrap .profile-info .profile-history .history-wrap .history-list{ font-size: 14px; font-weight: 600; padding-left: 10px; }
    
        .profile-wrap .profile-info .profile-desc{ gap: 20px; }
        .profile-wrap .profile-info .profile-desc .desc-title{ font-size: 16px; line-height: 150%; }
        .profile-wrap .profile-info .profile-desc .desc-par{ padding-left: 10px; font-size: 14px; }
    }
    
    @media screen and (max-width: 992px) {
        
        gap: 40px; padding-bottom: 120px;
    
        .profile-wrap{ flex-direction: column; align-items: center; gap: 40px; }
        .profile-wrap .profile-img{ width: 260px; height: 300px; }
        .profile-wrap .profile-img .profile-img-item{ width: 260px; }
        .profile-wrap .profile-info{ max-width: 320px; align-items: flex-start; gap: 40px; padding-bottom: 0; }
        .profile-wrap .profile-info .profile-history{ gap: 20px; }
        .profile-wrap .profile-info .profile-history .profile-name{ font-size: 24px; }
        .profile-wrap .profile-info .profile-history .history-wrap .history-list{ font-size: 14px; font-weight: 600; padding-left: 10px; }
    
        .profile-wrap .profile-info .profile-desc{ gap: 20px; }
        .profile-wrap .profile-info .profile-desc .desc-title{ font-size: 16px; line-height: 150%; }
        .profile-wrap .profile-info .profile-desc .desc-par{ padding-left: 10px; }
    }
`;