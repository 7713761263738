import { RecruitCTAWrap } from "../styles/common/recruitCTA.styled";
import RecruitCtaImg from "../assets/images/recruit-cta-img.png";
import ApplyBtn from "./applyBtn";
// import { useNavigate } from "react-router-dom";


const RecruitCTA = () => {

    const handleMoreClick = () => {
        window.location.href = 'https://www.jobkorea.co.kr/Recruit/Co_Read/Recruit/C/24665324';
    }


    return(

        <RecruitCTAWrap>
            <div className="cta-wrap">
                <div className="cta-content">
                    <div className="cta-title">
                        <p className="title-txt">함께 러너즈가 될 동료를 찾습니다.</p>
                        <div className="title-bottom">
                            <p className="title-desc">지속적인 학습과 성장에 대한<br className="pc_none" /> 욕구가 넘치는 분들을 채용합니다.</p>
                            <p className="title-desc">새로운 러너분들도 언젠가는 스스로<br className="pc_none" /> 자립할 수 있을 만큼 성장하길 원합니다.</p>
                        </div>
                    </div>
                    <div className="more-btn" onClick={handleMoreClick} >
                        <ApplyBtn type="button" className="more-btn-item"/>
                    </div>
                </div>
                <div className="cta-img">
                    <img src={RecruitCtaImg} className="cta-img-item" alt="" />
                </div>
            </div>
        </RecruitCTAWrap>
    )
}

export default RecruitCTA;
