import Recruit from "../components/recruit";
import RecruitCTA from "../components/recruitCTA";
import RecruitTitle from "../components/recruitTitle";




const RecruitPage = () => {

    

    return(

        <>
            <RecruitTitle />
            <Recruit />
            <RecruitCTA />
        </>
    )
}

export default RecruitPage;
