import styled from "@emotion/styled";
// import visionBgImg from "../../assets/images/vision-bg.png";

export const InterviewDetailWrap = styled.section`
    * { font-family: Pretendard; }
    width: 100%; display: flex; flex-direction: column; align-items: center; gap: 60px; padding: 90px 10% 160px;

    button { all: unset; cursor: pointer; }
    .title-wrap{ width: 100%; max-width: 1315px; display: flex; flex-direction: row; gap: 30px; justify-content: space-between; align-items: center; }
    .title-wrap .title-txt{  }
    .title-wrap .title-txt img{ width: 266px; }
    .title-wrap .title-box{ width: 100%; height: 4px; background-color: #000; }

    .keywords-wrap { width: 100%; display: flex; align-items: center; justify-content: flex-start; gap: 6px; padding: 0 15.5px; }
    .keywords-wrap .keywords-wrap_item { display: flex; padding: 14px 15px; border-bottom: 2px solid #EDEDED; transition: border-bottom-color 0.3s ease; white-space: nowrap; }
    .keywords-wrap .keywords-wrap_item.active { border-bottom-color: #000; }
    .keywords-wrap .keywords-wrap_item .keyword-box { display: flex; align-items: center; }
    .keywords-wrap .keywords-wrap_item .keyword-button { white-space: nowrap; color: #A5A5A5; font-size: 18px; font-weight: 400; line-height: normal; }
    .keywords-wrap .keywords-wrap_item .keyword-button.active { color: #000; }
    .keywords-wrap .keywords-wrap_item .keyword-dot { padding: 0 4px; }

    .contents-wrap { width: 100%; max-width: 1315px; display: flex; flex-direction: column; justify-content: center; align-items: flex-start; gap: 40px; font-family: 'Pretendard'; }
    .contents-wrap .contents-intro-wrap { position: relative; overflow:hidden; display:flex; flex-direction: column; width: 100%; border-radius: 43px; background: #540000; text-align: left; }

    .pc_show { display: inline-block; }
    .pc_none { display: none; }
    .contents-wrap .contents-intro-wrap .intro-wrap_question { position: sticky; top:0; z-index: 1; padding: 85px 168px 0; background: #540000; }
    .contents-wrap .contents-intro-wrap .intro-wrap_question .radius-box { display:inline-block; padding: 8px 20px; border-radius: 31px; background: #FFF; }
    .contents-wrap .contents-intro-wrap .intro-wrap_question .radius-box span { color: #000; font-size: 16px; font-weight: 700; line-height: normal;}
    .contents-wrap .contents-intro-wrap .intro-wrap_question .text-ques-item { color: #FFF; font-size: 40px; font-weight: 700; line-height: 135%; padding-top: 18px; }
    .contents-wrap .contents-intro-wrap .intro-wrap_question .slide-pagination { display: flex; padding: 30px 0 0; align-items: center; gap: 10px; }
    .contents-wrap .contents-intro-wrap .intro-wrap_question .slide-pagination .slide-arrow { padding: 10px 14px; }
    .contents-wrap .contents-intro-wrap .intro-wrap_question .slide-pagination .page-num { color: #FFF; font-size: 18px; font-weight: 400; line-height: 144%; }

    .contents-wrap .contents-intro-wrap .intro-wrap_answer { width: 630px; height:100%; max-height: 330px; overflow-y: auto; align-self: flex-end; display: flex; flex-direction: column; gap: 20px; margin: 0 117px 40px auto; }
    .contents-wrap .contents-intro-wrap .intro-wrap_answer::-webkit-scrollbar { width: 8px; }
    .contents-wrap .contents-intro-wrap .intro-wrap_answer::-webkit-scrollbar-thumb { height: 10%; background: #ffff; border-radius: 10px; }

    .contents-wrap .contents-intro-wrap .intro-wrap_answer .answer-block { box-sizing: border-box; width: 579px; border-radius: 26px; background: #FFF; padding: 36px 50px; }
    .contents-wrap .contents-intro-wrap .intro-wrap_answer .answer-block .keyword-item { color: #454545;font-size: 16px; font-weight: 600; line-height: 160%; padding-bottom: 10px;}
    .contents-wrap .contents-intro-wrap .intro-wrap_answer .text-ans-item { color: #424242; font-size: 16px; font-weight: 400; line-height: 160%; }
    .contents-wrap .contents-intro-wrap .intro-wrap_answer .text-ans-item b { font-weight: 700; }
    .contents-wrap .contents-intro-wrap .intro-wrap_answer .text-ans-item ol { list-style-position: inside; }
    .contents-wrap .contents-intro-wrap .intro-wrap_answer .text-ans-item li { position: relative; padding-left: 15px; }
    .contents-wrap .contents-intro-wrap .intro-wrap_answer .text-ans-item li::before { content: "•"; position: absolute; left: 0; font-weight: 800; }

    .contents-wrap .contents-desc-wrap{ display: flex; flex-direction: column; justify-content: center; align-items: flex-start; gap: 60px; }
    .contents-wrap .contents-desc-wrap .contents-desc{ display: flex; flex-direction: column; justify-content: center; align-items: flex-start; gap: 48px; text-align: left; }
    .contents-wrap .contents-desc-wrap .contents-desc .contents-text{ width: 100%; max-width: 1200px; display: flex; flex-direction: column; justify-content: center; align-items: flex-start; gap: 15px; font-weight: 700; }
    .contents-wrap .contents-desc-wrap .contents-desc .contents-text .text-ques-item{ color: #000; font-size: 18px; line-height: normal; }
    .contents-wrap .contents-desc-wrap .contents-desc .contents-text .text-ans-item{ color: #777; font-size: 16px; line-height: 140%; }
    
    @media screen and (max-width: 1400px) {
        gap: 48px;

        .keywords-wrap { overflow-x: scroll; scrollbar-width: none;}
        .title-wrap{ max-width: 800px; }
        .contents-wrap{ max-width: 800px; gap: 24px; }
        .contents-wrap .contents-intro-wrap { padding: 85px 100px 40px; }

        .tab_show { display: inline-block; }
        .tab_none { display: none; }
        .contents-wrap .contents-intro-wrap .intro-wrap_question .slide-pagination { padding: 30px 0; }

        .contents-wrap .contents-intro-wrap .intro-wrap_answer { width: 100%; margin-right: 0; margin-bottom: 0; }
        .contents-wrap .contents-intro-wrap .intro-wrap_answer::-webkit-scrollbar { width: 6px; }
        .contents-wrap .contents-intro-wrap .intro-wrap_answer .answer-block { max-width: 100%; }
        .contents-wrap .contents-intro-wrap .intro-wrap_question { padding: 0 10px; }
    }
    
    @media screen and (max-width: 992px) {
        
        gap: 20px; padding: 60px 0 40px;
    
        .title-wrap{ width: 100%; max-width: 320px; display: flex; flex-direction: row; gap: 12px; justify-content: space-between; align-items: center; }
        .title-wrap .title-txt img{ width: 217px; }
        .title-wrap .title-box{ height: 2px; }

        .keywords-wrap { gap: 4px; padding: 0; }
        .keywords-wrap .keywords-wrap_item { padding: 10px 11px; }
        .keywords-wrap .keywords-wrap_item .keyword-button { font-size: 14px; }
    
        .contents-wrap{ width: 320px; gap: 40px; }
        .contents-wrap .contents-intro-wrap { padding: 40px 20px 30px; border-radius: 23px;}
        .contents-wrap .contents-intro-wrap .intro-wrap_question { }
        .contents-wrap .contents-intro-wrap .intro-wrap_question .radius-box { padding: 6px 16px;}
        .contents-wrap .contents-intro-wrap .intro-wrap_question .radius-box span { font-size: 13px; }
        .contents-wrap .contents-intro-wrap .intro-wrap_question .text-ques-item { font-size: 26px; padding-top: 14px; }
        .contents-wrap .contents-intro-wrap .intro-wrap_question .slide-pagination { gap: 8px; }
        .contents-wrap .contents-intro-wrap .intro-wrap_question .slide-pagination .slide-arrow { padding: 6px 8px; }
        .contents-wrap .contents-intro-wrap .intro-wrap_question .slide-pagination .slide-arrow svg { width: 4px; height: 8px;}
        .contents-wrap .contents-intro-wrap .intro-wrap_question .slide-pagination .page-num { font-size: 14px; }

        .contents-wrap .contents-intro-wrap .intro-wrap_answer::-webkit-scrollbar { width: 4px; }
        .contents-wrap .contents-intro-wrap .intro-wrap_answer .answer-block { width: 265px; border-radius: 10px; padding: 20px; }
        .contents-wrap .contents-intro-wrap .intro-wrap_answer .answer-block .keyword-item { font-size: 14px; }
        .contents-wrap .contents-intro-wrap .intro-wrap_answer .text-ans-item { font-size: 13px;}

        .contents-wrap .contents-desc-wrap{ gap: 40px; }
        .contents-wrap .contents-desc-wrap .contents-desc{ gap: 32px; padding: 0 6px; }
        .contents-wrap .contents-desc-wrap .contents-desc .contents-text{ gap: 10px; }
        .contents-wrap .contents-desc-wrap .contents-desc .contents-text .text-ques-item{ font-size: 16px; line-height: 150%; }
        .contents-wrap .contents-desc-wrap .contents-desc .contents-text .text-ans-item{ font-size: 14px; line-height: 160%; }
    }
`;