import styled from "@emotion/styled";

export const RecruitCTAWrap = styled.section`
    width: 100vw; height: 400px; display: flex; justify-content: center; align-items: center; background-color: #141414; overflow: hidden;

    .cta-wrap{ max-width: 1520px; display: flex; flex-direction: row; justify-content: space-between; align-items: center; padding-left: 200px; }
    .cta-wrap .cta-content{ display: flex; flex-direction: column; align-items: flex-start; gap: 60px; color: #fff; text-align: left; }
    .cta-wrap .cta-content .cta-title{ display: flex; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 20px; font-family: 'Pretendard'; line-height: normal; }
    .cta-wrap .cta-content .cta-title .title-txt{ font-size: 32px; font-weight: 900; color: #fff; }
    .cta-wrap .cta-content .cta-title .title-bottom{ display: flex; flex-direction: column; gap: 10px; justify-content: flex-start; align-items: flex-start; font-size: 16px; font-weight: 700; color: #ccc; }
    .cta-wrap .cta-img{  }
    .cta-wrap .cta-img img{ width: 667px; height: 667px; }
    
    @media screen and (max-width: 1400px) {
        
        width: 100vw; height: 400px; display: flex; justify-content: center; align-items: center; background-color: #141414; overflow: hidden;
    
        .cta-wrap{ position: relative; max-width: 1520px; display: flex; flex-direction: row; justify-content: space-between; align-items: center; padding-left: 0; }
        .cta-wrap .cta-content{ align-items: center; gap: 40px; z-index: 1; }
        .cta-wrap .cta-content .cta-title{ align-items: center; }
        .cta-wrap .cta-content .cta-title .title-txt{ font-size: 24px; }
        .cta-wrap .cta-content .cta-title .title-bottom{ gap: 8px; align-items: center; font-size: 14px; color: #fff; text-align: center; line-height: 140%; }
        .cta-wrap .cta-img{ position: absolute; top: calc(50% - 200px); left: calc(50% - 200px); z-index: 0; }
        .cta-wrap .cta-img img{ width: 400px; height: 400px; opacity: 0.4; background: linear-gradient(270deg, #141414 0%, rgba(20, 20, 20, 0.00) 49.93%), linear-gradient(90deg, #141414 0%, rgba(20, 20, 20, 0.00) 50.07%), lightgray 50% / cover no-repeat; }

    }

    @media screen and (max-width: 992px) {
        
        width: 100vw; height: 400px; display: flex; justify-content: center; align-items: center; background-color: #141414; overflow: hidden;
    
        .cta-wrap{ position: relative; max-width: 1520px; display: flex; flex-direction: row; justify-content: space-between; align-items: center; padding-left: 0; }
        .cta-wrap .cta-content{ align-items: center; gap: 40px; z-index: 1; }
        .cta-wrap .cta-content .cta-title{ align-items: center; }
        .cta-wrap .cta-content .cta-title .title-txt{ font-size: 24px; }
        .cta-wrap .cta-content .cta-title .title-bottom{ gap: 8px; align-items: center; font-size: 14px; color: #fff; text-align: center; line-height: 140%; }
        .cta-wrap .cta-img{ position: absolute; top: calc(50% - 200px); left: calc(50% - 200px); z-index: 0; }
        .cta-wrap .cta-img img{ width: 400px; height: 400px; opacity: 0.4; background: linear-gradient(270deg, #141414 0%, rgba(20, 20, 20, 0.00) 49.93%), linear-gradient(90deg, #141414 0%, rgba(20, 20, 20, 0.00) 50.07%), lightgray 50% / cover no-repeat; }

    }
`;