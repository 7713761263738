import { SummaryWrap } from "../assets/styles/recruitSummary.styled";
import { RecruitApplyWrap } from "../styles/common/recruitApply.styled";


const RecruitApply = () => {


    return(

        <RecruitApplyWrap>
            <SummaryWrap>
                <p className="summary-title">지원 방법</p>
            </SummaryWrap>
            <ul className="application-list">
                <li className="application-item color_num1">서류전형</li>
                <li className="application-item-arr">
                    <svg className="pc_only" xmlns="http://www.w3.org/2000/svg" width="16" height="24" viewBox="0 0 16 24" fill="none">
                        <path d="M2.5 2L12.5 12L2.5 22" stroke="#06070B" strokeWidth="4" strokeLinecap="round"/>
                    </svg>
                    <svg className="pc_none" width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.5 1L7.5 7L1.5 13" stroke="black" strokeWidth="2" strokeLinecap="round"/>
                    </svg>
                </li>
                <li className="application-item color_num2">실무자 인터뷰</li>
                <li className="application-item-arr">
                    <svg className="pc_only" xmlns="http://www.w3.org/2000/svg" width="16" height="24" viewBox="0 0 16 24" fill="none">
                        <path d="M2.5 2L12.5 12L2.5 22" stroke="#06070B" strokeWidth="4" strokeLinecap="round"/>
                    </svg>
                    <svg className="pc_none" width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.5 1L7.5 7L1.5 13" stroke="black" strokeWidth="2" strokeLinecap="round"/>
                    </svg>
                </li>
                <li className="application-item color_num3">CEO 인터뷰</li>
                <li className="application-item-arr">
                    <svg className="pc_only" xmlns="http://www.w3.org/2000/svg" width="16" height="24" viewBox="0 0 16 24" fill="none">
                        <path d="M2.5 2L12.5 12L2.5 22" stroke="#06070B" strokeWidth="4" strokeLinecap="round"/>
                    </svg>
                    <svg className="pc_none" width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.5 1L7.5 7L1.5 13" stroke="black" strokeWidth="2" strokeLinecap="round"/>
                    </svg>
                </li>
                <li className="application-item color_num4">처우 협의</li>
                <li className="application-item-arr">
                    <svg className="pc_only" xmlns="http://www.w3.org/2000/svg" width="16" height="24" viewBox="0 0 16 24" fill="none">
                        <path d="M2.5 2L12.5 12L2.5 22" stroke="#06070B" strokeWidth="4" strokeLinecap="round"/>
                    </svg>
                    <svg className="pc_none" width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.5 1L7.5 7L1.5 13" stroke="black" strokeWidth="2" strokeLinecap="round"/>
                    </svg>
                </li>
                <li className="application-item color_num5">최종합격</li>
            </ul>
        </RecruitApplyWrap>
    )
}

export default RecruitApply;
