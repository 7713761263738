import History from "../components/history";
import HistoryTitle from "../components/historyTitle";
import Media from "../components/media";




const HistoryPage = () => {

    

    return(

        <>
            <HistoryTitle />
            <History />
            <Media />
        </>
    )
}

export default HistoryPage;
