import styled from "@emotion/styled";

export const SummaryWrap = styled.div`

    display: flex; flex-direction: column; justify-content: center; align-items: flex-start; gap: 10px; color: #000; font-family: 'Pretendard';
    .summary-subtitle{ font-size: 20px; font-weight: 800; }
    .summary-title{ font-size: 32px; font-weight: 900; }
    
    @media screen and (max-width: 992px) {
        
        align-items: center; gap: 6px; 
        .summary-subtitle{ font-size: 16px; }
        .summary-title{ font-size: 24px; font-weight: 800; }

    }
    
`;