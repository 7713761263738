import { VisionWrap } from "../styles/common/vision.styled";


const Vision = () => {


    return(

        <VisionWrap>
            <div className="background-img pc_none">
                <svg width="808" height="648" viewBox="0 0 808 648" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d_2352_254)">
                    <path d="M660.008 324C660.008 436.805 545.609 528.607 404 528.607C262.391 528.607 147.992 436.805 147.992 324C147.992 211.196 262.391 119.394 404 119.394C545.609 119.394 660.008 211.196 660.008 324Z" stroke="#FB0000" strokeOpacity="0.2" strokeWidth="2" shapeRendering="crispEdges"/>
                    </g>
                    <g filter="url(#filter1_d_2352_254)">
                    <path d="M680.165 324C680.165 445.71 556.741 544.732 403.999 544.732C251.258 544.732 127.834 445.71 127.834 324C127.834 202.289 251.258 103.268 403.999 103.268C556.741 103.268 680.165 202.289 680.165 324Z" stroke="#FB0000" strokeOpacity="0.3" strokeWidth="2" shapeRendering="crispEdges"/>
                    </g>
                    <g filter="url(#filter2_d_2352_254)">
                    <path d="M700.952 324C700.952 454.895 568.222 561.362 404 561.362C239.777 561.362 107.047 454.895 107.047 324C107.047 193.105 239.777 86.6377 404 86.6377C568.222 86.6377 700.952 193.105 700.952 324Z" stroke="#FB0000" strokeOpacity="0.4" strokeWidth="2" shapeRendering="crispEdges"/>
                    </g>
                    <g filter="url(#filter3_d_2352_254)">
                    <path d="M721.111 324C721.111 463.801 579.356 577.488 404.001 577.488C228.646 577.488 86.8906 463.801 86.8906 324C86.8906 184.199 228.646 70.5117 404.001 70.5117C579.356 70.5117 721.111 184.199 721.111 324Z" stroke="#FB0000" strokeOpacity="0.5" strokeWidth="2" shapeRendering="crispEdges"/>
                    </g>
                    <g filter="url(#filter4_d_2352_254)">
                    <path d="M741.268 324C741.268 472.707 590.488 593.614 404 593.614C217.513 593.614 66.7324 472.707 66.7324 324C66.7324 175.293 217.513 54.3857 404 54.3857C590.488 54.3857 741.268 175.293 741.268 324Z" stroke="#FB0000" strokeOpacity="0.6" strokeWidth="2" shapeRendering="crispEdges"/>
                    </g>
                    <g filter="url(#filter5_d_2352_254)">
                    <path d="M762.055 324C762.055 481.891 601.969 610.244 404 610.244C206.032 610.244 45.9453 481.891 45.9453 324C45.9453 166.108 206.032 37.7559 404 37.7559C601.969 37.7559 762.055 166.108 762.055 324Z" stroke="#FB0000" strokeOpacity="0.7" strokeWidth="2" shapeRendering="crispEdges"/>
                    </g>
                    <g filter="url(#filter6_d_2352_254)">
                    <path d="M782.212 324C782.212 490.798 613.1 626.37 404 626.37C194.899 626.37 25.7871 490.798 25.7871 324C25.7871 157.202 194.899 21.6299 404 21.6299C613.1 21.6299 782.212 157.202 782.212 324Z" stroke="#FB0000" strokeOpacity="0.8" strokeWidth="2" shapeRendering="crispEdges"/>
                    </g>
                    <g filter="url(#filter7_d_2352_254)">
                    <path d="M803 324C803 499.982 624.581 643 404 643C183.419 643 5 499.982 5 324C5 148.018 183.419 5 404 5C624.581 5 803 148.018 803 324Z" stroke="#FB0000" strokeWidth="2" shapeRendering="crispEdges"/>
                    </g>
                    <defs>
                    <filter id="filter0_d_2352_254" x="142.992" y="114.394" width="522.016" height="419.212" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.482353 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2352_254"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2352_254" result="shape"/>
                    </filter>
                    <filter id="filter1_d_2352_254" x="122.834" y="98.2676" width="562.331" height="451.464" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.482353 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2352_254"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2352_254" result="shape"/>
                    </filter>
                    <filter id="filter2_d_2352_254" x="102.047" y="81.6377" width="603.905" height="484.725" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.482353 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2352_254"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2352_254" result="shape"/>
                    </filter>
                    <filter id="filter3_d_2352_254" x="81.8906" y="65.5117" width="644.221" height="516.977" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.482353 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2352_254"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2352_254" result="shape"/>
                    </filter>
                    <filter id="filter4_d_2352_254" x="61.7324" y="49.3857" width="684.535" height="549.229" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.482353 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2352_254"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2352_254" result="shape"/>
                    </filter>
                    <filter id="filter5_d_2352_254" x="40.9453" y="32.7559" width="726.11" height="582.488" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.482353 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2352_254"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2352_254" result="shape"/>
                    </filter>
                    <filter id="filter6_d_2352_254" x="20.7871" y="16.6299" width="766.425" height="614.74" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.482353 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2352_254"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2352_254" result="shape"/>
                    </filter>
                    <filter id="filter7_d_2352_254" x="0" y="0" width="808" height="648" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.482353 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2352_254"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2352_254" result="shape"/>
                    </filter>
                    </defs>
                </svg>
            </div>
            <div className="title-wrap">
                <div className="title-txt">
                    <svg className="pc_only" width="178" height="35" viewBox="0 0 178 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M36.1184 1.84L24.0384 34.2H11.5584L-0.00156253 1.84H11.4784L17.7584 21.72H17.8384L24.1184 1.84H36.1184ZM49.0709 0.599998V7.12H37.7109V0.599998H49.0709ZM37.7109 34.2V9.12H49.0709V34.2H37.7109ZM79.8625 16.92H69.6225C69.5825 14.96 67.7825 14.48 66.1025 14.48C64.5025 14.48 62.9025 14.8 62.9025 16.2C62.9025 16.8 63.5425 17.16 64.1025 17.36C65.9025 18.04 69.9825 18.28 73.7425 19.28C77.5025 20.32 81.1425 21.72 81.1425 25.88C81.1425 33.28 73.3825 34.8 66.3825 34.8C59.5025 34.8 51.9025 33.08 51.8625 25.6H62.6625C62.7425 27.84 64.5025 28.44 66.7825 28.44C68.7025 28.44 70.4625 28 70.4625 26.72C70.4625 25.56 69.5425 25.12 64.3825 24.32C55.9425 22.96 52.0625 20.68 52.0625 16.36C52.0625 9.52 59.9825 8.32 65.8225 8.32C72.0225 8.32 79.1825 9.88 79.8625 16.92ZM95.3209 0.599998V7.12H83.9609V0.599998H95.3209ZM83.9609 34.2V9.12H95.3209V34.2H83.9609ZM113.713 8.32C122.673 8.32 129.313 11.84 129.313 21.6C129.313 32.28 122.753 34.8 113.713 34.8C104.633 34.8 98.1125 32.28 98.1125 21.6C98.1125 11.84 104.753 8.32 113.713 8.32ZM113.713 27.8C117.473 27.8 119.113 25.88 119.113 21.76V21.16C119.113 17.08 117.713 15.24 113.713 15.24C109.713 15.24 108.313 17.08 108.313 21.16V21.76C108.313 25.88 109.953 27.8 113.713 27.8ZM132.125 34.2V9.12H142.645L143.045 11.24H143.165C145.365 9.08 148.245 8.32 152.845 8.32C158.165 8.32 163.005 11.08 163.005 16.84V34.2H151.725V20.96C151.725 18.04 151.285 16 147.965 16C146.005 16 143.445 17.56 143.445 21.6V34.2H132.125ZM177.208 34.2H166.408V26.08H177.208V34.2Z" fill="black"/>
                    </svg>
                    <svg className="pc_none" width="143" height="28" viewBox="0 0 143 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M29.7948 1.272L20.1308 27.16H10.1468L0.89875 1.272H10.0828L15.1068 17.176H15.1708L20.1948 1.272H29.7948ZM40.1568 0.279999V5.496H31.0688V0.279999H40.1568ZM31.0688 27.16V7.096H40.1568V27.16H31.0688ZM64.79 13.336H56.598C56.566 11.768 55.126 11.384 53.782 11.384C52.502 11.384 51.222 11.64 51.222 12.76C51.222 13.24 51.734 13.528 52.182 13.688C53.622 14.232 56.886 14.424 59.894 15.224C62.902 16.056 65.814 17.176 65.814 20.504C65.814 26.424 59.606 27.64 54.006 27.64C48.502 27.64 42.422 26.264 42.39 20.28H51.03C51.094 22.072 52.502 22.552 54.326 22.552C55.862 22.552 57.27 22.2 57.27 21.176C57.27 20.248 56.534 19.896 52.406 19.256C45.654 18.168 42.55 16.344 42.55 12.888C42.55 7.416 48.886 6.456 53.558 6.456C58.518 6.456 64.246 7.704 64.79 13.336ZM77.1568 0.279999V5.496H68.0688V0.279999H77.1568ZM68.0688 27.16V7.096H77.1568V27.16H68.0688ZM91.87 6.456C99.038 6.456 104.35 9.272 104.35 17.08C104.35 25.624 99.102 27.64 91.87 27.64C84.606 27.64 79.39 25.624 79.39 17.08C79.39 9.272 84.702 6.456 91.87 6.456ZM91.87 22.04C94.878 22.04 96.19 20.504 96.19 17.208V16.728C96.19 13.464 95.07 11.992 91.87 11.992C88.67 11.992 87.55 13.464 87.55 16.728V17.208C87.55 20.504 88.862 22.04 91.87 22.04ZM106.6 27.16V7.096H115.016L115.336 8.792H115.432C117.192 7.064 119.496 6.456 123.176 6.456C127.432 6.456 131.304 8.664 131.304 13.272V27.16H122.28V16.568C122.28 14.232 121.928 12.6 119.272 12.6C117.704 12.6 115.656 13.848 115.656 17.08V27.16H106.6ZM142.666 27.16H134.026V20.664H142.666V27.16Z" fill="black"/>
                    </svg>
                </div>
            </div>
            <div className="contents-wrap">
                <p className="contents-title">
                    {/* 궁극적으로 모든 개인이 회사에 의존하지 않고도<br className="pc_none" /> 경제적으로 자립하고 능동적으로 삶을 살 수 있는<br className="pc_none" /> 세상을 꿈꾸고 있습니다 */}
                    모두가 회사에 의존하지 않고도 경제적으로 자립해<br /> <strong>능동적으로 삶을 살 수 있는 세상</strong>을 꿈꾸고 있습니다
                </p>
                <p className="contents-txt">
                    {/* 자동화, 언택트 세대가 다가오면서 경제 활동의<br className="pc_none" /> 방식이 점차 개인화되어 변화하고 있습니다.<br className="pc_only"/>
                    시<br className="pc_none" />대 변화에 맞춰 다음 세대에게는 기존 입시, 취업<br className="pc_none" /> 위주의 교육에서 벗어난 새로운 교육이 필요합니<br className="pc_none" />다.<br className="pc_only"/>
                    넥스트러너스는 아이부터 성인까지 전체 생<br className="pc_none" />애 주기에 걸쳐<br className="pc_only"/>
                    스스로 경제적 활동을 하고 능동<br className="pc_none" />적 삶을 살아가기 위해 필요한 핵심 역량들을 교<br className="pc_none" />육하고 있습니다. */}
                    AI, 자동화, 인구절벽과 같은 급격한 시대 변화에 따라 <br className="pc_none" />생존을 위한 경제 활동 방식이 180도 변화하고 있습니다<br /> 
                    넥스트러너스는 변화에 가장 앞장서서 <br className="pc_none" />미래 세대에게 필요한 핵심 역량을 교육 합니다
                </p>
            </div>
        </VisionWrap>
    )
}

export default Vision;
