import MainVisual from "../components/mainVisual";
import Vision from "../components/vision";
import ServiceKeyword from "../components/serviceKeyword";
import MainInterview from "../components/mainInterview";
import MainRecruit from "../components/mainRecruit";
import Video from "../components/video";


const HomePage = () => {

    

    return(

        <>
            {/* {props.children} */}
            <MainVisual />
            <Vision />
            <Video />
            <ServiceKeyword />
            <MainInterview />
            <MainRecruit />
        </>
    )
}

export default HomePage;
