import styled from "@emotion/styled";
// import visionBgImg from "../../assets/images/vision-bg.png";


export const BrandWrap = styled.section`
    max-width: 1360px ; display: flex; flex-direction: column; justify-content: space-between; align-items: flex-start; gap: 74px; margin: 0 auto; padding: 150px 0;

    .section-title{ display: flex; flex-direction: column; gap: 10px; align-items: flex-start; }
    .section-title .title{ color:#191919; font-family: 'Pretendard'; font-size: 25px; font-weight: 700; line-height: 152%; }
    .section-title .title-desc{ width: 250px; text-align: left; font-family: 'Montserrat'; font-size: 16px; font-weight: 400; line-height: 162%; color: #5e5e5e; }


    .brand-list{ width: 890px; display: flex; flex-direction: column; gap: 150px; align-self: flex-end; font-family: 'Pretendard'; }
    .brand-list .brandIntro-item{ display: flex; flex-direction: column; gap: 35px; justify-content: center; align-items: center; }
    .brand-list .brandIntro-item .brandIntro-desc-wrap{ width: 100%; display: flex; flex-direction: row; justify-content: space-between; align-items: flex-end; padding-right: 10px; }
    .brand-list .brandIntro-item .brandIntro-desc-wrap .brandIntro-desc{ display: flex; flex-direction: row; gap: 20px; }
    .brand-list .brandIntro-item .brandIntro-desc-wrap .brandIntro-desc .brandIntro-icon{ width: 54px; height: 54px; border-radius: 100%; background-size: cover; }
    .brand-list .brandIntro-item .brandIntro-desc-wrap .brandIntro-desc .brandIntro-text{ display: flex; flex-direction: column; justify-content: center; align-items: flex-start; }
    .brand-list .brandIntro-item .brandIntro-desc-wrap .brandIntro-desc .brandIntro-text .brandIntro-name{ color: #191919; font-family: 'Pretendard'; font-size: 20px; font-weight: 700; line-height: 160%; }
    .brand-list .brandIntro-item .brandIntro-desc-wrap .brandIntro-desc .brandIntro-text .brandIntro-detail{ color: #5e5e5e; font-family: 'Montsserat'; font-size: 14px; font-weight: 400; line-height: 160%; }
    .brand-list .brandIntro-item .brandIntro-more{  }
    .brand-list .brandIntro-item .brandIntro-more .brandIntro-more-btn{ display: flex; flex-direction: row; gap: 2px; color: #b9b9b9; font-size: 12px; font-weight: 700; line-height: 144%; }
    .brand-list .brandIntro-item .brandIntro-img-wrap{ display: flex; flex-direction: row; gap: 10px; }
    .brand-list .brandIntro-item .brandIntro-img-wrap .brandIntro-img{ width: 290px; height: 436px; background-color: #adadad; over-flow: hidden; border-radius: 20px; background-size: cover; }

    @media screen and (max-width: 1400px) {
        padding: 80px 40px 100px; align-items: center; gap: 60px;

        .section-title{ width: 890px; }
        .brand-list{ align-self: center; }
    }
    
    @media screen and (max-width: 992px) {
        
        max-width: unset; padding: 60px 0; gap: 70px;
    
        .section-title{ width: 320px; margin: 0 auto; }
        .section-title .title{ font-size: 18px; line-height: 140%; }
        .section-title .title-desc{ width: 180px; font-size: 12px; line-height: 160%; }
    
    
        .brand-list{ width: 340px; align-self: flex-end; }
        .brand-list .brandIntro-item{ flex-direction: column-reverse; gap: 20px; justify-content: center; align-items: flex-start; }
        .brand-list .brandIntro-item .brandIntro-desc-wrap{ flex-direction: column; align-items: flex-start; gap: 20px; padding-right: 0; }
        .brand-list .brandIntro-item .brandIntro-desc-wrap .brandIntro-desc .brandIntro-icon{ display: none; }
        .brand-list .brandIntro-item .brandIntro-desc-wrap .brandIntro-desc .brandIntro-text{ width: 320px; text-align: left; }
        .brand-list .brandIntro-item .brandIntro-desc-wrap .brandIntro-desc .brandIntro-text .brandIntro-name{ font-size: 16px; }
        .brand-list .brandIntro-item .brandIntro-desc-wrap .brandIntro-desc .brandIntro-text .brandIntro-detail{ font-size: 12px; }
        .brand-list .brandIntro-item .brandIntro-img-wrap{ width: 340px; height: 300px; padding-right: 10px; overflow-x: auto; overflow-y: hidden; }
        .brand-list .brandIntro-item .brandIntro-img-wrap .brandIntro-img{ width: 200px !important; height: 300px; flex: 0 0 200px; border-radius: 16px; }
    }
    
`;