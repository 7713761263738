import styled from "@emotion/styled";

export const HamMenuMobWrap = styled.div`

    &.slide-in { animation: slideIn 0.5s forwards; }
    &.slide-out { animation: slideOut 0.5s forwards; }
    @keyframes slideIn {
        from {
            right: -100vw;
        }
        to {
            right: 0;
        }
    }

    @keyframes slideOut {
        from {
            right: 0;
        }
        to {
            right: -100vw;
        }
    }
    position: fixed; width: 100vw; height: calc(100vh - 50px); top: 0; right: -100vw; display: flex; justify-content: center; margin-top: 50px; color: #fff; overflow-y auto; z-index: 100; background-color: #06070B; font-family: 'Pretendard'; font-weight: 700; line-height: normal; transition: all 0.5s ease-in-out;

    .gnb-list{ width: 100%; display: flex; flex-direction: column; align-items: flex-start; }
    .gnb-list .gnb-item{ width: 100%; display: flex; flex-direction: column; align-items: center; font-size: 20px; cursor: pointer; }
    .gnb-list .gnb-item .gnb-item-wrap{ width: 85%; height: 60px; display: flex; flex-direction: row; justify-content: space-between; align-items: center; padding: 0 6px; font-size: 20px; }
    .gnb-list .gnb-item .gnb-item-wrap .gnb-icon{ position: relative; width: 24px; height: 24px; }
    .gnb-list .gnb-item .gnb-item-wrap .gnb-icon svg{
        position: absolute; top:0; left: 0; transition: all 0.3s; opacity: 1;
        &.active{ opacity: 1; }
    }
    .gnb-list .gnb-item .lnb-container{ width: 100%; height: 0; max-height: 0; transition: all 0.5s ease-in-out; overflow: hidden; display: flex; flex-direction: column; align-items: center; }
    .gnb-list .gnb-item .lnb-container .lnb-area{ width: 85%; }
    .gnb-list .gnb-item .lnb-container .lnb-area .lnb-wrap{ }
    .gnb-list .gnb-item .lnb-container .lnb-area .lnb-wrap .lnb-list{  display: flex; flex-direction: column; align-items: flex-start; padding: 0 6px; font-size: 16px; }
    .gnb-list .gnb-item .lnb-container .lnb-area .lnb-wrap .lnb-list .lnb-item{ width: 100%; padding-left: 20px; border-bottom: 1px solid #222; }
    .gnb-list .gnb-item .lnb-container .lnb-area .lnb-wrap .lnb-list .lnb-item:last-of-type{ border-bottom: none; }
    .gnb-list .gnb-item .lnb-container .lnb-area .lnb-wrap .lnb-list .lnb-item .lnb-item-wrap{ height: 40px; display: flex; flex-direction: row; justify-content: space-between; align-items: center; color: rgba(255, 255, 255, 0.6); }
    .gnb-list .gnb-item .lnb-container .lnb-area .lnb-wrap .lnb-list .lnb-item .lnb-item-wrap .lnb-item-txt{  }
    .gnb-list .gnb-item .lnb-container .lnb-area .lnb-wrap .lnb-list .lnb-item .lnb-item-wrap .lnb-item-icon{  }
    .gnb-item.active{
        .gnb-item-wrap{ color: #fb0000; }
        .lnb-container{ height: auto; max-height: 500px; }

    }
`;