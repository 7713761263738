import React, { useState } from "react";
import { InterviewWrap } from "../styles/common/interview.styled";
import InterviewBoard, { getGalleryData } from "./interviewBoard";
import Pagination from "./pagination";
import interbiew_logo from "../assets/images/interbiew_logo.png";

const postsPerPage = 9;
const totalPosts = getGalleryData().length;

const Interview = () => {

    const [currentPage, setCurrentPage] = useState(1);
    const currentPosts = getGalleryData().slice((currentPage - 1) * postsPerPage, currentPage * postsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber); // 페이지 번호 업데이트
    };


    return (
        <InterviewWrap>
            <div className="board-wrap">
                <div className="title-wrap">
                    <div className="title-txt">
                    <img src={interbiew_logo} className="brand-img-item" alt="interview" />
                    </div>
                    <div className="title-box"></div>
                </div>
                <InterviewBoard className="board-gallery" posts={currentPosts} />
            </div>
            <Pagination currentPage={currentPage} totalPages={Math.ceil(totalPosts / postsPerPage)} onPageChange={handlePageChange} />
        </InterviewWrap>
    );
};



export default Interview;
