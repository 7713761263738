import styled from "@emotion/styled";

export const BenefitsWrap = styled.section`
    width: 100%; display: flex; flex-direction: column; align-items: center; gap: 90px; padding: 0 20% 160px;
    
    .title-wrap{ display: flex; flex-direction: column; gap: 10px; align-items: center; }
    .title-wrap::before{ content: ''; width: 4px; height: 80px; background-color: #06070B; }
    .title-wrap .title-txt{  }
    .title-wrap .title-txt svg{  }
    .title-wrap .title-txt svg path{  }

    .contents-wrap{ display: flex; flex-direction: column; justify-content: center; align-items: center; }
    
    @media screen and (max-width: 992px) {
        
        width: 100%; display: flex; flex-direction: column; align-items: center; gap: 40px; padding-bottom: 160px;
        
        .title-wrap{ gap: 6px; }

    }
`;