import styled from "@emotion/styled";
// import visionBgImg from "../../assets/images/vision-bg.png";

export const InterviewWrap = styled.section`
    width: 100%; display: flex; flex-direction: column; align-items: center; gap: 50px; padding: 90px 10% 160px;

    .board-wrap{ width: 100%; max-width: 1400px; display: flex; flex-direction: column; align-items: center; gap: 40px; padding: 0 40px; }
    .board-wrap .title-wrap{ width: 100%; max-width: 1400px; display: flex; flex-direction: row; gap: 40px; justify-content: space-between; align-items: center; }
    .board-wrap .title-wrap .title-txt{  }
    .board-wrap .title-wrap .title-txt img{ width: 266px; }
    .board-wrap .title-wrap .title-txt svg path{  }
    .board-wrap .title-wrap .title-box{ width: 100%; height: 2px; background-color: #000; }

    .more-btn-item{ border-bottom: 3px solid #000; color: #000; }
    
    
    .board-wrap .board-gallery{ width: 100%; }  

    @media screen and (max-width: 992px) {
        padding: 60px 0;

        .board-wrap{ max-width: 360px; gap: 26px; padding: 0; }
        .board-wrap .title-wrap{ gap: 12px; padding: 0 20px; }
        .board-wrap .title-wrap .title-txt img{ width: 192px; }
    }
`;