import styled from "@emotion/styled";

export const FooterWrap = styled.footer`

    box-sizing: border-box; width: 100%; background-color: #222;
    
    // &::before{ content: ""; display: block; width: 100%; height: 2px; background-color: #074737; }
    .footer-wrap{ display: flex; flex-direction: column; gap: 2.5rem; width: 75%; margin: 0 auto; padding: 80px 0; }

    .footer-wrap .footer-txt{ display: flex; flex-direction: row; justify-content: space-between; font-family: 'Pretendard'; }
    .footer-wrap .footer-txt .detail-txt-wrap{ display: flex; flex-direction: column; gap: 10px; }
    .footer-wrap .footer-txt .detail-txt-wrap .logo{  }
    .footer-wrap .footer-txt .detail-txt-wrap .logo a{ display: flex; justify-content:flex-start; align-items: center; height: 20px; padding: 0; margin: 0; line-height: 0; }
    .footer-wrap .footer-txt .detail-txt-wrap .logo a img{ width: auto; height: 14px; }
    .footer-wrap .footer-txt .detail-txt-wrap .detail-txt{ display: flex; flex-direction: column; }
    .footer-wrap .footer-txt .detail-txt-wrap .detail-txt .detail-text-list{ display: flex; flex-direction: row; gap: 1.25rem; }
    .footer-wrap .footer-txt .detail-txt-wrap .detail-txt .detail-text-list .detailTxt-item{ display: flex; flex-direction: row; gap: 0.5rem; color: #eaeaea; font-size: 12px; font-weight: 400; line-height: 225%; }
    .footer-wrap .footer-txt .detail-txt-wrap .detail-txt .detail-text-list .detailTxt-item .title{  }
    .footer-wrap .footer-txt .detail-txt-wrap .detail-txt .detail-text-list .detailTxt-item .txt{  }
    .footer-wrap .footer-txt .detail-txt-mob{ display: none; }


    .footer-wrap .footer-txt .gnb-txt{ display: flex; flex-direction: row; gap: 60px; font-size: 12px; font-weight: 500; line-height: normal; }
    .footer-wrap .footer-txt .gnb-txt .gnb-wrap{ display: flex; flex-direction: column; gap: 20px; text-align: left; }
    .footer-wrap .footer-txt .gnb-txt .gnb-wrap .gnb-title{ color: #fff; }
    .footer-wrap .footer-txt .gnb-txt .gnb-wrap .lnb-list{ display: flex; flex-direction: column; gap: 0; }
    .footer-wrap .footer-txt .gnb-txt .gnb-wrap .lnb-list .lnb-item{ cursor: pointer; padding: 5px 5px; }
    .footer-wrap .footer-txt .gnb-txt .gnb-wrap .lnb-list .lnb-item a{ color: #aaa; padding: 0; margin: 0; }

    .footer-wrap .footer-copyright{ display: flex; justify-content: space-between; align-items: center; font-family: 'Pretendard'; }
    .footer-wrap .footer-copyright .detail-area{ display: flex; padding: 0px 4px; justify-content: center; align-items: center; }
    .footer-wrap .footer-copyright .familySite-item{ color: #eaeaea; font-size: 14px; font-weight: 700; line-height: 190%; }
    .footer-wrap .footer-copyright .copyright-item{ color: #999; font-size: 12px; font-weight: 500; line-height: 225%; }

    @media screen and (max-width: 1400px) {
        .footer-wrap{ width: 85%; }
    }
    
    @media screen and (max-width: 992px) {
        
        // box-sizing: border-box; width: 100%; background-color: #222;
        
        // &::before{ content: ""; display: block; width: 100%; height: 2px; background-color: #074737; }
        .footer-wrap{ display: flex; flex-direction: column; gap: 2.5rem; max-width: 320px; margin: 0 auto; padding: 80px 0; }
    
        .footer-wrap .footer-txt{ display: flex; flex-direction: column; justify-content: space-between; font-family: 'Pretendard'; gap: 60px; }
        // .footer-wrap .footer-txt .detail-txt-wrap{ display: flex; flex-direction: column; gap: 10px; }
        // .footer-wrap .footer-txt .detail-txt-wrap .logo{  }
        // .footer-wrap .footer-txt .detail-txt-wrap .logo a{ display: flex; justify-content:flex-start; align-items: center; height: 20px; padding: 0; margin: 0; line-height: 0; }
        // .footer-wrap .footer-txt .detail-txt-wrap .logo a img{ width: auto; height: 14px; }
        // .footer-wrap .footer-txt .detail-txt-wrap .detail-txt{ display: flex; flex-direction: column; }
        .footer-wrap .footer-txt .detail-txt-wrap .detail-txt .detail-text-list{ display: flex; flex-direction: row; gap: 0px 4px; flex-wrap: wrap; }
        // .footer-wrap .footer-txt .detail-txt-wrap .detail-txt .detail-text-list .detailTxt-item{ display: flex; flex-direction: row; gap: 0.5rem; color: #eaeaea; font-size: 12px; font-weight: 400; line-height: 225%; }
        // .footer-wrap .footer-txt .detail-txt-wrap .detail-txt .detail-text-list .detailTxt-item .title{  }
        // .footer-wrap .footer-txt .detail-txt-wrap .detail-txt .detail-text-list .detailTxt-item .txt{  }
        // .footer-wrap .footer-txt .detail-txt-mob{ display: none; }
    
    
        .footer-wrap .footer-txt .gnb-txt{ display: flex; flex-direction: row; gap: 36px; font-size: 12px; font-weight: 500; line-height: normal; }
        // .footer-wrap .footer-txt .gnb-txt .gnb-wrap{ display: flex; flex-direction: column; gap: 20px; text-align: left; }
        // .footer-wrap .footer-txt .gnb-txt .gnb-wrap .gnb-title{ color: #fff; }
        // .footer-wrap .footer-txt .gnb-txt .gnb-wrap .lnb-list{ display: flex; flex-direction: column; gap: 0; }
        .footer-wrap .footer-txt .gnb-txt .gnb-wrap .lnb-list .lnb-item{ cursor: pointer; padding: 5px px; }
        // .footer-wrap .footer-txt .gnb-txt .gnb-wrap .lnb-list .lnb-item a{ color: #aaa; padding: 0; margin: 0; }
    
        .footer-wrap .footer-copyright{ width: 320px; display: flex; justify-content: space-between; align-items: center; }
        .footer-wrap .footer-copyright .detail-area{ display: flex; padding: 0; justify-content: space-between; align-items: center; }
        .footer-wrap .footer-copyright .familySite-item{ color: #eaeaea; font-size: 14px; font-weight: 700; line-height: 190%; }
        .footer-wrap .footer-copyright .copyright-item{ color: #999; font-size: 12px; font-weight: 500; line-height: 140%; text-align: right; }
    }
`;