import styled from "@emotion/styled";
// import visionBgImg from "../../assets/images/vision-bg.png";

export const InterviewBoardWrap = styled.div`
    * { font-family: Pretendard; }
    // width: 100%; max-width: 1400px; display: flex; flex-direction: column; align-items: center; gap: 50px;
    width: 100%;
    .gallery-list{ display: flex; justify-content: space-between; flex-wrap: wrap; }
    .gallery-list .gallery-item-wrap{ width: calc((100%) / 4); display: flex; flex-direction: column; gap: 20px; }
    .gallery-list .gallery-item-wrap .gallery-item .gallery-box-wrap { width: 100%; height: 468px; display: flex; flex-direction: column; align-items: flex-start; justify-content: space-between; padding: 44px 33px 45px 44px; transition: background 0.5s ease-in-out, color 0.5s ease-in-out; }
    .gallery-list .gallery-item-wrap .gallery-item .gallery-box-wrap:hover { background: linear-gradient(163deg, #FF8A00 11.73%, #F00 96.07%); }
    .gallery-list .gallery-item-wrap:nth-of-type(even) .gallery-item .gallery-box-wrap { 
    background-color: #4F4F4F; }
    .gallery-list .gallery-item-wrap:nth-of-type(odd) .gallery-item .gallery-box-wrap { 
    background-color: #2F2F2F; }
    .gallery-list .gallery-item-wrap .gallery-item .gallery-box-wrap .gallery-content-item { width: 100%; }
    .gallery-list .gallery-item-wrap .gallery-item .gallery-box-wrap .gallery-content-item .content-item_list { display: flex; align-items: center; justify-content: space-between; gap: 18px; }
    .gallery-list .gallery-item-wrap .gallery-item .gallery-box-wrap .gallery-content-item .content-item_list .item_list-tid { font-family: Pretendard; color: rgba(255, 255, 255, 0.50); font-size: 16px; font-weight: 500; line-height: normal; }
    .gallery-list .gallery-item-wrap .gallery-item .gallery-box-wrap .gallery-content-item .content-item_list .content-item-tit { color: #FFF; font-size: 32px; font-weight: 700;
    line-height: normal;}
    .gallery-list .gallery-item-wrap .gallery-item .gallery-box-wrap .gallery-content-item .content-item_list .stroke-line { width: 100%; height: 1px; background-color: rgba(255, 255, 255, 0.30); }
    .gallery-list .gallery-item-wrap .gallery-item .gallery-box-wrap .gallery-content-item .content-item_tit { display: flex; align-items: flex-start; justify-content: flex-start; flex-direction: column; gap: 15px; padding-top: 23px; text-align: left; }
    .gallery-list .gallery-item-wrap .gallery-item .gallery-box-wrap .gallery-content-item .content-item_tit .item_tit { font-family: Pretendard; color: #FFF; font-size: 32px; font-weight: 700; line-height: normal; }

    .gallery-list .gallery-item-wrap .gallery-item .gallery-box-wrap .more-btn-icon { width: 100%; display: flex; align-items: center; justify-content: flex-end; gap: 17px; }
    .gallery-list .gallery-item-wrap .gallery-item .gallery-box-wrap .more-btn-icon .icon_text { color: #A3A3A3; font-size: 16px; font-weight: 400; line-height: normal; }
    .gallery-list .gallery-item-wrap .gallery-item .gallery-box-wrap:hover .more-btn-icon .icon_text { color: #FFF; }
    .gallery-list .gallery-item-wrap .gallery-keyword-wrap{ display: flex; padding: 0px 16px; align-items: flex-start; color: #727272; font-size: 16px; font-weight: 400; line-height: normal; text-align: left; }

    .noPostWrap{ width: 100%; height: 600px; display: flex; justify-content: center; align-items: center; }
    .noPostWrap-item{ font-size: 20px; font-weight: 700; }

    @media screen and (max-width: 1400px) {

        .gallery-list{ }
        .gallery-list .gallery-item-wrap { gap: 20px; }
        .gallery-list .gallery-item-wrap .gallery-item .gallery-box-wrap { padding: 24px 20px 24.8px 24px; }
        
        .gallery-list .gallery-item-wrap .gallery-keyword-wrap .keywords-list{ gap: 12px; }
        .gallery-list .gallery-item-wrap .gallery-keyword-wrap .keywords-list .keywords-item{}
    
        .noPostWrap{ width: 100%; height: 600px; display: flex; justify-content: center; align-items: center; }
        .noPostWrap-item{ font-size: 20px; font-weight: 700; }
    }

    @media screen and (max-width: 1200px) { 

        .gallery-list { gap: 26px 0;}
        .gallery-list .gallery-item-wrap{ width : calc((100%) / 2); }
    }
    
    @media screen and (max-width: 992px) {

        .gallery-list .gallery-item-wrap { gap: 11px; }
        .gallery-list .gallery-item-wrap .gallery-item .gallery-box-wrap { height: 255px; }
        .gallery-list .gallery-item-wrap .gallery-item .gallery-box-wrap .gallery-content-item .content-item_list { gap: 9px; }
        .gallery-list .gallery-item-wrap .gallery-item .gallery-box-wrap .gallery-content-item .content-item_list .item_list-tid { font-size: 8.78px; }
        .gallery-list .gallery-item-wrap .gallery-item .gallery-box-wrap .gallery-content-item .content-item_tit { gap: 4px; padding-top: 13.4px; }
        .gallery-list .gallery-item-wrap .gallery-item .gallery-box-wrap .gallery-content-item .content-item_tit .item_tit { font-size: 22px;  }
        .gallery-list .gallery-item-wrap .gallery-item .gallery-box-wrap .more-btn-icon { gap: 6px; }
        .gallery-list .gallery-item-wrap .gallery-item .gallery-box-wrap .more-btn-icon .icon_text { font-size: 13px; }
        .gallery-list .gallery-item-wrap .gallery-item .gallery-box-wrap .more-btn-icon svg { width: 21.8px; height: 21.8px; }
        .gallery-list .gallery-item-wrap .gallery-keyword-wrap { padding: 0 20px; font-size: 12px; }

        .noPostWrap{ width: 100%; height: 600px; display: flex; justify-content: center; align-items: center; }
        .noPostWrap-item{ font-size: 20px; font-weight: 700; }

    }
`;